import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material"
import useAuth from "context/auth"
import moment from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PrizeDrawsService } from "service/prizes"
import { formatCurrency, NameMask } from "utils/mask"

export const ModalWinnerSeller = () => {
    const { user } = useAuth()
    const navigate = useNavigate();
    const { pathname, search, } = useLocation();
    const [open, setOpen] = useState(false)
    const [openPrizes, setOpenPrizes] = useState(false)
    const [prizesDraws, setPrizeDraws] = useState<{
        prizeDrawAt: string,
        prizeDrawId: number,
        value: number
    }[]>([])
    const total = prizesDraws?.reduce((acc, currentValue) => acc + currentValue.value, 0)


    useEffect(() => {
        PrizeDrawsService.getHistory()
            .then(({ data }) => {
                if (data?.wins?.length > 0) {
                    const groupedData = Object.values((data?.wins ?? []).reduce((acc, item) => {
                        if (!acc[item.prizeDrawId]) {
                            acc[item.prizeDrawId] = { ...item };
                        } else {
                            acc[item.prizeDrawId].value += item.value;
                        }
                        return acc;
                    }, {}));
                    setPrizeDraws(groupedData as any)
                    setOpen(true)
                }
            })
    }, [])

    const handleClose = (id?: number) => {
        if (id) {
            PrizeDrawsService.updateHistory(id)
        } else {
            prizesDraws.forEach((item) => {
                PrizeDrawsService.updateHistory(item.prizeDrawId)
            })
            setOpen(false)
        }
    }

    return (
        <>
            < Dialog
                open={open && !openPrizes}
            >
                <DialogContent>
                    <Typography variant="h2" textAlign={"center"}>Você é um revendedor pé quente</Typography>
                    <Typography variant="h4" textAlign={"center"} mt={4}>Seus clientes ganharam  <strong>{formatCurrency(total)}</strong></Typography>
                    <Grid
                    >
                        <ul>
                            {prizesDraws.map(({ prizeDrawId, prizeDrawAt }) => {
                                const date = moment(prizeDrawAt)
                                let formattedDate = date?.format("dddd, DD/MM/YYYY [às] HH:mm[h]");

                                formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
                                return (
                                    <li key={prizeDrawId}>Sorteio ao vivo: {formattedDate}</li>
                                )
                            })}
                        </ul>
                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Button
                        variant='contained'
                        color="primary"
                        onClick={() => setOpenPrizes(true)}
                    >
                        Ver resultados
                    </Button>
                    <Button
                        variant='contained'
                        color="inherit"
                        sx={{ backgroundColor: '#0000001A' }}
                        onClick={() => handleClose()}
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openPrizes} onClose={() => setOpenPrizes(false)}>
                <DialogContent>
                    <Typography variant="h2" textAlign={"center"}>Resultados</Typography>
                    <Typography variant="h4" fontWeight={400} textAlign={"center"} mt={4} mb={4}>Clique no resultado para ver detalhes</Typography>
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1}
                    >
                        {prizesDraws.map(({ prizeDrawId, prizeDrawAt, value }) => {
                            const date = moment(prizeDrawAt)
                            let formattedDate = date?.format("dddd, DD/MM/YYYY [às] HH:mm[h]");

                            formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)
                            return (
                                <Button
                                    key={'button-prize-' + prizeDrawId}
                                    fullWidth
                                    variant="outlined"
                                    color="secondary"
                                    sx={{ textAlign: "center", textTransform: "none" }}
                                    onClick={() => {
                                        navigate(pathname + search + `?seller-prize=${prizeDrawId}`)
                                        handleClose(prizeDrawId)
                                        if (prizesDraws.length === 1) {
                                            setOpen(false)
                                            setOpenPrizes(false)
                                        }
                                        setPrizeDraws(prev => prev.filter((item) => item.prizeDrawId !== prizeDrawId))
                                    }}
                                >
                                    <Typography>{formattedDate}<br />
                                        Total em prêmios: <strong>{formatCurrency(value)} </strong></Typography>
                                </Button>
                            )
                        })}

                        {prizesDraws?.length === 0 &&
                            <Typography textAlign={"center"}>Você já visualizou todos os sorteios pendentes</Typography>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        color="inherit"
                        sx={{ backgroundColor: '#0000001A' }}
                        onClick={() => setOpenPrizes(false)}
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}