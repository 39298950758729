import React, { useEffect, useState } from "react";
import { Button, FormControl, Menu as MuiMenu, Typography } from "@mui/material"
import { IoMdArrowDropdown } from "react-icons/io";

interface MenuProps {
    children: React.ReactNode;
    handleClose?: () => void;
    handleOpen?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    title: string;
    open: boolean;
}

const Menu = (props: MenuProps) => {

    const { children, handleClose, title, open, handleOpen } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleCloseMenu = () => {
        setAnchorEl(null);
        handleClose && handleClose();
    }
    const HandleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (handleOpen) {
            handleOpen(event);
        }
    }

    useEffect(() => {
        if (!open) {
            setAnchorEl(null)
        }
    }, [open])


    return (
        <FormControl fullWidth>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={HandleOpen}
                variant="outlined"
                color="inherit"
                sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}
                endIcon={<IoMdArrowDropdown />}
            >
                {title}
            </Button>
            <MuiMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {children}
            </MuiMenu>
        </FormControl>
    )
}

export const MenuText = (props: MenuProps) => {

    const { children, handleClose, title, open, handleOpen } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleCloseMenu = () => {
        setAnchorEl(null);
        handleClose && handleClose();
    }
    const HandleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if (handleOpen) {
            handleOpen(event);
        }
    }

    useEffect(() => {
        if (!open) {
            setAnchorEl(null)
        }
    }, [open])


    return (
        <>
            <Typography onClick={HandleOpen} fontSize={13}>{title}</Typography>
            <MuiMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    sx: {
                        fontSize: 13,
                        maxHeight: 200,
                        '.MuiMenuItem-root': {
                            fontSize: 13,
                            maxWidth: '100%'
                        }
                    }
                }}
            >
                {children}
            </MuiMenu >
        </>
    )
}

export default Menu;