import { Button, Dialog, DialogActions, DialogContent, Grid, Typography, useTheme } from "@mui/material"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom"
import RoutesPath from "../../../utils/routesBases"

interface Props {
    open: boolean,
    onHandleClose: () => void,
}

export const NoBalanceModal = ({
    open,
    onHandleClose
}: Props) => {
    const navigate = useNavigate()
    const { palette } = useTheme()
    return (
        <Dialog
            open={open}
            onClose={() => onHandleClose()}
        >
            <DialogContent>
                <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"} textAlign={"center"} gap={3}>
                    <Typography variant="h3" color={"red"} textTransform={"uppercase"}>
                        <RiMoneyDollarCircleLine size={40} style={{
                            marginBottom: -3
                        }} />
                        <br />
                        Crédito insuficiente</Typography>
                    <Typography variant="h4">Você não possui créditos para concluir essa aposta<br />
                        <br />Clique aqui para comprar créditos</Typography>
                    <Button
                        color="secondary"
                        variant="contained"
                        sx={{ animation: 'pulseWithoutBoxShadow 1s infinite' }}
                        onClick={() => {
                            navigate(RoutesPath.Clients.deposito.path)
                        }}
                        startIcon={<RiMoneyDollarCircleLine style={{
                            marginLeft: 2
                        }} />}
                    >Comprar créditos <br /> para apostar</Button>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onHandleClose} variant="text" sx={{ color: palette.grey[800] }}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}
