import comissions from "assets/imgs/icons/comissions.svg"
import concursos from "assets/imgs/icons/concursos.svg"
import indicacao2 from "assets/imgs/icons/icone-indicação 2.svg"
import indicacao from "assets/imgs/icons/icone-indicação.svg"
import publi from "assets/imgs/icons/PUBLICIDADES.png"
import money from "assets/imgs/money.svg"

export default class Images {
    static icons = {
        comissions,
        concursos,
        indicacao,
        indicacao2,
        money,
        publi
    }
}