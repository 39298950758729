import { ThemeOptions, createTheme } from '@mui/material/styles';

// project imports
import themePalette from './palette';
import themeTypography from './typography';
import { TypographyOptions } from '@mui/material/styles/createTypography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = () => {


    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: themePalette(),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography() as TypographyOptions,
        components: {
            MuiButtonBase: {
                defaultProps: { sx: { textTransform: 'none' } }
            },
            MuiButton: {
                defaultProps: { disableElevation: true, sx: { textTransform: 'none' } }
            },
            MuiDivider: {
                defaultProps: { sx: { width: '100%', borderBottomWidth: 'medium', borderColor: '#00000050' } }
            },
            MuiSelect: {
                defaultProps: {
                    sx: { backgroundColor: '#00000010', '& .MuiOutlinedInput-notchedOutline': { content: "''", borderColor: 'transparent' } },
                }
            }
        }
    };
    const themes = createTheme(themeOptions);
    return themes;
};

export default theme;