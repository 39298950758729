import { useEffect, useState } from "react";
import { Button, CircularProgress, Collapse, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material"

import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../context/auth";
import { NameMask, formatCurrency } from "../../utils/mask";
import { LoadingButton } from "@mui/lab";
import { CommissionService } from "../../service/commission";
import PIXLOGO from "../../assets/imgs/pix.svg"
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { FiCopy } from "react-icons/fi";
import theme from "theme";
import CreditAdded from "../../components/creditAdded";
import { io } from "socket.io-client";
import { awaitTime, Utils } from "../../utils";


interface IDepositResponse {
    createdAt: string,
    status: number,
    value: number,
    userId: number,
    paymentId: number,
    id: number,
    data: {
        qrCode: {
            value: string,
            kind: string,
        },
        integrationId: string,
        pixCopyAndPaste: string,
    }
}

export const DepositPage = () => {
    const navigate = useNavigate();

    const { user } = useAuth();

    const location = useLocation()

    const values = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

    const [value, setValue] = useState<number | undefined>()

    const [loading, setLoading] = useState(false)

    const [pixInfos, setPixInfos] = useState<IDepositResponse>()

    const NameClient = NameMask(user?.name ?? "")

    const [openDepositSuccess, setOpenDepositSuccess] = useState(false)

    const socket = io(Utils.URLSBases.ws, {
        path: '/socket.io',
        transports: ['websocket'],
        secure: true,
    })

    const handleDeposit = (_value: number) => {
        setLoading(true)
        CommissionService.createDeposit(_value as number)
            .then(({ data }) => {
                setPixInfos(data?.depositInfo)
                getStatusTask(data?.depositInfo?.id)
            })
            .catch(() => {
                toast("Falha ao gerar um depósito", {
                    type: "error"
                })
            })
            .finally(() => setLoading(false))
    }

    const getStatusTask = async (id: number) => {
        if (openDepositSuccess) {
            return
        }

        const rework = async (): Promise<any> => {
            await awaitTime(30)
            return getStatusTask(id)
        }

        let success = await getStatus(id)

        if (success) {
            setOpenDepositSuccess(true)
            return
        } else {
            return rework()
        }
    }

    const getStatus = async (id: number, byTask: boolean = true) => {
        if (!byTask) {
            await awaitTime(3)
        }
        return CommissionService.getDeposit(id).then(async ({ data }) => {
            if (data?.depositInfo?.status === 1) {
                return true
            } else {
                return false
            }
        }).catch(() => {
            return false
        })
    }

    useEffect(() => {
        if (pixInfos?.id) {
            const data = {
                case: 'deposit_confirmed',
                kind: 'deposit',
                target: pixInfos?.id
            }
            const room = `${data.case}-${data.kind}-${data.target}`;

            socket.on(room, (message: IDepositResponse) => {
                if (message?.status === 1) {
                    setOpenDepositSuccess(true)
                }
            })
            document.addEventListener("visibilitychange", async function () {
                if (document.visibilityState === 'visible') {
                    let success = await getStatus(pixInfos.id, false)
                    if (success) {
                        setOpenDepositSuccess(true)
                    }
                }
            });
        }
    }, [pixInfos])

    const handleCopy = () => {
        toast("Código PIX copiado!", { type: 'success' })
        copy(pixInfos?.data?.pixCopyAndPaste ?? "")
    }


    return (
        <Grid container alignItems={"center"} mt={2} flexDirection={'column'} gap={4} p={2}>
            <Typography fontWeight="700" variant="h3">Olá, {NameClient.firstName} {NameClient.lastName}</Typography>
            <TextField
                fullWidth
                select
                value={value}
                onChange={(e) => {
                    let _value = Number(e.target.value)
                    setValue(_value)
                    handleDeposit(_value)
                }}
                variant="outlined"
                label={"Qual valor você deseja?"}
                InputProps={{
                    style: {
                        fontWeight: '700',
                        fontSize: 16
                    },
                    endAdornment: (
                        <InputAdornment position="start">
                            {loading && <CircularProgress size={18} sx={{ mr: 1 }} />}
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={{
                    style: {
                        fontWeight: '700',
                        fontSize: 16
                    },
                }}
            >
                <MenuItem disabled>Selecione o valor desejado...</MenuItem>
                {values.map((item) => {
                    return (
                        <MenuItem value={item} sx={{
                            fontWeight: '700',
                            fontSize: 16
                        }}>{formatCurrency(item)} {item === 100 ? "(Ganhe + R$ 20,00 de bônus)" : ""}</MenuItem>
                    )
                })}
            </TextField>

            <Collapse in={!!pixInfos} sx={{
                width: '100%'
            }}>
                <Grid container gap={3} sx={{ opacity: 1 }}>
                    <Typography fontWeight={"bold"} variant="h4" onClick={handleCopy}>Forma de pagamento</Typography>
                    <Grid container alignItems="center" gap={1} onClick={handleCopy}>
                        <img src={PIXLOGO} width={20} />
                        <Typography fontWeight={"bold"}>Pagar via PIX</Typography>
                    </Grid>
                    <Typography onClick={handleCopy}>Para efetuar o pagamento, <strong>copie </strong> o <strong>código PIX</strong> abaixo e <strong>cole</strong> no <strong>aplicativo do seu banco</strong>. </Typography>

                    <Typography fontSize={12} onClick={handleCopy}>
                        <u>1° Passo</u>: Clique em copiar o código PIX abaixo
                        <br />
                        <u>2° Passo</u>: Abra o aplicativo do seu banco na área PIX
                        <br />
                        <u>3° Passo</u>: Cole o código na área PIX copia e cola
                        <br />
                        <u>4° Passo</u>: Efetue o pagamento
                    </Typography>
                    <Grid container p={2} bgcolor="#0000000A" alignItems="center" justifyContent="space-between">
                        <Typography width="calc(100% - 45px)" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {pixInfos?.data?.pixCopyAndPaste}
                        </Typography>
                        <IconButton onClick={handleCopy}>
                            <FiCopy color={theme().palette.secondary.main} />
                        </IconButton>
                    </Grid>
                    <Button
                        sx={{
                            mt: -3,
                            width: "100%",
                            textAlign: "center",
                            textTransform: "none"
                        }}
                        color="secondary"
                        startIcon={<FiCopy />}
                        onClick={handleCopy}
                    >
                        Clique aqui para copiar o código PIX
                    </Button>

                    <Grid container alignItems="center" justifyContent={'center'}>
                        <Typography>
                            Ou escaneie o QRCODE para efetuar o pagamento
                        </Typography>
                        {
                            pixInfos?.data?.qrCode?.value &&
                            (pixInfos?.data?.qrCode?.kind === "base64" ?
                                <img src={"data:image/png;base64," + pixInfos?.data.qrCode.value} width={'100%'} style={{ maxWidth: 300 }} /> :
                                <img src={pixInfos?.data.qrCode.value} width={'100%'} />)
                        }
                    </Grid>
                </Grid>
            </Collapse>

            <Grid width="100%">
                <Button disabled={loading} color="inherit" variant="outlined" sx={{ p: 1, fontSize: '1rem', maxWidth: 150 }} fullWidth onClick={() => navigate(-1)}>
                    Voltar
                </Button>
            </Grid>

            <CreditAdded
                show={openDepositSuccess}
                isDeposit
                isSeller={location.pathname.includes("/correspondente") || location.pathname.includes("/venda")}
                bgColor="rgba(52, 128, 235, 0.7)"
            />
        </Grid>
    )
}

export default DepositPage;