import { Dialog, DialogContent, Grid, Typography, useTheme } from "@mui/material";
import { CoinFlip } from "../../../components/coin";
import { SlideIn } from "../../../components/slide";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { RiCoinsLine } from "react-icons/ri";
import { formatCurrency } from "../../../utils/mask";
import { Sounds } from "../audios/intex";

interface Props {
    open: boolean;
    value: number,
    onHandleClose: () => void;
}

export const NoGainModal = ({ open, value, onHandleClose }: Props) => {
    const [isVisible, setIsVisible] = useState(true);
    const { palette } = useTheme()

    const [showNotCry, setShowNotCry] = useState(false)

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        let timeoutNotCry: NodeJS.Timeout;

        if (open) {
            timeout = setTimeout(() => {
                setIsVisible(false);
                setTimeout(() => {
                    onHandleClose();
                    setIsVisible(true);
                    setShowNotCry(false)
                }, 1000);
            }, 6000);

            timeoutNotCry = setTimeout(() => {
                setShowNotCry(true)
                setTimeout(() => {
                    Sounds.playCashregister()
                }, 300);
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
            clearTimeout(timeoutNotCry);
        };
    }, [open, onHandleClose]);

    return (
        <Dialog
            fullScreen
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0, 0.5)',
                    boxShadow: 'none',
                    overflow: 'hidden',
                },
            }}
            disableScrollLock
        >
            <DialogContent style={{ padding: 0, overflow: 'hidden' }}>
                <AnimatePresence mode="sync">
                    {isVisible && (
                        <Grid
                            key="no-gain-container"
                            container
                            height={"100%"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            gap={"20px"}
                        >
                            <SlideIn key={`no-gain`}
                                direction="bottom"
                                exit="top"
                                type="spring"
                                usingModal>
                                <Typography variant="h2" color={"white"} textAlign={"center"} mt={showNotCry ? 0 : -30}>
                                    Suas apostas não foram premiadas dessa vez!
                                </Typography>
                            </SlideIn>

                            {showNotCry && <SlideIn
                                key={'not-cry'}
                                direction="bottom"
                                exit="top"
                                type="spring"
                                usingModal

                            >
                                <Grid container mt={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                                    <RiCoinsLine color="#00e114" size={116} />
                                </Grid>

                                <Typography variant="h3" sx={{ color: '#00e114' }} textAlign={"center"}>
                                    Você está com sorte e ganhou um<br />
                                    <strong>PRÊMIO EXTRA</strong>
                                    <br />
                                    <strong>{formatCurrency(value)}</strong>
                                </Typography>

                                <Grid display={"flex"} gap={2} width={"100%"} mt={1} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <CoinFlip key={'sadsadds'} result={null} onFinish={() => { }} />
                                </Grid>

                                <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mt={1}>
                                    {["Agora tente multiplicar seus ganhos com", "CARA OU COROA"].map((text) => <Typography variant="h3" textAlign={"center"} textTransform={"uppercase"} color={"white"} fontWeight={"bold"}>{text}</Typography>)}
                                </Grid>
                            </SlideIn>}
                        </Grid>
                    )}
                </AnimatePresence>
            </DialogContent>
        </Dialog>
    );
};
