import { useForm } from "react-hook-form"
import { CircularProgress, FormControl, FormHelperText, Grid, InputAdornment, OutlinedInput, Typography } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { Validators } from "../../../utils/validators";
import { CellphoneMaskCustom, CPFMaskCustom, } from "../../../components/mask";
import { AuthService } from "../../../service/auth";
import useAuth from "../../../context/auth";
import { toast } from "react-toastify";
import { Moment } from "moment";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { WhatsApp } from "@mui/icons-material";
import { BetService } from "../../../service/bets";
import { LoadingButton } from "@mui/lab";

const ClientLoginPage = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues
    } = useForm<{ cpf: string, phone: string }>()

    const { Authenticate } = useAuth();

    const [client, setClient] = useState<{
        "id": number,
        "name": string,
        "phone": string
    } | null>(null)
    const [showPin, setShowpin] = useState(false);

    const [birthday, setBirthday] = useState<Moment | null>(null)

    const [loading, setLoading] = useState(false);

    const [showBirthday, setShowBirthday] = useState(false)

    const [showWhatsApp, setShowWhatsApp] = useState(false)

    const inputsRefBirthday = useRef<(HTMLInputElement | null)>(null);
    const inputsRefWhatsApp = useRef<(HTMLInputElement | null)>(null);

    useEffect(() => {
        if (showWhatsApp && inputsRefWhatsApp.current) {
            inputsRefWhatsApp.current.focus();
        }
    }, [showWhatsApp]);

    useEffect(() => {
        if (inputsRefBirthday.current) {
            inputsRefBirthday.current.focus();
        }
    }, [showBirthday]);

    const clientRequestLogin = ({ cpf }: { cpf: string }) => {
        setClient(null)
        setShowpin(false)
        setLoading(true)

        AuthService.clientLogin({
            cpf: cpf.replace(/\D/g, "")
        })
            .then(({ data }) => {
                if (data) {
                    Authenticate(data, 'client')
                }
            })
            .catch((err) => {
                if (err?.response?.data?.message === "Cliente não encontrado") {
                    setShowWhatsApp(true)
                } else {
                    toast(err?.response?.data?.message ?? "Error ao realizar login", { type: "error" })
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const createClient = ({ cpf, phone }: { cpf: string, phone: string }) => {
        setLoading(true)
        BetService.createClient({
            cpf,
            phone,
            ...(showBirthday && {
                birthday: birthday.format("YYYY-MM-DD")
            })
        })
            .then(({ data }) => {
                clientRequestLogin({ cpf })
            })
            .catch(err => {
                setLoading(false)
                if (err?.response?.data?.message?.error === "access_denied" || (err?.response?.status === 404 && err?.response?.data?.service !== 'getPerson' && err?.response?.data?.point !== "Busca por cliente")) {
                    setShowBirthday(true)
                }
                toast(err?.response?.data?.message ?? "Erro ao criar cliente!", { type: "error" })
            })
    }


    return (
        <Grid mt={5}>
            <Typography
                fontWeight="bold"
                variant="h2"
                textAlign="center">
                Seja bem-vindo(a)!
            </Typography>
            <Typography
                mt={2}
                fontWeight="bold"
                variant="h4"
                textAlign="center">
                Para continuar
            </Typography>
            <Grid container mt={3} gap={2}>
                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu CPF
                </Typography>
                <Grid container>
                    <FormControl fullWidth>
                        <OutlinedInput
                            error={!!errors.cpf}
                            placeholder="000.000.000-00"
                            inputComponent={CPFMaskCustom}
                            disabled={loading}
                            autoFocus
                            fullWidth
                            {...register("cpf", {
                                required: true,
                                onChange: (event) => {
                                    const value = event?.target?.value?.replace(/\D/g, '')
                                    if (value?.length === 11) {
                                        handleSubmit(clientRequestLogin)()
                                    }
                                },
                                validate: (value) => {
                                    if (!Validators.validateCPF(value)) {
                                        return "Cpf invalido!"
                                    }
                                }
                            })}
                            endAdornment={loading && <CircularProgress size={24} />}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                    </FormControl>
                </Grid>

                {showBirthday &&
                    <>
                        <Typography
                            fontWeight="bold"
                            variant="h5"
                        >
                            Informe sua data de nascimento
                        </Typography>
                        <DesktopDatePicker
                            inputRef={(el) => inputsRefBirthday.current}
                            sx={{ fontSize: 20, width: '100%' }}
                            format="DD/MM/YYYY"
                            autoFocus
                            value={birthday}
                            onChange={(event) => setBirthday(event)}
                        />
                    </>
                }
                {showWhatsApp &&
                    <>
                        <Typography fontWeight={"bold"}>Informe seu WhatsApp</Typography>
                        <OutlinedInput
                            autoFocus={true}
                            error={!!errors.phone}
                            sx={{ fontSize: 20 }}
                            placeholder="(00) 0 0000-0000"
                            inputComponent={CellphoneMaskCustom}
                            fullWidth
                            {...register("phone", {
                                required: true,
                                validate: (value) => {
                                    if (!Validators.validateWhatsAppNumber(value)) {
                                        return "WhatsApp invalido!"
                                    }
                                }
                            })}
                            startAdornment={
                                <InputAdornment position="start">
                                    <WhatsApp color="success" />
                                </InputAdornment>
                            }
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.phone?.message as string}</FormHelperText>
                    </>
                }

                {(showBirthday || showWhatsApp) &&
                    <LoadingButton
                        variant="contained"
                        fullWidth
                        onClick={handleSubmit(createClient)}
                        disabled={!Validators.validateWhatsAppNumber(getValues("phone") ?? "")}
                        loading={loading}
                    >Continuar</LoadingButton>
                }
            </Grid>
        </Grid>
    )
}

export default ClientLoginPage;