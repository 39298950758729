import { useEffect, useState } from 'react';
import { LPNav } from '../../../layout/lp';

import style from './styles.module.css'
import { RodapeLP } from 'layout/lp/rodape';
import { Link, useLocation } from 'react-router-dom';

export function LPRegulation() {
    const { state, pathname } = useLocation()
    const [prevPath, setPrevPath] = useState<string | undefined>()

    useEffect(() => {
        if (state?.prev_path) {
            setPrevPath(state?.prev_path)
        }
    }, [])

    return pathname.includes("rapido") ? <RegulationExpress prevPath={prevPath} key={'express'} /> : <RegulationLive prevPath={prevPath} key={'live'} />
}

const RegulationLive = ({ prevPath }: { prevPath: string | undefined }) => {
    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.container}>
                <h2>REGULAMENTO DO SORTEIO AO VIVO</h2>
                <p><span>•DO SORTEIO</span> - Os sorteios de quadras, ternos, duques e unidades, são únicos e exclusivos pela plataforma NÚMERO FAVORITO, as transmissões dos sorteios serão disponibilizadas exclusivamente através do acesso à plataforma NÚMERO FAVORITO, a permanência do sorteio para visualização é de 24 (vinte e quatro) horas após cada transmissão; Para fins de resultado do sorteio, são utilizados 4 (quatro) globos seletores, onde cada globo seletor é abastecido com 10 (dez) bolas numeradas de 0 (zero) a 9 (nove), os globos seletores são posicionados lateralmente um ao lado do outro, na posição sequencial 1º globo seletor, 2º globo seletor, 3º globo seletor e 4º globo seletor, onde, apenas 1 (uma) bola numerada é sorteada por vez em cada globo seletor; A bola numerada sorteada no globo seletor da 4ª posição, compõe o resultado na posição de UNIDADE (denominada como NÚMERO FAVORITO); A bola numerada sorteada no globo seletor da 3ª posição, compõe o resultado na posição de DUQUE; A bola numerada sorteada no globo seletor da 2ª posição, compõe o resultado na posição de TERNO; E por fim, a bola numerada sorteada no globo seletor da 1ª posição, compõe o resultado na posição de QUADRA. “Exemplo: Se no globo seletor da 4ª posição, for sorteado a bola numerada 8; No globo seletor da 3ª posição, for sorteado a bola numerada 5; No globo seletor da 2ª posição, for sorteado a bola numerada 3; E no globo seletor da 1ª posição, for sorteado a bola numerada 1; O resultado será QUADRA 1358, TERNO 358, DUQUE 58 e UNIDADE 8 (NÚMERO FAVORITO).” Os sorteios são diários, às 10:00 horas; 12:00 horas; 14:00 horas; 16:00 horas; 18:00 horas e 20:00 horas. A data e horário de cada sorteio está visível na plataforma NÚMERO FAVORITO e no recibo “bilhete da sorte”; As apostas concorrentes iniciarão com 0000 e finalizarão com 9999, estrutura numérica de milhar.
                    <br /><span>•DA APURAÇÃO DO PRÊMIO DE QUADRA E SORTE EXTRA</span> - O prêmio de quadra, assim como Sorte Extra de terno, duque, unidade, vizinho de baixo, vizinho de cima e resultado invertido serão apurados única e exclusivamente através do resultado exclusivo do sorteio NÚMERO FAVORITO. Acerta o prêmio de QUADRA a aposta que tiver o número idêntico aos 4(quatro) números sorteados conforme a sequência da posição QUADRA, TERNO, DUQUE e UNIDADE;  Acerta a Sorte Extra de TERNO a aposta que tiver o número idêntico aos 3(três) últimos números do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição TERNO, DUQUE e UNIDADE); Acerta a Sorte Extra de DUQUE a aposta que tiver o número idêntico aos 2(dois) últimos números do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição DUQUE e UNIDADE); E acerta a Sorte Extra de UNIDADE (NÚMERO FAVORITO) a aposta que tiver no último número do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição UNIDADE); Entende-se que as contemplações por aproximação e inversão de resultado são denominadas de “Sorte Extra”, incluem também como “Sorte Extra” os prêmios de aproximação à QUADRA: Prêmio “Vizinho de baixo” quando a aposta tem 1 (um)  número inferior à quadra sorteada; Prêmio “Vizinho de cima” quando a aposta tem 1 (um) número superior à quadra sorteada; Prêmio “Resultado invertido” quando a aposta tem os 04 (quatro) números totalmente invertidos à quadra sorteada.
                    <br /><span>•DO VALOR DA APOSTA, CORTESIAS E GRATUIDADES</span> - O valor pago pela aposta tem como base de cálculo para cobrança apenas as apostas de QUADRA, já que as apostas em TERNO, DUQUE, UNIDADE, VIZINHO DE BAIXO, VIZINHO DE CIMA e RESULTADO INVERTIDO são cortesias (gratuidades) ofertadas pela plataforma NÚMERO FAVORITO ao usuário, sem qualquer custo adicional; Entende-se que o usuário ao pagar as apostas, está pagando o valor referente às apostas de QUADRA, porém, concorrendo gratuitamente a Sorte Extra por aproximação à QUADRA, com o TERNO, DUQUE, UNIDADE, VIZINHO DE BAIXO, VIZINHO DE CIMA e RESULTADO INVERTIDO.
                    <br /><span>•DO SALDO DO USUÁRIO, DO CRÉDITO PARA APOSTAR, DA UTILIZAÇÃO DO SALDO E CRÉDITO PARA PAGAMENTO DE NOVAS APOSTAS E DA SOLICITAÇÃO DE RESGATE DO VALOR DISPONÍVEL PARA SAQUE</span> - O valor do prêmio será disponibilizado para resgate imediatamente após a apuração do resultado, onde será automaticamente incrementado ao saldo do valor para saque do usuário, e estará acessível para consultas, visualizações e solicitações de saques no endereço eletrônico: <a href="https://numerofavorito.com/cliente">https://numerofavorito.com/cliente</a>; O saldo do valor para saque disponível do usuário, assim como o crédito para apostar também poderão ser utilizados como forma de pagamento para novas apostas;  A solicitação de saque do valor disponível para saque, apenas estará disponível para ser sacado quando o saldo do valor para saque for igual ou superior a R$ 7,50 (Sete reais e cinquenta centavos);  Será cobrado uma taxa de custo bancário no valor de R$ 2,50 (Dois reais e cinquenta centavos) para cada saque efetuado, ao solicitar o saque do valor para saque, será efetuado um crédito de pagamento via PIX vinculado ao CPF (Cadastro de Pessoa Física) do usuário (o saldo disponível é pessoal e intransferível), não haverá a possibilidade do envio do crédito de pagamento via PIX para outrem, também haverá a possibilidade de solicitar parcialmente o valor para saque, uma vez solicitado o saque, será deduzido a taxa de custo bancário, o valor líquido restante será creditado integralmente na conta bancária do usuário.
                    <br /><span>•DA COMPRA DE CRÉDITOS PARA APOSTAR</span> - O usuário a qualquer momento poderá comprar créditos pré-pagos para a única e exclusiva finalidade de pagar suas próprias apostas na plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/cliente" target='_blank'>https://numerofavorito.com/cliente</a>; Sendo vedada a solicitação de saque do valor pré-pago “CRÉDITOS PARA APOSTAR”; Os valores pré-pagos não são de natureza corrente, são indisponíveis para eventuais saques, a plataforma NÚMERO FAVORITO está em conformidade com as boas práticas junto ao SFN – Sistema Financeiro Nacional, em conformidade com a Lei 9.613/98 – Lei de Prevenção à Lavagem de Dinheiro e ao Financiamento do Terrorismo.
                    <br /><span>•DOS PRÊMIOS E SORTE EXTRA</span> - Para efeito do pagamento dos prêmios e pagamento da Sorte Extra, será aplicado a modalidade de contemplação individual, onde será devido ao ganhador o valor bruto atribuído ao prêmio e a Sorte Extra.
                    <br />Entende-se que, somente concorrerá à contemplação do prêmio bruto de Quadra a aposta com acertos de todas as 04 (quatro) casas decimais do número sorteado “milhar”, conforme exemplo abaixo; <u>Sorte Extra</u>: Somente concorrerá à contemplação a Sorte Extra de “Terno” as apostas com acertos nas últimas 03 (três) casas decimais do número sorteado “centena de milhar” conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Duque” as apostas com acertos nas últimas 02 (duas) casas decimais do números sorteados “dezena de milhar” conforme exemplo abaixo; E somente concorrerá à contemplação a Sorte Extra de “Unidade” as apostas com acertos na última 01 (uma) casa decimal do números sorteados “unidade de milhar” conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Vizinho de baixo” quando a aposta tiver na última casa decimal “unidade” 1 (um) número inferior à quadra sorteada, conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Vizinho de cima” quando a aposta tiver na última casa decimal “unidade” 1 (um) número superior à quadra sorteada, conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de  “Resultado invertido” quando a aposta tiver os 04 (quatro) números totalmente invertidos à quadra sorteada, onde o número sorteado na posição de unidade passa para posição da quadra, o número sorteado na posição de duque passa para a posição de terno, o número sorteado na posição de terno passa para a posição de duque, e o número sorteado na posição de quadra passa para a posição de unidade, conforme exemplo abaixo.
                    <br /><u>Exemplo de contemplação:</u><br />
                    Se o número sorteado no resultado for, “1358”;<br />
                    Acerto de Quadra - Somente concorrerá ao prêmio bruto de Quadra a aposta com acertos de todas as 04 (quatro) casas decimais “<u>1358</u>”;<br />
                    “Sorte Extra”<br />
                    Acerto de Terno - Somente concorrerá a Sorte Extra de Terno as apostas com acertos nas últimas 03 (três) casas decimais “*<u>358</u>”;<br />
                    Acerto de Duque - Somente concorrerá a Sorte Extra de Duque as apostas com acertos nas últimas 02 (duas) casas decimais “**<u>58</u>”;<br />
                    Acerto de Unidade - Somente concorrerá a Sorte Extra de Unidade as apostas com acertos na última 01 (uma) casa decimal “***<u>8</u>”; <br />

                    Acerto de Vizinho de baixo - Somente concorrerá a Sorte Extra de Vizinho de baixo a aposta com o número inferior à quadra sorteada “1357”;<br />
                    Acerto de Vizinho de cima - Somente concorrerá a Sorte Extra de Vizinho de cima a aposta com o número superior à quadra sorteada “1359”; e<br />
                    Acerto de Resultado invertido - Somente concorrerá a Sorte Extra de Resultado invertido a aposta tiver os 04 (quatro) números totalmente invertidos à quadra sorteada “8531”.<br />
                    <br /><span>•DISPOSIÇÕES GERAIS</span> - Cada ganhador, a contar da data da compra da aposta, automaticamente autorizará o uso, pela Plataforma NÚMERO FAVORITO, pelo período de 12 (doze) meses, da imagem, voz, performance, nomes e outras identificações a estes relativos, em filmes publicitários veiculados em mídia eletrônica, internet, fotos, cartazes, anúncios em jornais, revistas e outros e em qualquer outra forma de mídia impressa ou eletrônica, a serem divulgados no território nacional, referentes à divulgação da conquista do prêmio, sem qualquer ônus àquela; O processamento dos dados pessoais do participante será feito de acordo com a Lei Geral de Proteção de Dados – LGPD; Os usuários tem à disposição um canal de atendimento para o esclarecimento de eventuais dúvidas; Os problemas técnicos ou mau funcionamento decorrentes, mas não limitados às ocorrências, tais como, erros de hardware ou software de propriedade do usuário; defeito ou falha do computador, telefone, smartphone, cabo, satélite, rede, eletroeletrônico, equipamento sem fio, que prejudiquem o acesso ou a conexão à Internet; erro ou limitação por parte dos provedores de serviço, hospedagem; erro na transmissão das informações; atraso ou  falhas no envio e/ou recebimento de mensagens de SMS, whatsapp, correio eletrônico e outros; ações de hackers, vírus, bugs, worms, que venham impedir, dificultar e prejudicar o acesso ao site, não poderão ser atribuídos à plataforma NÚMERO FAVORITO.
                </p>
                <h2>Diretrizes</h2>
                <p><span>•DA MODALIDADE DA PLATAFORMA</span> – A plataforma NÚMERO FAVORITO é uma nova modalidade de entretenimento pessoal, classificada como uma Startup FunTech, não se enquadra estruturalmente como um sorteio tradicional, tornou-se uma das maiores referências em FunTech, startup que utiliza inovação tecnológica para transformar o setor de entretenimento e lazer.
                    <br /><span>•DA TECNOLOGIA DA PLATAFORMA</span> – A nossa Technology Hub é nativa do Silicon Valley, CA - USA. Tecnologia implementada com lato amparo da Lei Complementar no 182 de 1o de junho de 2021.
                    <br /><span>•DA ESCOLHA DOS NÚMEROS</span> - O usuário escolhe a quantidade de apostas que deseja comprar de 01 a 20 apostas, logo o usuário deverá preencher manualmente ou automaticamente cada aposta com 04 (quatro) algarismos “formato numeral de milhar”, podendo escolher quaisquer dos números naturais, exemplo: (0; 1; 2; 3; 4; 5; 6; 7; 8 e 9), observando que em todas as apostas preenchidas, sempre o algarismo na posição de unidade de milhar será um NÚMERO FAVORITO, (exemplo: No preenchimento da aposta 1358, o número “8” será um NÚMERO FAVORITO).
                    <br /><span>•DA GERAÇÃO DAS APOSTAS</span> - Baseados na disponibilidade dos 10 (dez) algarismos disponíveis na plataforma NÚMERO FAVORITO: (0, 1, 2, 3 ,4 ,5, 6, 7, 8 e 9), utilizamos todas as combinações possíveis entre os numerais 0000 e 9999, nos possibilitando a geração de todas as 10.000 (Dez mil) apostas que comportam dentro da estrutura numérica de milhar, as quais utilizamos como base para apuração do resultado de todos os prêmios e Sorte Extra do sorteio (QUADRA / TERNO / DUQUE / UNIDADE).
                    <br /><span>•DA DISTRIBUIÇÃO DOS PRÊMIOS E SORTE EXTRA</span> -  A plataforma NÚMERO FAVORITO, premia os ganhadores com a totalidade dos prêmios e Sorte Extra previamente anunciados, sendo os prêmios e Sorte Extra distribuídos conforme cada categoria de acertos e suas respectivas contemplações (QUADRA / TERNO / DUQUE / UNIDADE).
                    <br /><span>•DA BONIFICAÇÃO CARA OU COROA</span> -  Ao premiar a(s) aposta(s) e/ou outro(s) prêmio(s) será disponibilizada a chance da bonificação “cara ou coroa”, onde ao lançar a moeda virtual com duas faces (cara e coroa) será dada a oportunidade de duplicar o valor do prêmio (x2), onde as chances para duplicar o valor do prêmio são aleatórias; Entende-se que ao lançar a moeda virtual “cara ou coroa” o prêmio será duplicado quando a moeda virtual parar com a face coroa “visível” e a face cara “oculta”; Quando a moeda virtual parar com a face cara “visível” e a face coroa “oculta” o prêmio permanecerá com o valor original (o valor que foi originalmente percebido na premiação da aposta); Havendo o resultado bonificação (face coroa da moeda virtual “visível”), o valor referente à bonificação será creditado e ficará disponível imediatamente em créditos para apostar; Já o valor originalmente percebido na premiação da aposta será creditado e ficará disponível imediatamente em valor para saque.
                    <br /><span>•DO RESULTADO E APURAÇÃO DOS GANHADORES</span> - A plataforma NÚMERO FAVORITO, utiliza para fins de resultado e apuração dos ganhadores, os resultados dos sorteios próprios, transmitidos exclusivamente pela plataforma NÚMERO FAVORITO, onde o acesso à transmissão é através do acesso individual de cada participante.
                    <br /><span>•DA IDADE MÍNIMA PARA USO DA PLATAFORMA</span> - O usuário deverá ter no mínimo completado 18 (dezoito) anos de idade para utilizar a plataforma NÚMERO FAVORITO, nossa tecnologia não permite o cadastro de usuário com idade inferior a idade mínima permitida pela plataforma.
                    <br /><span>•DO USO CONSCIENTE</span> – Devido a medidas de boas práticas adotadas pela plataforma NÚMERO FAVORITO, o usuário está limitado a adquirir a quantidade máxima de 10 (dez) apostas por sorteio.
                    <br /><span>•DA PROBABILIDADE DE GANHO</span> – A probabilidade estimada de ganho para cada aposta é de: 1:10 para a UNIDADE (10,000% de probabilidade de ganho); 1:100 para a DUQUE(1,000% de probabilidade de ganho); 1:1000 para a TERNO(0,100% de probabilidade de ganho) e 1:10000 para o QUADRA (0,010% de probabilidade de ganho).
                    <br /><span>•DOS ACESSOS À PLATAFORMA</span> – O usuário CLIENTE, apenas terá acesso à plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/cliente" target='_blank'>https://numerofavorito.com/cliente</a>; Já o usuário REVENDEDOR, apenas terá acesso à plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/venda" target="_blank">https://numerofavorito.com/venda</a>.

                </p>
                <i>Nosso propósito é revolucionar sua experiência com o entretenimento, oferecendo algo mais que diversão. Acreditamos que um relacionamento baseado na credibilidade, honestidade e transparência possibilita o sucesso contínuo. Estamos cientes de que sua vitória é o nosso sucesso!</i>
                {prevPath && <Link to={prevPath}>
                    <button>Voltar</button>
                </Link>}
            </div>
            <RodapeLP>
                <p></p>
            </RodapeLP>
        </div>
    )
}

const RegulationExpress = ({ prevPath }: { prevPath: string | undefined }) => {
    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.container}>
                <h2>REGULAMENTO DO SORTEIO RÁPIDO</h2>
                <p><span>•DO SORTEIO</span> - Os sorteios de quadras, ternos, duques e unidades, são únicos e exclusivos pela plataforma NÚMERO FAVORITO, as os sorteios serão individuais e os resultados instantâneos, disponibilizadas exclusivamente através do acesso à plataforma NÚMERO FAVORITO; Para fins de resultado do sorteio, são utilizados 4 (quatro) globos seletores digitais, onde cada globo seletor digital conterá 10 (dez) bolas digitais numeradas de 0 (zero) a 9 (nove), os globos seletores digitais são posicionados lateralmente um ao lado do outro, na posição sequencial 1º globo seletor, 2º globo seletor, 3º globo seletor e 4º globo seletor, onde, apenas 1 (uma) bola digital numerada é sorteada por vez em cada globo seletor; A bola numerada sorteada no globo seletor da 4ª posição, compõe o resultado na posição de QUADRA; A bola numerada sorteada no globo seletor da 3ª posição, compõe o resultado na posição de TERNO; A bola numerada sorteada no globo seletor da 2ª posição, compõe o resultado na posição de DUQUE; E por fim, a bola digital numerada sorteada no globo seletor da 1ª posição, compõe o resultado na posição de UNIDADE. “Exemplo: Se no globo seletor digital da 4ª posição, for sorteado a bola digital numerada 1; No globo seletor digital da 3ª posição, for sorteado a bola digital numerada 3; No globo seletor digital da 2ª posição, for sorteado a bola digital numerada 5; E no globo seletor digital da 1ª posição, for sorteado a bola digital numerada 8; O resultado será QUADRA 1358, TERNO 358, DUQUE 58 e UNIDADE 8.”; As apostas concorrentes iniciarão com 0000 e finalizarão com 9999, estrutura numérica de milhar.
                    <br /><span>•DA APURAÇÃO DO PRÊMIO DE QUADRA E SORTE EXTRA</span> - O prêmio de quadra, assim como Sorte Extra de terno, duque, unidade, vizinho de baixo, vizinho de cima e resultado invertido serão apurados única e exclusivamente através do resultado exclusivo do sorteio NÚMERO FAVORITO. Acerta o prêmio de QUADRA a aposta que tiver o número idêntico aos 4(quatro) números sorteados conforme a sequência da posição QUADRA, TERNO, DUQUE e UNIDADE;  Acerta a Sorte Extra de TERNO a aposta que tiver o número idêntico aos 3(três) últimos números do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição TERNO, DUQUE e UNIDADE); Acerta a Sorte Extra de DUQUE a aposta que tiver o número idêntico aos 2(dois) últimos números do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição DUQUE e UNIDADE); E acerta a Sorte Extra de UNIDADE (NÚMERO FAVORITO) a aposta que tiver no último número do resultado (Lê -se da direita para a esquerda, conforme a sequência da posição UNIDADE); Entende-se que as contemplações por aproximação e inversão de resultado são denominadas de “Sorte Extra”, incluem também como “Sorte Extra” os prêmios de aproximação à QUADRA: Prêmio “Vizinho de baixo” quando a aposta tem 1 (um)  número inferior à quadra sorteada; Prêmio “Vizinho de cima” quando a aposta tem 1 (um) número superior à quadra sorteada; Prêmio “Resultado invertido” quando a aposta tem os 04 (quatro) números totalmente invertidos à quadra sorteada.
                    <br /><span>•DO VALOR DA APOSTA, CORTESIAS E GRATUIDADES</span> - O valor pago pela aposta tem como base de cálculo para cobrança apenas as apostas de QUADRA, já que as apostas em TERNO, DUQUE, UNIDADE, VIZINHO DE BAIXO, VIZINHO DE CIMA e RESULTADO INVERTIDO são cortesias (gratuidades) ofertadas pela plataforma NÚMERO FAVORITO ao usuário, sem qualquer custo adicional; Entende-se que o usuário ao pagar as apostas, está pagando o valor referente às apostas de QUADRA, porém, concorrendo gratuitamente a Sorte Extra por aproximação à QUADRA, com o TERNO, DUQUE, UNIDADE, VIZINHO DE BAIXO, VIZINHO DE CIMA e RESULTADO INVERTIDO.
                    <br /><span>•DO SALDO DO USUÁRIO, DO CRÉDITO PARA APOSTAR, DA UTILIZAÇÃO DO SALDO E CRÉDITO PARA PAGAMENTO DE NOVAS APOSTAS, DA SOLICITAÇÃO DE SAQUE DO VALOR DISPONÍVEL PARA SAQUE, DA DISPONIBILIDADE E RESGATE DO PRÊMIO</span> - O valor do prêmio será disponibilizado para resgate imediatamente após a apuração do resultado, onde será automaticamente incrementado ao saldo do valor para saque do usuário, e estará acessível para consultas, visualizações e solicitações de saques no endereço eletrônico: <a href="https://numerofavorito.com/cliente">https://numerofavorito.com/cliente</a>; O saldo do valor para saque disponível do usuário, assim como o crédito para apostar também poderão ser utilizados como forma de pagamento para novas apostas;  A solicitação de saque do valor disponível para saque, apenas estará disponível para ser sacado quando o saldo do valor para saque for igual ou superior a R$ 7,50 (Sete reais e cinquenta centavos);  Será cobrado uma taxa de custo bancário no valor de R$ 2,50 (Dois reais e cinquenta centavos) para cada saque efetuado, ao solicitar o saque do valor para saque, será efetuado um crédito de pagamento via PIX vinculado ao CPF (Cadastro de Pessoa Física) do usuário (o saldo disponível é pessoal e intransferível), não haverá a possibilidade do envio do crédito de pagamento via PIX para outrem, também haverá a possibilidade de solicitar parcialmente o valor para saque, uma vez solicitado o saque, será deduzido a taxa de custo bancário,  o valor líquido restante será creditado integralmente na conta bancária do usuário.
                    <br /><span>•DA COMPRA DE CRÉDITOS PARA APOSTAR</span> - O usuário a qualquer momento poderá comprar créditos pré-pagos para a única e exclusiva finalidade de pagar suas próprias apostas na plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/cliente" target='_blank'>https://numerofavorito.com/cliente</a>; Sendo vedada a solicitação de saque do valor pré-pago “CRÉDITOS PARA APOSTAR”; Os valores pré-pagos não são de natureza corrente, são indisponíveis para eventuais saques, a plataforma NÚMERO FAVORITO está em conformidade com as boas práticas junto ao SFN – Sistema Financeiro Nacional, em conformidade com a Lei 9.613/98 – Lei de Prevenção à Lavagem de Dinheiro e ao Financiamento do Terrorismo.
                    <br /><span>•DOS PRÊMIOS E SORTE EXTRA</span> - Para efeito do pagamento dos prêmios e pagamento da Sorte Extra, será aplicado a modalidade de contemplação individual, onde será devido ao ganhador o valor bruto atribuído ao prêmio e a Sorte Extra.
                    <br />Entende-se que, somente concorrerá à contemplação do prêmio bruto de Quadra a aposta com acertos de todas as 04 (quatro) casas decimais do número sorteado “milhar”, conforme exemplo abaixo; <u>Sorte Extra</u>: Somente concorrerá à contemplação a Sorte Extra de “Terno” as apostas com acertos nas últimas 03 (três) casas decimais do número sorteado “centena de milhar” conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Duque” as apostas com acertos nas últimas 02 (duas) casas decimais do números sorteados “dezena de milhar” conforme exemplo abaixo; E somente concorrerá à contemplação a Sorte Extra de “Unidade” as apostas com acertos na última 01 (uma) casa decimal do números sorteados “unidade de milhar” conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Vizinho de baixo” quando a aposta tiver na última casa decimal “unidade” 1 (um) número inferior à quadra sorteada, conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de “Vizinho de cima” quando a aposta tiver na última casa decimal “unidade” 1 (um) número superior à quadra sorteada, conforme exemplo abaixo; Somente concorrerá à contemplação a Sorte Extra de  “Resultado invertido” quando a aposta tiver os 04 (quatro) números totalmente invertidos à quadra sorteada, onde o número sorteado na posição de unidade passa para posição da quadra, o número sorteado na posição de duque passa para a posição de terno, o número sorteado na posição de terno passa para a posição de duque, e o número sorteado na posição de quadra passa para a posição de unidade, conforme exemplo abaixo.
                    <br /><u>Exemplo de contemplação:</u><br />
                    Se o número sorteado no resultado for, “1358”;<br />
                    Acerto de Quadra - Somente concorrerá ao prêmio bruto de Quadra a aposta com acertos de todas as 04 (quatro) casas decimais “<u>1358</u>”;<br />
                    “Sorte Extra”<br />
                    Acerto de Terno - Somente concorrerá a Sorte Extra de Terno as apostas com acertos nas últimas 03 (três) casas decimais “*<u>358</u>”;<br />
                    Acerto de Duque - Somente concorrerá a Sorte Extra de Duque as apostas com acertos nas últimas 02 (duas) casas decimais “**<u>58</u>”; e<br />
                    Acerto de Unidade - Somente concorrerá a Sorte Extra de Unidade as apostas com acertos na última 01 (uma) casa decimal “***<u>8</u>”; <br />

                    Acerto de Vizinho de baixo - Somente concorrerá a Sorte Extra de Vizinho de baixo a aposta com o número inferior à quadra sorteada “1357”;<br />
                    Acerto de Vizinho de cima - Somente concorrerá a Sorte Extra de Vizinho de cima a aposta com o número superior à quadra sorteada “1359”; e<br />
                    Acerto de Resultado invertido - Somente concorrerá a Sorte Extra de Resultado invertido a aposta tiver os 04 (quatro) números totalmente invertidos à quadra sorteada “8531”.<br />
                    <br /><span>•DISPOSIÇÕES GERAIS</span> - Cada ganhador, a contar da data da efetivação da aposta, automaticamente autorizará o uso, pela Plataforma NÚMERO FAVORITO, pelo período de 12 (doze) meses, da imagem, voz, performance, nomes e outras identificações a estes relativos, em filmes publicitários veiculados em mídia eletrônica, internet, fotos, cartazes, anúncios em jornais, revistas e outros e em qualquer outra forma de mídia impressa ou eletrônica, a serem divulgados no território nacional, referentes à divulgação da conquista do(s) prêmio(s), sem qualquer ônus àquela; O processamento dos dados pessoais do participante será feito de acordo com a Lei Geral de Proteção de Dados – LGPD; O usuário tem à disposição um canal de atendimento para o esclarecimento de eventuais dúvidas; Os problemas técnicos ou mau funcionamento decorrentes, mas não limitados às ocorrências, tais como, erros de hardware ou software de propriedade do usuário; defeito ou falha do computador, telefone, smartphone, cabo, satélite, rede, eletroeletrônico, equipamento sem fio, que prejudiquem o acesso ou a conexão à Internet; erro ou limitação por parte dos provedores de serviço, hospedagem; erro na transmissão das informações; atraso ou falhas no envio e/ou recebimento de mensagens de SMS, whatsapp, correio eletrônico e outros; ações de hackers, vírus, bugs, worms, que venham impedir, dificultar e prejudicar o acesso ao site, não poderão ser atribuídos à plataforma NÚMERO FAVORITO
                </p>
                <h2>Diretrizes</h2>
                <p><span>•DA MODALIDADE DA PLATAFORMA</span> – A plataforma NÚMERO FAVORITO é uma nova modalidade de entretenimento pessoal, classificada como uma Startup FunTech, não se enquadra estruturalmente como um sorteio tradicional, tornou-se uma das maiores referências em FunTech, startup que utiliza inovação tecnológica para transformar o setor de entretenimento e lazer.
                    <br /><span>•DA TECNOLOGIA DA PLATAFORMA</span> – A nossa Technology Hub é nativa do Silicon Valley, CA - USA. Tecnologia implementada com lato amparo da Lei Complementar no 182 de 1o de junho de 2021.
                    <br /><span>•DA ESCOLHA DOS NÚMEROS</span> - O usuário escolhe a quantidade de apostas que deseja comprar entre 01 e 10 apostas, logo o usuário deverá preencher manualmente ou automaticamente cada aposta com 04 (quatro) algarismos “formato numeral de milhar”, podendo escolher quaisquer dos números naturais, exemplo: (0; 1; 2; 3; 4; 5; 6; 7; 8 e 9).
                    <br /><span></span>•Baseados na disponibilidade dos 10 (dez) algarismos disponíveis na plataforma NÚMERO FAVORITO: (0, 1, 2, 3 ,4 ,5, 6, 7, 8 e 9), utilizamos todas as combinações possíveis entre os numerais 0000 e 9999, nos possibilitando a geração de todas as 10.000 (Dez mil) apostas que comportam dentro da estrutura numérica de milhar, as quais utilizamos como base para apuração do resultado de todos os prêmios e Sorte Extra do sorteio (QUADRA / TERNO / DUQUE / UNIDADE).
                    <br /><span>•DA DISTRIBUIÇÃO DOS PRÊMIOS E SORTE EXTRA</span> -  A plataforma NÚMERO FAVORITO, premia os ganhadores com a totalidade dos prêmios e Sorte Extra previamente anunciados, sendo os prêmios e Sorte Extra distribuídos conforme cada categoria de acertos e suas respectivas contemplações (QUADRA / TERNO / DUQUE / UNIDADE).
                    <br /><span>•DA BONIFICAÇÃO CARA OU COROA</span> -  Ao premiar a(s) aposta(s) e/ou outro(s) prêmio(s) será disponibilizada a chance da bonificação “cara ou coroa”, onde ao lançar a moeda virtual com duas faces (cara e coroa) será dada a oportunidade de duplicar o valor do prêmio (x2), onde as chances para duplicar o valor do prêmio são aleatórias; Entende-se que ao lançar a moeda virtual “cara ou coroa” o prêmio será duplicado quando a moeda virtual parar com a face coroa “visível” e a face cara “oculta”; Quando a moeda virtual parar com a face cara “visível” e a face coroa “oculta” o prêmio permanecerá com o valor original (o valor que foi originalmente percebido na premiação da aposta); Havendo o resultado bonificação (face coroa da moeda virtual “visível”), o valor referente à bonificação será creditado e ficará disponível imediatamente em créditos para apostar; Já o valor originalmente percebido na premiação da aposta será creditado e ficará disponível imediatamente em valor para saque.
                    <br /><span>•DO RESULTADO E APURAÇÃO DOS GANHADORES</span> - A plataforma NÚMERO FAVORITO, utiliza para fins de resultado e apuração dos ganhadores, os resultados dos sorteios individuais e automatizados, exclusivamente pela plataforma NÚMERO FAVORITO, onde o acesso à plataforma é através do login individual de cada usuário (entrar).
                    <br /><span>•DA IDADE MÍNIMA PARA USO DA PLATAFORMA</span> - O usuário deverá ter no mínimo completado 18 (dezoito) anos de idade para utilizar a plataforma NÚMERO FAVORITO, nossa tecnologia não permite o cadastro de usuário com idade inferior a idade mínima permitida pela plataforma.
                    <br /><span>•DO USO CONSCIENTE</span> – Devido a medidas de boas práticas adotadas pela plataforma NÚMERO FAVORITO, o usuário está limitado a adquirir a quantidade máxima de 10 (dez) apostas em cada sorteio.
                    <br /><span>•DA PROBABILIDADE DE GANHO</span> – A probabilidade estimada de ganho para cada aposta é de: 1:10 para a UNIDADE (10,000% de probabilidade de ganho); 1:100 para o DUQUE (1,000% de probabilidade de ganho); 1:1000 para o TERNO (0,100% de probabilidade de ganho) e 1:10000 para o QUADRA (0,010% de probabilidade de ganho).
                    <br /><span>•DOS ACESSOS À PLATAFORMA</span> – O usuário CLIENTE, apenas terá acesso à plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/cliente" target='_blank'>https://numerofavorito.com/cliente</a>; Já o usuário REVENDEDOR, apenas terá acesso à plataforma NÚMERO FAVORITO, através do endereço eletrônico: <a href="https://numerofavorito.com/venda" target="_blank">https://numerofavorito.com/venda</a>.

                </p>
                <i>Nosso propósito é revolucionar sua experiência com o entretenimento, oferecendo algo mais que diversão. Acreditamos que um relacionamento baseado na credibilidade, honestidade e transparência possibilita o sucesso contínuo. Estamos cientes de que sua vitória é o nosso sucesso!</i>
                {prevPath && <Link to={prevPath}>
                    <button>Voltar</button>
                </Link>}
            </div>
            <RodapeLP>
                <p></p>
            </RodapeLP>
        </div>
    )
}