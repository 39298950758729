import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, TableCell, TableRow, Typography, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import RotateComponente from '../../../components/rotate';
import TableView from '../../../components/table';
import { WinnersProps, prizeDrawsProps } from '../../../types';
import moment from 'moment';
import { NameMask, maskCPF } from '../../../utils/mask';
import { orderWinnerByGroup } from '../../../utils';

interface TableCommissionProps {
    open: boolean,
    handleClose: () => void;
    prizeDraw: prizeDrawsProps | null;
}

export default function TableResults({ open, handleClose, prizeDraw }: TableCommissionProps) {

    const theme = useTheme();

    const prize = ['Quadra', 'Prêmio de TERNO - Cortesia por aproximação', 'Prêmio de DUQUE - Cortesia por aproximação', 'Prêmio de UNIDADE - Cortesia por aproximação']
    const prizeLegible = ['QUADRA', 'TERNO', 'DUQUE', 'UNIDADE']

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0, overflowY: 'hidden' } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                overflow: 'hidden'
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <Typography textAlign="center" variant="h4" my={4}>RESULTADO</Typography>
                    <Grid style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        margin: "20px 0px",
                        color: 'black',
                    }}>
                        <Typography>CONCURSO: {prizeDraw?.prizeDrawId}, {String(moment(prizeDraw?.prizeDrawAt).format("dddd")).toUpperCase()}</Typography>
                        <Typography>{String(moment(prizeDraw?.prizeDrawAt).format("L")).toUpperCase()}</Typography>
                        <Typography>às {String(moment(prizeDraw?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas </Typography>
                    </Grid>
                    <TableView
                        align='center'
                        header={['PRÊMIO', 'NÚMEROS SORTEADOS', 'QUANTIDADE DE PREMIADOS', 'VALOR LÍQUIDO DE CADA PRÊMIO']}
                    >
                        <TableBody>
                            {Array.from({ length: 4 }).map((_, index) => {
                                let caracter = ""
                                for (let i = 0; i < index; i++) {
                                    caracter += "*"
                                }
                                return (
                                    <TableRow>
                                        <TableCell align="center">
                                            <strong>{prizeLegible[index]}</strong>
                                            {index === 3 && <span style={{
                                                fontSize: '9px'
                                            }}><br />Número Favorito</span>}
                                        </TableCell>
                                        <TableCell align="center">
                                            {caracter}{prizeDraw?.results && prizeDraw.results.find(a => a.order === index + 1)?.number}
                                        </TableCell>
                                        <TableCell align="center">
                                            {prizeDraw?.results && prizeDraw.results.find(a => a.order === index + 1)?.hits}
                                        </TableCell>
                                        {index === 3 ?
                                            <TableCell align="center">
                                                {prizeDraw?.results && (prizeDraw.results.find(a => a.order === 4)?.prizeValue === null ? '2 Apostas de cortesia' : (prizeDraw?.results.find(a => a.order === 4)?.amount as number ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}
                                            </TableCell> :
                                            <TableCell align="center">
                                                {prizeDraw?.results && (prizeDraw.results.find(a => a.order === index + 1)?.amount as number ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </TableView>
                    <Typography textAlign="center" variant="h4" my={4}>LISTA GERAL DOS GANHADORES NO CONCURSO</Typography>
                    <TableView
                        align='center'
                        header={['Nome e sobrenome', 'Documento', 'Quantidade de acertos', 'Prêmio', 'Valor da Premiação']}
                    >
                        <TableBody>
                            {
                                orderWinnerByGroup(prizeDraw?.winers as WinnersProps[])?.map(({
                                    id,
                                    cpf,
                                    name,
                                    order,
                                    total,
                                    quantity
                                }) => {
                                    const nameWinner = NameMask(name ?? "")
                                    return (
                                        <TableRow key={id}>
                                            <TableCell align="center">
                                                {nameWinner.firstName} {nameWinner.lastName}
                                            </TableCell>
                                            <TableCell align="center">
                                                {maskCPF(cpf)}
                                            </TableCell>
                                            <TableCell align="center">
                                                {quantity}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prize[order - 1]}
                                            </TableCell>
                                            <TableCell align="center">
                                                {prizeDraw?.useBonus && order === 4 ? `${2 * quantity} Apostas de cortesia` : total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog >
    );
}