import { Card, CardContent, Container, Divider, Grid, Typography, useTheme } from "@mui/material"

import SubtractBG from "assets/imgs/Subtract.png"
import SVGLOGO from "assets/imgs/logo.svg"

const Subtract = () => {

    const theme = useTheme();

    return (
        <Grid container p={2}>
            <Grid
                container
                justifyContent={'center'}
                sx={{
                    backgroundImage: `url(${SubtractBG})`,
                    height: '100%',
                    backgroundSize: 'cover'
                }}
                p={3}
                gap={2}
            >
                <img src={SVGLOGO} width={200} />
                <Typography fontWeight={"bold"} variant="h3">
                    Meu bilhete da sorte
                </Typography>
                <Typography fontWeight={"400"} variant="h4">
                    Data do concurso: 00/00/2024
                    Sábado
                </Typography>
                <Divider style={{ borderStyle: 'dashed', opacity: .5 }} />
                <Grid container>
                    <Typography fontWeight={"bold"} variant="h4">
                        Nome nome sobrenome sobrenome
                    </Typography>
                    <Typography fontWeight={"400"} variant="h4">
                        CPF: 000.000.000-00
                    </Typography>
                    <Typography fontWeight={"400"} variant="h4">
                        Valor total pago: R$ 0,00
                    </Typography>
                    <Typography fontWeight={"400"} variant="h4">
                        Forma de Pagamento: PIX
                    </Typography>
                </Grid>
                <Divider style={{ borderStyle: 'dashed', opacity: .5 }} />
                <Grid container gap={2}>
                    <Typography fontWeight={"bold"} variant="h5">
                        Esse bilhete da sorte está concorrendo aos Prêmios Brutos abaixo:
                    </Typography>
                    <Grid container justifyContent={'space-between'}>
                        <Card variant="outlined"
                            sx={{
                                borderRadius: 3,
                                bgcolor: theme.palette.success.light + 80,
                                borderColor: theme.palette.success.main
                            }}>
                            <CardContent sx={{ paddingBottom: 0 }}>
                                <Typography fontWeight={"bold"} variant="h3">
                                    R$ 7.000,00
                                </Typography>
                                <Typography fontWeight={"400"} variant="h5">
                                    (Sete mil Reais)
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card variant="outlined"
                            sx={{
                                borderRadius: 3,
                                bgcolor: theme.palette.success.light + 80,
                                borderColor: theme.palette.success.main
                            }}>
                            <CardContent sx={{}}>
                                <Typography fontWeight={"bold"} variant="h3">
                                    Quadra
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Subtract;