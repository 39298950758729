import { MdLiveTv } from "react-icons/md";
import useQuery from "../hooks/useQuery";
import { FaWhatsapp } from "react-icons/fa6"
import { useNavigate } from "react-router-dom";
import useAuth from "../context/auth";
import { Grid, MenuItem, Typography, useTheme } from "@mui/material";
import RoutesPath from "utils/routesBases";
import { MenuText } from "components/menu";
import { constants, suportNumber } from "utils";
import moment from "moment";
import { prizeDrawsProps } from "types";
import { BsCoin } from "react-icons/bs";
import { BiMoneyWithdraw } from "react-icons/bi";
import { formatCurrency } from "utils/mask";
import { Fragment } from "react/jsx-runtime";
import { Tooltip } from "react-tooltip";
import { CSSProperties, useState } from "react";
import AtalhoImg from "../assets/imgs/express/LOGO LARANJA.png"

export const MenuNav = ({
    anchorElResults,
    anchorElTickets,
    setAnchorElResults,
    setAnchorElTickets,
    handleCloseResults,
    handleCloseTickets,
    drawParticipation,
    prizeDraws,
    setPrizeDrawsResult,
    setShowPrize,
    anchorElRegulation,
    handleCloseRegulation,
    setAnchorElRegulation,
    handleOpenModalInstallApp
}: {
    anchorElTickets: boolean,
    anchorElResults: boolean,
    anchorElRegulation: boolean,
    setAnchorElResults: (value: boolean) => void,
    setAnchorElRegulation: (value: boolean) => void,
    setAnchorElTickets: (value: boolean) => void,
    handleCloseTickets: () => void,
    handleCloseResults: () => void,
    handleCloseRegulation: () => void,
    drawParticipation: null | {
        id: number;
        prizeDrawAt: string
    }[],
    prizeDraws: null | prizeDrawsProps[],
    setPrizeDrawsResult: (value: null | prizeDrawsProps) => void,
    setShowPrize: (value: boolean) => void,
    handleOpenModalInstallApp: () => void
}): JSX.Element => {
    const query = useQuery();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { user, userCredits, tokenClient } = useAuth();
    const _sallerquery = query.get('s')
    const clientIndicate = query.get('c')

    const [tooltipVisible, setTooltipVisible] = useState<number | null>(null);

    const handleClick = (index: number) => {
        if (menus[index]?.popup?.disabled) {
            return
        }
        if (tooltipVisible === index) {
            setTooltipVisible(null);
        } else {

            setTooltipVisible(index);
            setTimeout(() => setTooltipVisible(null), 2000);
        }
    };

    const menus: {
        label: string | JSX.Element,
        type: "button" | "link",
        handleClick?: () => void,
        icon?: JSX.Element,
        to?: string,
        state?: any,
        disabled?: boolean,
        popup?: {
            whenDisabled: boolean,
            label: string,
            disabled?: boolean
        }
    }[] = [
            {
                label: "Atendimento",
                icon: <FaWhatsapp color="green" size={16} />,
                type: "button",
                handleClick: () => {
                    window.open(`https://wa.me/+55${suportNumber}`, "_blank")
                }
            },
            {
                label: <MenuText
                    open={anchorElRegulation}
                    handleOpen={() => setAnchorElRegulation(true)}
                    title="Regulamento"
                    handleClose={handleCloseRegulation}
                >
                    {
                        [{ url: "/regulamento-sorteio-ao-vivo", label: "Regulamento sorteio ao vivo" }, { url: "/regulamento-sorteio-rapido", label: "Regulamento sorteio rápido" }].map(({ label, url }) =>
                            <MenuItem onClick={() => {
                                navigate(url + `?s=${_sallerquery ?? ""}${clientIndicate ? `&c=${clientIndicate}` : ''}`, {
                                    state: {
                                        prev_path: window.location.pathname
                                    }
                                })
                                handleCloseRegulation();
                            }}>
                                {label}
                            </MenuItem>
                        )
                    }
                </MenuText>,
                type: "button",
            },
            {
                label: "Grupo WhatsApp",
                type: "button",
                handleClick: () => {
                    window.open("https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo", "_blank")
                }
            },
            {
                label: "Assistir Sorteios",
                type: "button",
                icon: <MdLiveTv color={palette.primary.main} size={16} />,
                handleClick: () => {
                    navigate(RoutesPath.sorteio.path, {
                        state: {
                            prev_path: window.location.pathname
                        }
                    })
                },
                disabled: !tokenClient
            },
            {
                label: <MenuText
                    open={anchorElTickets}
                    handleOpen={() => tokenClient && setAnchorElTickets(true)}
                    title="Meus Bilhetes"
                    handleClose={handleCloseTickets}
                >
                    {
                        drawParticipation?.map(({ id, prizeDrawAt }) =>
                            <MenuItem onClick={() => {
                                navigate(RoutesPath.Clients.bilhetes.path + `/${id}`)
                                handleCloseTickets();
                            }}>
                                Concurso {id} {String(moment(prizeDrawAt).format("dddd").split("-feira")[0]).toUpperCase()} {String(moment(prizeDrawAt).format("DD/MM/YY")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()}h
                            </MenuItem>
                        )
                    }
                </MenuText>,
                type: "button",
                disabled: !tokenClient
            },
            {
                label: <MenuText
                    open={anchorElResults}
                    handleOpen={() => tokenClient && setAnchorElResults(true)}
                    title="Meus Resultados"
                    handleClose={handleCloseResults}
                >
                    {
                        (!prizeDraws || prizeDraws?.length === 0) &&
                        <MenuItem onClick={() => {
                            handleCloseResults();
                        }}>
                            Não há resultados
                        </MenuItem>
                    }
                    {
                        prizeDraws?.sort((a, b) => b.prizeDrawId - a.prizeDrawId).map((data) => (
                            <MenuItem key={data?.id} onClick={() => {
                                handleCloseResults();
                                setPrizeDrawsResult(data);
                            }}>
                                {(String(moment(data?.prizeDrawAt).format("dddd")).split("-feira")[0]).toUpperCase()} {String(moment(data?.prizeDrawAt).format("DD/MM/YY")).toUpperCase()} às {String(moment(data?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()}h
                            </MenuItem>
                        ))
                    }
                </MenuText>,
                type: "button",
                disabled: !tokenClient

            },
            {
                label: "Meus Prêmios",
                type: "button",
                handleClick: () => setShowPrize(true),
                disabled: !tokenClient
            },
            // {
            //     label: "Top 10",
            //     type: "button",
            //     disabled: true
            // },
            {
                label: <>Baixe o APP <img src={AtalhoImg} style={{
                    width: '10px',
                    height: '10px'
                }} /></>,
                type: "button",
                handleClick: handleOpenModalInstallApp
            },
            {
                label: <>Indique & Ganhe <BsCoin size={13} color="#0404cd" style={{ marginBottom: -2 }} /> </>,
                type: "button",
                disabled: true
            },
            {
                label: "SAQUE AQUI",
                type: "link",
                icon: <BiMoneyWithdraw size={16} color={palette.primary.main} />,
                to: RoutesPath.Clients.saque.path,
                disabled: userCredits?.credits < (constants.minPixTransfer) || !tokenClient,
                popup: {
                    whenDisabled: true,
                    label: `Saque disponível apenas para valores acima de ${formatCurrency(constants.minPixTransfer)}. (Conforme Regulamento)`,
                    disabled: !tokenClient
                }
            },
        ]

    return menus.map((menu, index) => {
        const isDisabled = menu.disabled;
        const shouldShowTooltip = menu.popup?.whenDisabled && isDisabled;

        if (menu.type === "button") {
            return (
                <Fragment key={'nav' + index}>
                    <Grid
                        data-tooltip-id={`popup-${menu.label}`}
                        data-tooltip-delay-hide={1000}
                        display={"flex"}
                        gap={.5}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            handleClick(index)
                            if (!menu?.disabled && menu?.handleClick) menu.handleClick()
                        }}
                        sx={{
                            cursor: 'pointer',
                            ...(menu.disabled && {
                                '.MuiTypography-root': {
                                    color: 'gray !important',
                                },
                                'svg': {
                                    color: 'gray !important',
                                }
                            })
                        }}
                    >
                        {menu.icon && menu.icon}
                        <Typography fontSize={13}>{menu.label}</Typography>
                        {index < (menus.length - 1) && <Typography fontSize={13} fontWeight={"bold"} color={"#0404cd"}>|</Typography>}
                    </Grid>
                    {menu.popup && (
                        <ToolTipClick
                            id={`popup-${menu.label}`}
                            label={menu.popup.label}
                            open={tooltipVisible === index}
                            disabled={!shouldShowTooltip}
                        />
                    )}
                </Fragment>
            )
        } else {
            return (
                <Fragment key={'nav' + index}>

                    <Grid
                        data-tooltip-id={`popup-${menu.label}`}
                        display={"flex"}
                        gap={.5}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            handleClick(index)
                            if (!menu?.disabled && menu.to) {
                                navigate(menu.to, {
                                    ...(menu.state && {
                                        state: menu.state
                                    })
                                })
                            }
                        }}
                        sx={{
                            cursor: 'pointer',
                            ...(menu.disabled && {
                                '.MuiTypography-root': {
                                    color: 'gray !important',
                                },
                                'svg': {
                                    color: 'gray !important',
                                }
                            })
                        }}
                    >
                        {menu.icon && menu.icon}
                        <Typography fontSize={13}>{menu.label}</Typography>
                        {index < (menus.length - 1) && <Typography fontSize={13} fontWeight={"bold"} color={"#0404cd"}>|</Typography>}
                    </Grid>
                    {menu.popup && (
                        <ToolTipClick
                            id={`popup-${menu.label}`}
                            label={menu.popup.label}
                            open={tooltipVisible === index}
                            disabled={!shouldShowTooltip}
                        />
                    )}
                </Fragment>

            )
        }
    }) as any
}

export const MenuNavSeller = ({
    anchorElRegulation,
    handleCloseRegulation,
    setAnchorElRegulation,
}: {
    anchorElRegulation: boolean,
    setAnchorElRegulation: (value: boolean) => void,
    handleCloseRegulation: () => void,
}) => {

    const query = useQuery();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const { user, userCredits, tokenClient } = useAuth();
    const _sallerquery = query.get('s')
    const clientIndicate = query.get('c')

    const [tooltipVisible, setTooltipVisible] = useState<number | null>(null);

    const handleClick = (index: number) => {
        if (menus[index]?.popup?.disabled) {
            return
        }
        if (tooltipVisible === index) {
            setTooltipVisible(null);
        } else {

            setTooltipVisible(index);
            setTimeout(() => setTooltipVisible(null), 2000);
        }
    };
    
    const menus: {
        label: string | JSX.Element,
        type: "button" | "link",
        handleClick?: () => void,
        icon?: JSX.Element,
        to?: string,
        state?: any,
        disabled?: boolean,
        popup?: {
            whenDisabled: boolean,
            label: string,
            disabled?: boolean
        }
    }[] = [
            {
                label: "Atendimento",
                icon: <FaWhatsapp color="green" size={16} />,
                type: "button",
                handleClick: () => {
                    window.open(`https://wa.me/+55${suportNumber}`, "_blank")
                }
            },
            {
                label: <MenuText
                    open={anchorElRegulation}
                    handleOpen={() => tokenClient && setAnchorElRegulation(true)}
                    title="Regulamento"
                    handleClose={handleCloseRegulation}
                >
                    {
                        [{ url: "/regulamento-sorteio-ao-vivo", label: "Regulamento sorteio ao vivo" }, { url: "/regulamento-sorteio-rapido", label: "Regulamento sorteio rápido" }].map(({ label, url }) =>
                            <MenuItem onClick={() => {
                                navigate(url + `?s=${_sallerquery ?? ""}${clientIndicate ? `&c=${clientIndicate}` : ''}`, {
                                    state: {
                                        prev_path: window.location.pathname
                                    }
                                })
                                handleCloseRegulation();
                            }}>
                                {label}
                            </MenuItem>
                        )
                    }
                </MenuText>,
                type: "button",
            },
            {
                label: "Grupo WhatsApp",
                type: "button",
                handleClick: () => {
                    window.open("https://chat.whatsapp.com/JgXQM7gnO6tJBcXFG64gbo", "_blank")
                }
            },
            {
                label: "Indique revendedores",
                type: "link",
                to: "/venda/convite-revendedor"
            }
        ]

    return menus.map((menu, index) => {
        const isDisabled = menu.disabled;
        const shouldShowTooltip = menu.popup?.whenDisabled && isDisabled;

        if (menu.type === "button") {
            return (
                <Fragment key={'nav' + index}>
                    <Grid
                        data-tooltip-id={`popup-${menu.label}`}
                        data-tooltip-delay-hide={1000}
                        display={"flex"}
                        gap={.5}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            handleClick(index)
                            if (!menu?.disabled && menu?.handleClick) menu.handleClick()
                        }}
                        sx={{
                            cursor: 'pointer',
                            ...(menu.disabled && {
                                '.MuiTypography-root': {
                                    color: 'gray !important',
                                },
                                'svg': {
                                    color: 'gray !important',
                                }
                            })
                        }}
                    >
                        {menu.icon && menu.icon}
                        <Typography fontSize={13}>{menu.label}</Typography>
                        {index < (menus.length - 1) && <Typography fontSize={13} fontWeight={"bold"} color={"#0404cd"}>|</Typography>}
                    </Grid>
                    {menu.popup && (
                        <ToolTipClick
                            id={`popup-${menu.label}`}
                            label={menu.popup.label}
                            open={tooltipVisible === index}
                            disabled={!shouldShowTooltip}
                        />
                    )}
                </Fragment>
            )
        } else {
            return (
                <Fragment key={'nav' + index}>

                    <Grid
                        data-tooltip-id={`popup-${menu.label}`}
                        display={"flex"}
                        gap={.5}
                        flexDirection={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        onClick={() => {
                            handleClick(index)
                            if (!menu?.disabled && menu.to) {
                                navigate(menu.to, {
                                    ...(menu.state && {
                                        state: menu.state
                                    })
                                })
                            }
                        }}
                        sx={{
                            cursor: 'pointer',
                            ...(menu.disabled && {
                                '.MuiTypography-root': {
                                    color: 'gray !important',
                                },
                                'svg': {
                                    color: 'gray !important',
                                }
                            })
                        }}
                    >
                        {menu.icon && menu.icon}
                        <Typography fontSize={13}>{menu.label}</Typography>
                        {index < (menus.length - 1) && <Typography fontSize={13} fontWeight={"bold"} color={"#0404cd"}>|</Typography>}
                    </Grid>
                    {menu.popup && (
                        <ToolTipClick
                            id={`popup-${menu.label}`}
                            label={menu.popup.label}
                            open={tooltipVisible === index}
                            disabled={!shouldShowTooltip}
                        />
                    )}
                </Fragment>

            )
        }
    }) as any
}

export const ToolTipClick = ({
    id,
    label,
    open,
    disabled,
    style
}: {
    id: string,
    label: string,
    open: boolean,
    disabled?: boolean,
    style?: CSSProperties
}) => {
    return (
        <Tooltip
            id={id}
            content={label}
            disableTooltip={() => disabled}
            style={{
                zIndex: 33,
                maxWidth: '85vw',
                backgroundColor: 'yellow',
                color: 'black',
                ...style
            }}
            isOpen={open}
        />
    )
}