import { ReactNode } from "react";

export function RodapeLP({ children }: {
    children?: ReactNode
}) {
    return (
        <footer className="rodape">
            {children ?? <p>Technology Hub by Silicon Valley, CA - USA. Tecnologia implementada com lato amparo da Lei Complementar nº 182 de 1º de Junho de 2021.</p>}
            <p>© 2021 - 2024 Copyright — Todos os direitos reservados</p>
        </footer>
    )
}