import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import useAuth from "../../../context/auth"
import { useEffect, useState } from "react"
import { CommissionService } from "../../../service/commission"
import { Roles } from "../../../utils"
import { round } from "../../../utils/mask"

export interface IPolicies {
    id: number,
    name: string | null,
    description: string | null,
    userId: string | null,
    role: number,
    level: number,
    active: true,
    percentage: number,
    generalId: number
}

const CommissionsTable = () => {

    const [policies, setPolicies] = useState<IPolicies[]>([])

    const { user } = useAuth()

    const CommissionCell = ({ text }: { text: string }) => {
        return (
            <TableCell sx={{ textAlign: 'center' }}>{text}</TableCell>
        )
    }

    useEffect(() => {
        CommissionService.getCommissionPolicies()
            .then(({ data }) => {
                setPolicies(data.policies)
            })
    }, [])


    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'}>
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"}>
                Tabela de comissões
            </Typography>
            <Grid container justifyContent={"space-between"} alignItems="center" gap={2}>
                {user?.role === Roles.seller &&
                    <>
                        <Typography variant="h4" textAlign={"center"} fontWeight={"500"}>
                            Tabela de comissionamento sobre minhas vendas por cada aposta
                        </Typography>
                        <Table>
                            <TableHead sx={{ bgcolor: "#316FE8" }}>
                                <TableRow>
                                    {/* <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>Total de vendas por concurso</TableCell> */}
                                    <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>Tabela</TableCell>
                                    <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>Comissão a receber</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow sx={{ bgcolor: '#316FE81A' }}>
                                    {/* <CommissionCell text={"Até R$ 999,99"} /> */}
                                    <CommissionCell text={`${policies?.find((p) => p.level === 0 && p.role === Roles.seller)?.description ?? "Venda de aposta"}`} />
                                    <CommissionCell text={`${(policies?.find((p) => p.level === 0 && p.role === Roles.seller)?.percentage as number * 100) || 30}%`} />
                                </TableRow>
                                {/* <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                    <CommissionCell text="Acima de R$ 1.000,00" />
                                    <CommissionCell text="Bonificada" />
                                    <CommissionCell text="35,0%" />
                                </TableRow> */}
                            </TableBody>
                        </Table>
                    </>}
                {
                    user?.role === Roles.general &&
                    <>
                        <Typography variant="h4" textAlign={"center"} fontWeight={"500"}>
                            Tabela de comissionamento sobre as vendas
                        </Typography>
                        <Table>
                            <TableHead sx={{ bgcolor: "#316FE8" }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>Nome</TableCell>
                                    <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>Comissão a receber</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {policies.find((item) => item.role === 20 && item.active) &&
                                    <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                        <CommissionCell text={policies.find((item) => item.role === 20 && item.active)?.description ?? "Comissão adm"} />
                                        <CommissionCell text={`${round((policies.find((item) => item.role === 20 && item.active)?.percentage as number)) * 100}%`} />
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </>
                }
                <Typography variant="h4" textAlign={"center"} fontWeight={"500"}>
                    {user?.role === Roles.seller ?
                        "Tabela de comissionamento sobre as vendas das indicações" :
                        "Tabela de comissionamento sobre as vendas dos seus Revendedores"}
                </Typography>
                <Table>
                    <TableHead sx={{ bgcolor: "#316FE8" }}>
                        <TableRow>
                            <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>{user?.role === Roles.seller ? "Meus ganhos sobre as indicações" : "Comissão dos revendedores" }</TableCell>
                            <TableCell sx={{ color: 'white', maxWidth: 140, textAlign: 'center' }}>{user?.role === Roles.seller ? "Comissão a receber" : "Comissão" }</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(policies.find((item) => item.level === 0 && item.active) && user?.role === Roles.general) && <TableRow sx={{ bgcolor: '#316FE81A' }}>
                            <CommissionCell text={policies?.find((p) => p.level === 0 && p.role === Roles.seller)?.description ?? "Venda direta"} />
                            <CommissionCell text={`${round((policies?.find((p) => p.level === 0 && p.role === Roles.seller)?.percentage as number * 100)) ?? 30}%`} />
                        </TableRow>}
                        {policies.find((item) => item.level === 1 && item.active) && user?.role === Roles.seller &&
                            <TableRow sx={{ bgcolor: '#316FE81A' }}>
                                <CommissionCell text={policies?.find((p) => p.level === 1 && p.role === Roles.seller)?.description ?? "Diretas"} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 1 && p.role === Roles.seller)?.percentage as number * 100)) ?? 5}%`} />
                            </TableRow>
                        }
                        {policies.find((item) => item.level === 1 && item.active) && user?.role === Roles.general &&
                            <TableRow sx={{ bgcolor: '#316FE81A' }}>
                                <CommissionCell text={policies?.find((p) => p.level === 1 && p.role === Roles.seller)?.description ?? "Indicação direta"} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 1 && p.role === Roles.seller)?.percentage as number * 100)) ?? 5}%`} />
                            </TableRow>
                        }
                        {policies.find((item) => item.level === 2 && item.active) &&
                            <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                <CommissionCell text={policies?.find((p) => p.level === 2 && p.role === Roles.seller)?.description ?? "Indiretas 1º Grau "} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 2 && p.role === Roles.seller)?.percentage as number * 100)) ?? 4}%`} />
                            </TableRow>
                        }
                        {policies.find((item) => item.level === 3 && item.active) &&
                            <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                <CommissionCell text={policies?.find((p) => p.level === 3 && p.role === Roles.seller)?.description ?? "Indiretas 2º Grau "} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 3 && p.role === Roles.seller)?.percentage as number * 100)) ?? 3}%`} />
                            </TableRow>
                        }
                        {policies.find((item) => item.level === 4 && item.active) &&
                            <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                <CommissionCell text={policies?.find((p) => p.level === 4 && p.role === Roles.seller)?.description ?? "Indiretas 3º Grau "} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 4 && p.role === Roles.seller)?.percentage as number * 100)) ?? 2}%`} />
                            </TableRow>
                        }
                        {policies.find((item) => item.level === 5 && item.active) &&
                            <TableRow sx={{ bgcolor: '#316FE826' }}  >
                                <CommissionCell text={policies?.find((p) => p.level === 5 && p.role === Roles.seller)?.description ?? "Indiretas 4º Grau "} />
                                <CommissionCell text={`${round((policies?.find((p) => p.level === 5 && p.role === Roles.seller)?.percentage as number * 100)) ?? 1}%`} />
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export default CommissionsTable;