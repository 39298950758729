import { Button, Grid } from "@mui/material";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { ReactNode, useRef } from "react";

export function QRCodeComponent({
    link,
    title,
    imageName,
    midComponent
}: {
    title?: string,
    link: string,
    imageName?: string,
    midComponent?: ReactNode
}) {
    const printRef = useRef(null);

    return (
        <Grid mt={2} container alignItems={"center"} justifyContent={"center"} gap={2}>
            <div ref={printRef} style={{ padding: 10 }}>
                <QRCode
                    size={200}
                    style={{ height: "auto", maxWidth: 200, width: "100%" }}
                    value={link}
                    viewBox={`0 0 256 256`} />
            </div>

            {midComponent && midComponent}

            <Button
                variant="contained"
                fullWidth
                onClick={async () => {
                    const element = printRef.current;
                    if (!printRef) {
                        return
                    }
                    if (link && element) {
                        const canvas = await html2canvas(element);

                        const data = canvas.toDataURL('image/png');
                        const currentlink = document.createElement('a');

                        if (typeof currentlink.download === 'string') {
                            currentlink.href = data;
                            currentlink.download = `${imageName ?? "Link de vendas"}.png`;
                            document.body.appendChild(currentlink);
                            currentlink.click();
                            document.body.removeChild(currentlink);
                        } else {
                            window.open(data);
                        }

                    }
                }}
            >{title ?? "Baixar QRCODE"}</Button>
        </Grid>
    )
}