import { Button, Divider, Grid, Typography, useTheme } from "@mui/material"
import useAuth from "../../../context/auth";
import { MdFullscreen } from "react-icons/md";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RoutesPath from "../../../utils/routesBases";
import TableDetailsWithdraw from "./details";
import { useEffect, useState } from "react";
import { BetService } from "../../../service/bets";
import { constants, Roles } from "../../../utils";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { BiMoneyWithdraw } from "react-icons/bi";

const CreditsSellerPage = () => {

    const navigate = useNavigate();

    const { palette } = useTheme();
    const { user } = useAuth();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const querySimulator = queryParams.get('movements');

    const [showDetails, setShowDetails] = useState(false);
    const [historic, setHistoric] = useState<{
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA
    }[] | null
    >(null)

    useEffect(() => {
        BetService.creditsHistoric()
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
            })
    }, [])

    useEffect(() => {
        if (querySimulator) {
            setShowDetails(true)
        }
    }, [querySimulator])

    return (
        <Grid container alignItems="center" flexDirection={"column"} p={2} gap={2}>
            <Typography variant="h2" textAlign={"center"} fontWeight={"bold"} textTransform={'uppercase'}>
                MEU SALDO
            </Typography>
            <Grid container alignItems="center" flexDirection={"column"}>
                <Typography
                    variant="h2"
                    textAlign={"center"}
                    fontWeight={"bold"}
                    textTransform={'uppercase'}
                    color={(user?.credits && user?.credits < 0) ? palette.error.main : palette.primary.main}
                >
                    {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                </Typography>
                <Typography fontWeight="500" alignItems='center'>Saldo</Typography>
            </Grid>
            {/* {user?.role === Roles.seller &&
                <Grid container justifyContent={'space-between'} alignItems="center">
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        fullWidth
                        sx={{
                            animation: 'pulseWithoutBoxShadow 2s infinite'
                        }}
                        onClick={() => navigate(RoutesPath.Revendedor.deposito.path)}
                        startIcon={<div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}>
                            <Typography fontSize={20}>+</Typography>
                            <RiMoneyDollarCircleLine style={{
                                marginLeft: 2
                            }} />
                        </div>}
                    >
                        ADICIONAR SALDO PARA APOSTAS
                    </Button>
                </Grid>
            } */}
            <Grid container justifyContent={'space-between'} alignItems="center">
                <Button
                    variant="contained"
                    size="large"
                    disabled={user?.credits ? user?.credits < constants.minPixTransfer : true}
                    fullWidth
                    onClick={() => navigate(RoutesPath.Revendedor.saque.path)}
                    startIcon={<BiMoneyWithdraw />}
                >
                    SOLICITAR SAQUE
                </Button>
            </Grid>
            {
                (user?.credits ?? 0) > 0 && (user?.credits ?? 0) <= constants.minPixTransfer ?
                    <Typography component="i" color="GrayText" textAlign="center" fontSize={'.8rem'}>
                        Saque disponível apenas para valores acima de <br />R$ 7,50.
                        (Conforme o <Typography onClick={() => {
                            navigate("/regulamento", {
                                state: {
                                    prev_path: window.location.pathname
                                }
                            })
                        }} component="a" href="/regulamento" color="inherit" fontSize={'.8rem'}>regulamento</Typography>)
                    </Typography>

                    : <></>
            }
            <Button fullWidth color="inherit" variant="outlined" sx={{ p: 1, textTransform: 'none' }} onClick={() => setShowDetails(true)}>
                <MdFullscreen color="black" size={25} />
                <Typography fontSize={16} fontWeight="500" mt={.4}>Abrir detalhes do meu saldo</Typography>
            </Button>
            <Divider />
            {/* <Typography variant="h2" textAlign={"center"} fontWeight={"bold"} textTransform={'uppercase'}>
                VENDAS E COMiSSÕES
            </Typography>
            <Grid container alignItems="center" flexDirection={"column"}>
                <Typography variant="h1" textAlign={"center"} fontWeight={"bold"} textTransform={'uppercase'}>
                    R$ 000.000,00
                </Typography>
                <Typography variant="h4" fontWeight="500" alignItems='center'>Total de comissões geradas</Typography>
            </Grid> */}
            <Grid width="100%" mt={4}>
                <Button color="inherit" variant="outlined" sx={{ p: 1, fontSize: '1rem', maxWidth: 150 }} fullWidth onClick={() => navigate(RoutesPath.Revendedor.path)}>
                    Voltar
                </Button>
            </Grid>
            <TableDetailsWithdraw
                open={showDetails}
                handleClose={() => {
                    setShowDetails(false)
                    queryParams.delete("movements")
                    navigate(`${location.pathname}?${queryParams.toString()}`);
                }}
                historic={historic}
            />
        </Grid>
    )
}

export default CreditsSellerPage;