import { Navigate, Outlet, RouteObject, useLocation } from "react-router-dom";
import RoutesPath from "../utils/routesBases";
import AuthLayout from "../layout/auth";
import useAuth from "../context/auth";
import BetLayout from "../layout/bet";
import { Box, Container, Grid } from "@mui/material";

const ClientRouters = (): RouteObject[] => {
    const { tokenClient } = useAuth();
    const { search } = useLocation()
    if (!tokenClient) {
        return [
            {
                element: <AuthLayout />,
                path: RoutesPath.Clients.path,
                children: [
                    {
                        element: <BetLayout />,
                        path: RoutesPath.Clients.path,
                    },
                    {
                        element: RoutesPath.Clients.login.element,
                        path: RoutesPath.Clients.login.path,
                    },
                    {
                        element: <Navigate to={RoutesPath.Clients.path + search} />,
                        path: RoutesPath.Clients.pathRedirect,
                    },
                    {
                        element: <Navigate to={RoutesPath.Clients.path + search} />,
                        path: RoutesPath.Clients.pathRedirectacesso,
                    },
                    {
                        element: <Navigate to={RoutesPath.Clients.path + search} />,
                        path: RoutesPath.Clients.path,
                    },
                ]
            },
        ]
    }
    return [
        {
            element: <CenterLayout/>,
            path: "/cliente",
            children: [
                {
                    element: <BetLayout />,
                    path: RoutesPath.Clients.path,
                },
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: RoutesPath.Clients.login.path,
                },
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: "/cliente/*",
                },
                {
                    element: <Navigate to={RoutesPath.Clients.path} />,
                    path: RoutesPath.Clients.pathRedirect,
                },
                {
                    element: RoutesPath.Clients.element,
                    path: RoutesPath.Clients.path,
                },
                {
                    element: RoutesPath.Clients.bilhetes.element,
                    path: RoutesPath.Clients.bilhetes.path + '/:id',
                },
                {
                    element: RoutesPath.Clients.saque.element,
                    path: RoutesPath.Clients.saque.path,
                },
                {
                    element: RoutesPath.Clients.deposito.element,
                    path: RoutesPath.Clients.deposito.path
                }
            ]
        }
    ]
}

export default ClientRouters;


const CenterLayout = () => {
    return (
        <Container maxWidth="md">
            <Box width={"100%"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <Outlet />
            </Box>
        </Container>
    )
}