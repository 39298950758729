import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { GoAlertFill } from "react-icons/go";

export const ModalFraude = ({ open, onClose }: { open: boolean, onClose: () => void }) => {

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                <Grid sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <GoAlertFill color="orange" size={30} />
                    <Typography variant="h2" color={"red"}>ATENÇÃO CUIDADO COM GOLPES</Typography>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Typography fontSize={16}>🚫 <strong>Não</strong> solicitamos PIX para ganhadores</Typography>
                    <Typography fontSize={16}>🚫 <strong>Não</strong> fazemos ligações para clientes</Typography>
                    <Typography fontSize={16}>🚫 <strong>Não</strong> solicitamos vídeos de clientes</Typography>
                    <Typography fontSize={16}>🚫 <strong>Não</strong> solicitamos fotos de clientes</Typography>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    )
}