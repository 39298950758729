import { Typography, TypographyProps, useTheme } from "@mui/material"
import React from "react";

interface TextMarkedProps extends TypographyProps {
    color?: "primary" | "secondary" | "success" | "error",
    children: React.ReactNode;
}

const TextMarked = (props: TextMarkedProps) => {

    const { color, children, ...rest } = props;

    const { palette } = useTheme();

    const mainColor = color ? palette[color] : palette.primary

    return(
        <Typography {...rest} component={"strong"} fontWeight={"bold"} color={mainColor.main}>
            {children}
        </Typography>
    )
}

export default TextMarked;