import { Button, CircularProgress, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { PredictionInput } from "../../components/prediction/input";
import useBet, { NumbersGroups, PredictionPros, PredictionsProps } from "../../context/bet";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { BetService } from "../../service/bets";
import { sendToShow } from "../../utils";

import { LoadingButton } from "@mui/lab";

import { NumbersOptions } from "./numbersOptions";
import { FaCheck } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import RoutesPath from "../../utils/routesBases";
import { LuCheckCircle } from "react-icons/lu";
import useExpress from "context/express";
import { formatCurrency } from "utils/mask";
import { Sounds } from "components/games/audios/intex";

const FavoriteNumber = () => {

    const {
        favoriteNumber,
        setFavoriteNumber,
        predictions,
        setPrediction,
        setLuckNumbers,
        luckyNumbers,
        selectDraw,
        GetPredicationsNumber,
        purchaseFlow,
        order,
        setOrder
    } = useBet();

    const navigate = useNavigate()
    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor
    const [canContinue, setCanContinue] = useState(false);
    const maxQuantity = 10
    const minQuantity = 3
    const [quantity, setQuantity] = useState(minQuantity)
    const [showButtonGenerateNumbers, setShowButtonGenerateNumbers] = useState<number>(null)
    const [loadingNumbers, setLoadingNumbers] = useState<number[]>([])

    const { guessPrice } = useExpress()
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);

    const useRow = useMediaQuery("(max-width:350px)")

    const handlePredictionChange = (key: string, prediction: PredictionPros[]) => {
        setPrediction({ ...predictions, [key]: prediction });
    };

    const [loadingRandomNumbers, setLoadingRandomNumbers] = useState(false)

    const [activeInput, setActiveInput] = useState<null | number>(null)


    useEffect(() => {
        let value = true
        if (predictions) {
            const keys = Object.keys(predictions)
            let count = 0
            for (let key of keys) {
                if (predictions[key]?.length === 4) {
                    count += 1
                }
            }

            if (count >= minQuantity) {
                value = false
            }
        }
        setCanContinue(!value)
    }, [predictions])


    useEffect(() => {
        if (predictions) {
            const keys = Object.keys(predictions);
            const completedPredictions = keys.filter(key => predictions[key].length === 4);
            if (completedPredictions.length >= quantity && quantity < maxQuantity) {
                setQuantity(prevQuantity => prevQuantity + 1);
                setTimeout(() => {
                    inputsRef?.current[completedPredictions.length * 4]?.focus();
                }, 10);
            }
        }
        if (quantity > maxQuantity) {
            setQuantity(maxQuantity)
            let _predictions = predictions
            const numbersAsArray = Object.keys(_predictions);

            for (let number of numbersAsArray) {
                const asNumber = Number(number.replace(/\D/g, ''));
                if (asNumber > maxQuantity) {
                    delete _predictions[number];
                }
            }
            setPrediction(_predictions)
        }
    }, [predictions, quantity]);

    const { palette } = useTheme();

    useEffect(() => {
        sendToShow("favorite_predictions", 'start')
        setTimeout(() => {
            if (!inputsRef?.current[0]?.value) {
                inputsRef?.current[0]?.focus()
            }
        }, 100)
    }, [favoriteNumber])

    const generateNumber = async (index: number): Promise<any> => {
        setLoadingNumbers(prev => [...prev, index])

        handlePredictionChange(`predication${index + 1}`, getRandomNumber().map((item, i) => ({
            id: i,
            number: Number(item)
        })))

        setLoadingNumbers(prev => prev.filter((item) => item !== index))
        setTimeout(() => {
            inputsRef?.current[(index + 1) * 4]?.focus();
        }, 10);
    }

    const getRandomNumber = () => {
        const numbers: string[] = []

        for (let i = 0; i <= 3; i++) {
            let value = Math.floor(Math.random() * 10)
            numbers.push(value.toString())
        }

        if (GetPredicationsNumber().includes(numbers.join(''))) {
            return getRandomNumber()
        }

        return numbers
    }

    const getGuessTotalValue = (qtd: boolean = false): number => {
        let value = 0
        if (predictions) {
            const keys = Object.keys(predictions)
            for (let key of keys) {
                if (predictions[key]?.length === 4) {
                    value += 1
                }

            }
        }
        return qtd ? value : value * guessPrice
    }


    const renderGuessValue = (): string => {
        let qtd = getGuessTotalValue(true)
        if (qtd === 0) {
            return ""
        } else if (qtd === 1) {
            return `1 Aposta: ${formatCurrency(getGuessTotalValue())}`
        } else {
            return `${getGuessTotalValue(true)} Apostas: ${formatCurrency(getGuessTotalValue())}`
        }

    }

    const focusNext = () => {
        const incompleteList = Object.entries(predictions ?? {}).filter(([key, array]) => (array ?? []).length < 4)
        const incomplete = incompleteList.sort((a, b) => Number(a[0].split('predication')[1]) - Number(b[0].split('predication')[1]))[0]
        if (incomplete) {
            let predication_index = Number((incomplete[0] as unknown as string).split("predication")[1])
            let index: number | null = null
            for (let i = 0; i < 4; i++) {
                if (!(incomplete[1] ?? []).find((item) => item.id === i) && index === null) {
                    index = i
                }
            }
            const refPosition = ((index as number) + (predication_index - 1) * 4)

            inputsRef.current[refPosition]?.focus()
        }
    }

    const renderNamePrize = (name: string, color: string, isFavorite: boolean = false) => {
        if (isFavorite) {
            return (
                <p style={{
                    display: 'inline',
                    textDecoration: 'underline',
                    textDecorationColor: '#bab7b6',
                    margin: 0,
                    fontSize: 10
                }}>
                    <span style={{
                        fontWeight: '700',
                        color: color,
                    }}>
                        {name}
                    </span>
                </p>
            )
        }
        return (
            <p style={{
                display: 'inline',
                fontWeight: '700',
                color: color,
                margin: 0,
                fontSize: 10
            }}>{name}</p>
        )
    }

    const handleNext = () => {
        let _quantity = GetPredicationsNumber().filter((guess) => guess.length === 4).length
        setOrder({ group: _quantity, value: _quantity * guessPrice })
        navigate(RouterPath.bet.order2.path)
    }

    return (
        <Grid container gap={2} id="favorite_number" position={"relative"}>
            <Grid container gap={2} minWidth={300}>
                <Typography width="100%" variant="h4" textAlign='center' fontWeight="400" padding={"0px 10px"} mt={-1}>
                    Quanto mais você <strong>apostar</strong>, maiores são suas chances de <strong>ganhar</strong>!
                    <br />
                    <br />
                    Preencha de <strong>{minQuantity}</strong> a <strong>{maxQuantity}</strong> apostas
                </Typography>

                <Typography mt={-2} width="100%" fontSize={"10px"} fontWeight="400" textAlign='center' padding={"0px 10px"}>
                    com os números de sua preferência
                    <br />
                    ou clique <LuCheckCircle /> para preencher automaticamente sua(s) aposta(s)
                </Typography>

                <Typography width="100%" variant="h4" textAlign='center' fontWeight="400" padding={"0px 10px"} mt={-2}>
                    {renderNamePrize("QUADRA", 'rgba(0, 82, 0, .9)')} | {renderNamePrize("TERNO", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("DUQUE", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("UNIDADE", palette.primary.main, true)}
                </Typography>
                {
                    Array(quantity).fill(null).map((_, predication_index) => {
                        const beforeKey = `predication${predication_index}`
                        let disabledNext = false
                        // if (predication_index > 0) {
                        //     if (((predictions ?? {})[beforeKey] ?? [])?.length < 4) {
                        //         disabledNext = true
                        //     }
                        // }
                        return (
                            <Grid container display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"flex-start"} gap={1}>
                                <Grid id={`input-${predication_index}`} key={predication_index} container justifyContent={"space-around"} alignItems="center" direction={useRow ? "column" : "row"} gap={useRow ? 2 : 0}>
                                    <div>
                                        <Grid container borderRadius={2} p={.7} overflow="hidden" >
                                            {
                                                Array(4).fill(null).map((_, index) => {
                                                    const key = `predication${predication_index + 1}`
                                                    const ITEM_ID = (index - predication_index) > 0 ? ((index)) : index
                                                    const predicationItem = predictions ? predictions[key] : null
                                                    const valueItem = predicationItem ? predicationItem.find((_item) => _item.id === ITEM_ID) : null

                                                    const refPosition = ((index) + (predication_index) * 4)

                                                    if (activeInput === refPosition && index === 0) {
                                                        sendToShow(`input-${predication_index}`, 'center')
                                                    }

                                                    return (
                                                        <Grid
                                                            key={index}
                                                            width={60}
                                                            height={60}
                                                            container
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            border={`1px solid GrayText`}
                                                            borderRadius={4}
                                                            sx={{
                                                                // ...(disabledNext && {
                                                                //     backgroundColor: '#edebeb'
                                                                // })
                                                            }}
                                                        >
                                                            <PredictionInput
                                                                index={index}
                                                                isFocus={activeInput === refPosition}
                                                                isFavorite={index === 3}
                                                                disable={loadingRandomNumbers || disabledNext}
                                                                inputRef={(el) => inputsRef.current[refPosition] = el}
                                                                value={valueItem?.number ?? undefined}
                                                                onDelete={() => {
                                                                    handlePredictionChange(key, (predicationItem ?? []).filter((item) => item.id !== index))
                                                                    if (refPosition > 0) {
                                                                        inputsRef.current[refPosition - 1]?.focus()
                                                                    }
                                                                }}
                                                                onKeyDown={(keyCode) => {
                                                                    Sounds.playClick()
                                                                    let value = Number(keyCode)
                                                                    if (!isNaN(value)) {
                                                                        if (index === 3 && predictions) {
                                                                            let newPredictions: PredictionPros[] = [
                                                                                ...predicationItem ?? [],
                                                                                {
                                                                                    id: index,
                                                                                    number: value
                                                                                }
                                                                            ]
                                                                            let exist = false

                                                                            for (let _pred of Object.keys(predictions ?? {})) {
                                                                                let pred = predictions[_pred]
                                                                                let isEqual = 0
                                                                                if (pred) {
                                                                                    for (let obj of pred) {
                                                                                        let find = newPredictions.find((item) => item.id === obj.id && item.number === obj.number)
                                                                                        if (find) isEqual += 1
                                                                                    }
                                                                                }
                                                                                if (isEqual === 4 && _pred !== key) {
                                                                                    exist = true
                                                                                }
                                                                            }

                                                                            if (exist) {
                                                                                toast("A aposta não pode se repetir", {
                                                                                    type: "warning"
                                                                                })
                                                                                return
                                                                            }
                                                                        }
                                                                        if (valueItem) {
                                                                            if (predicationItem) {
                                                                                let newPredictions: PredictionPros[] = [
                                                                                    ...predicationItem.filter((item) => item.id !== index),
                                                                                    {
                                                                                        id: index,
                                                                                        number: value
                                                                                    }
                                                                                ]
                                                                                handlePredictionChange(key, newPredictions)
                                                                            }
                                                                        } else {
                                                                            if (predicationItem) {
                                                                                let newPredictions: PredictionPros[] = [
                                                                                    ...predicationItem,
                                                                                    {
                                                                                        id: index,
                                                                                        number: value
                                                                                    }
                                                                                ]
                                                                                handlePredictionChange(key, newPredictions)
                                                                            } else {
                                                                                let newPredictions: PredictionPros[] = [
                                                                                    {
                                                                                        id: index,
                                                                                        number: value
                                                                                    }
                                                                                ]
                                                                                handlePredictionChange(key, newPredictions)
                                                                            }
                                                                        }
                                                                        if (refPosition !== ((quantity * 4) - 1)) {
                                                                            inputsRef.current[refPosition + 1]?.focus()
                                                                        } else {
                                                                            if (Object.keys(predictions ?? {}).length === maxQuantity) {
                                                                                inputsRef.current[refPosition]?.blur()
                                                                                sendToShow('generate_luck_numbers', "end")
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                onChange={(value) => {
                                                                }}
                                                                onFocusIn={() => {
                                                                    setActiveInput(refPosition)
                                                                    setShowButtonGenerateNumbers(Math.trunc(refPosition / 4))
                                                                }}
                                                                onFocusOut={() => setActiveInput(null)}
                                                            />
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </div>
                                    <Grid display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                        <Typography
                                            variant="h4"
                                            fontWeight="bold">
                                            Aposta {predication_index + 1}
                                        </Typography>
                                        <FaCheck color={palette.success.main} style={{
                                            opacity: (predictions && predictions[`predication${predication_index + 1}`]?.length === 4) ? 1 : 0
                                        }} />
                                    </Grid>
                                </Grid>
                                {(((showButtonGenerateNumbers === predication_index) || loadingNumbers.includes(predication_index))) && <LoadingButton
                                    onClick={() => generateNumber(predication_index)}
                                    loading={loadingNumbers.includes(predication_index)}
                                    disabled={loadingNumbers.includes(predication_index)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: 'transparent',
                                        backdropFilter: 'blur(3px)',
                                        fontWeight: 'bold',
                                        width: '200px',
                                        color: "GrayText",
                                        borderColor: "GrayText",
                                    }}
                                    startIcon={<LuCheckCircle size={14} />}
                                >
                                    <Typography fontSize={'8px'}>Se preferir, clique para preencher a aposta {predication_index + 1} automaticamente</Typography>
                                </LoadingButton>}
                            </Grid>

                        )
                    })
                }
                {canContinue && luckyNumbers === null &&
                    <Grid item xs={12} display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2}>
                        <Button variant="outlined" onClick={() => {
                            setFavoriteNumber(null)
                            setPrediction(null)
                            setLuckNumbers(null)
                            sendToShow("favorite_number", 'start')
                            setQuantity(minQuantity)
                            inputsRef.current[0]?.focus()
                        }}
                            sx={{
                                fontSize: 12,
                                color: "#c7c3c3",
                                borderColor: "#c7c3c3",
                            }}
                        >Apagar apostas</Button>

                        <Typography id={`total-value`} color={palette.grey[600]} variant="h6" textAlign='center' fontWeight="400" sx={{ scrollMarginTop: 150 }}>
                            {renderGuessValue()}
                            <br />
                        </Typography>
                    </Grid>
                }

                {
                    canContinue && luckyNumbers === null &&
                    <Button
                        variant="contained"
                        size="large"
                        color="secondary"
                        id="generate_luck_numbers"
                        sx={{ mt: 2, padding: '20px 0px', animation: 'pulseWithoutBoxShadow 2s infinite' }}
                        fullWidth
                        disabled={!canContinue}
                        onClick={handleNext}
                    >
                        <Grid>
                            <Typography
                                color={palette.common.white}
                                variant="h3"
                                textTransform={"none"}>
                                Clique para
                            </Typography>
                            <Typography
                                color={palette.common.white}
                                textTransform="uppercase"
                                sx={{ textDecoration: 'underline' }}
                                variant="h3"
                                fontWeight="bold">
                                Continuar
                            </Typography>
                        </Grid>
                    </Button>
                }
            </Grid>

            {
                luckyNumbers &&
                <NumbersOptions />
            }
            {/* <ShowNumbersLuck
                open={!!showModalNumbersLuck}
                value={showModalNumbersLuck}
                handleClose={() => setShowModalNumbersLuck(null)}
            /> */}
            <Grid height={100} />
        </Grid >
    )
}

export default FavoriteNumber;