import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"

export const InstallModal = ({
    handleClose,
    open
}: {
    open: boolean,
    handleClose: () => void
}) => {
    return (
        <Dialog
            open={open}
            fullScreen
            onClose={handleClose}
        >
            <DialogContent
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <video
                    controls
                    controlsList="nodownload"
                    preload="auto"
                    playsInline
                    style={{
                        maxWidth: '100%'
                    }}>
                    <source
                        src={"https://s3.us-east-1.amazonaws.com/assets.numerofavorito.com/videos+lp/COMO+INSTALAR+APP.mp4"}
                        type="video/mp4"
                    />
                </video>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}