import { Box, Button, Container, Grid } from "@mui/material"
import SVGLOGO from "../assets/imgs/logo 01.png"
import { Outlet, useLocation, useNavigate } from "react-router-dom"

const AuthLayout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const isLogin = pathname.includes("login")

    return (
        <Container sx={{
            ... (!isLogin && {
                p: 0
            })
        }} maxWidth={isLogin ? "xs" : "md"}>
            {
                isLogin ?
                    <Grid height='calc(100vh - 1rem)' p={2} justifyContent='center' container alignContent='flex-start' >
                        <Box>
                            <Grid container flexDirection='column' alignItems={'center'} onClick={() => navigate("/</Box>")}>
                                <img src={SVGLOGO} width={100} />
                            </Grid>
                        </Box>
                        <Box width={'100%'}>
                            <Grid width={"100%"}>
                                <Outlet />
                            </Grid>
                        </Box>
                    </Grid>
                    :
                    <Outlet />
            }
        </Container>
    )
}
export default AuthLayout;