import { Button, Chip, Container, Grid, Typography, useTheme, useMediaQuery, Tabs, Tab, MenuItem, IconButton } from "@mui/material"

import SVGLOGO from "assets/imgs/logo 01.png"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useBet from "context/bet";
import { formatCurrency, NameMask, phoneMask } from "utils/mask";
import { Fragment, useEffect, useMemo, useState } from "react";

import { ClientMenu } from "./saller/menu";
import RoutesPath from "../utils/routesBases";
import useQuery from "../hooks/useQuery";
import useAuth from "../context/auth";
import { constants, suportNumber } from "../utils";
import { FaWhatsapp } from "react-icons/fa6";
import { MdLiveTv } from "react-icons/md";
import { GameTraditional } from "../components/games/traditional";
import { GameExpress } from "../components/games/express";
import { SlideIn } from "../components/slide";
import { AnimatePresence } from "framer-motion";

import ImgTraditionalSelected from "../assets/imgs/express/SORTEIO AO VIVO SELECIONADO.png"
import ImgTraditionalNotSelected from "../assets/imgs/express/SORTEIO AO VIVO NÃO SELECIONADO.png"

import ImgExpressSelected from "../assets/imgs/express/SORTEIO RÁPIDO SELECIONADO.png"
import ImgExpressNotSelected from "../assets/imgs/express/SORTEIO RÁPIDO NÃO SELECIONADO.png"
import useExpress from "../context/express";

import { BiMoneyWithdraw } from "react-icons/bi";
import { BsCoin } from "react-icons/bs";
import moment from "moment";
import { BetService } from "../service/bets";
import { MenuText } from "../components/menu";
import { prizeDrawsProps, PrizesProps } from "../types";
import { PrizeDrawsService } from "../service/prizes";
import TableResults from "../pages/correspondent/myPrizeDraws/results";
import TablePrizes from "../pages/client/prizes";
import { GoEye } from "react-icons/go";

import TableDetailsWithdrawView from "../pages/client/creditsDetails";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { ImExit } from "react-icons/im";
import { AuthService } from "../service/auth";
import { GrLogin } from "react-icons/gr";


import { MenuNav, ToolTipClick } from "./menus";
import { FaHandPointDown } from "react-icons/fa";
import { RequestSeller } from "pages/bet/order/requestSeller";
import { InstallModal } from "components/games/videos/installApp";
import CardImg from "../assets/imgs/express/694c40bc-fe94-4f9d-b46c-c4e83dd89010.png"
import { ReportUniqueClient } from "pages/client/reportInique";
import { ReportUniqueSeller } from "pages/correspondent/reportInique";
import { ModalWinnerClient } from "pages/client/modalWinner";
import { ModalWinnerSeller } from "pages/correspondent/modalWinner";


const BetLayout = () => {

    const navigate = useNavigate();
    const { palette } = useTheme();
    const { user, userCredits, tokenClient } = useAuth();

    const [openDetails, setOpenDetails] = useState(false);

    const { gameTab, setGameTab } = useExpress()

    const breackpoint = !useMediaQuery("(min-width:331px)")

    const { pathname, search, } = useLocation();
    const isGame = pathname === "/cliente/concurso/" || pathname === "/cliente/" || pathname === "/cliente/concurso" || pathname === "/cliente" || pathname === "/apostar" || pathname === "/apostar/"

    const query = useQuery();

    const [menu, setMenu] = useState(false);


    const { seller, purchaseFlow } = useBet();

    const verifyHOME = seller?.id === 708 || seller?.id === 755

    const NameSaleer = NameMask((verifyHOME ? "" : seller?.name) ?? "")

    const _sallerquery = query.get('s')
    const clientIndicate = query.get('c')

    const [animationComplete, setAnimationComplete] = useState(false);

    const [anchorElTickets, setAnchorElTickets] = useState(false);
    const [anchorElResults, setAnchorElResults] = useState(false);
    const [anchorElRegulation, setAnchorElRegulation] = useState(false);

    const [drawParticipation, setDrawParticipation] = useState<null | {
        id: number;
        prizeDrawAt: string
    }[]>(null);

    const [prizeDrawsResult, setPrizeDrawsResult] = useState<null | prizeDrawsProps>(null);
    const [prizeDraws, setPrizeDraws] = useState<null | prizeDrawsProps[]>(null);

    const [showPrizes, setShowPrize] = useState<boolean>(false);
    const [prizes, setPrizes] = useState<PrizesProps[] | null>(null);

    const [loadingGuessCredit, setLoadingGuessCredit] = useState(false)

    const queryParams = new URLSearchParams(search);

    const querySimulator = queryParams.get('movements');

    const [tooltipWithsrawIsVisible, setToolTipWithdrawIsVisible] = useState(false)

    const [historic, setHistoric] = useState<{
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA,
        betUUID: string | null,
        clientCpf: string | null,
        clientName: string | null,
        eventNumber: number
    }[] | null
    >(null)

    const [openModalSeller, setOpenModalSeller] = useState(false)

    const [openModalInstallApp, setOpenModalInstallApp] = useState(false)

    const [showBanner, setShowBanner] = useState(true)

    useEffect(() => {
        if (gameTab === 2) setAnimationComplete(false)
    }, [gameTab])

    useEffect(() => {
        if (tokenClient) {
            GetDrawParticipation();
            getPrizes();
            getPrizeDrawsResults();
            getAllMovements()
        }
        if (pathname.includes("apostar")) {
            setOpenModalSeller(true)
        }
        if (pathname.includes("concurso/order") || pathname.includes("concurso/recibo")) {
            setShowBanner(false)
        } else {
            setShowBanner(true)
        }
    }, [pathname])

    useEffect(() => {
        if (tooltipWithsrawIsVisible) {
            setTimeout(() => {
                setToolTipWithdrawIsVisible(false)
            }, 2000);
        }
    }, [tooltipWithsrawIsVisible])


    const handleCloseTickets = () => {
        setAnchorElTickets(false)
    }

    const handleCloseRegulation = () => {
        setAnchorElRegulation(false)
    }

    const handleCloseResults = () => {
        setAnchorElResults(false)
    }

    const GetDrawParticipation = () => {
        BetService.getDrawsParticipationClient()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setDrawParticipation(data.prizeDraws)
                }
            })
            .catch(() => { })
    }

    const getPrizeDrawsResults = () => {
        PrizeDrawsService.resultsclients()
            .then(({ data }) => {
                if (data?.results) {
                    setPrizeDraws(data.results)
                }
            })
    }

    const getPrizes = () => {
        PrizeDrawsService.prizes()
            .then(({ data }) => {
                if (data?.prizes) {
                    setPrizes(data.prizes)
                }
            })
    }

    const getAllMovements = (guess?: boolean) => {
        if (guess) {
            setLoadingGuessCredit(true)
        }
        BetService.creditsHistoric(guess)
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
                if (guess) {
                    setLoadingGuessCredit(false)
                    setOpenDetails(true)
                }
            })
    }

    const memoizedGameTraditional = useMemo(() => <GameTraditional />, []);
    const memoizedGameExpress = useMemo(() => <GameExpress />, []);


    return (
        <Container sx={{ p: 0 }} maxWidth="sm">
            <Grid width={"100%"}>
                <Grid p={2} container justifyContent={'space-between'}>
                    <Grid width={"100%"} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                        <Link to={"/quemsomos" + search}>
                            <img src={SVGLOGO} width={80} height={40} />
                        </Link>
                        <Grid container gap={1} flexDirection={'row'} alignItems={"flex-start"} justifyContent={"flex-end"}>
                            <Typography fontSize={10} textAlign={'end'} color="GrayText">
                                {verifyHOME ? "" : "Revendedor: "}{NameSaleer.firstName} {NameSaleer.lastName}
                            </Typography>
                            {
                                verifyHOME ? <></> :
                                    <Button
                                        component="a"
                                        target="_blank"
                                        href={"https://api.whatsapp.com/send/?phone=" + '55' + ((seller?.phone ?? "00000000000"))}
                                        color="inherit"
                                        sx={{ mt: -.2, ml: breackpoint ? -1 : 0, textDecoration: 'underline', fontSize: 10, color: 'GrayText', padding: '0px' }}>
                                        {phoneMask((seller?.phone ?? "00000000000"))}
                                    </Button>
                            }

                        </Grid>
                    </Grid>
                </Grid>
                <Grid container width={"100%"} gap={1} sx={{
                    padding: "0px 20px"
                }}>
                    <MenuNav
                        anchorElResults={anchorElResults}
                        anchorElTickets={anchorElTickets}
                        drawParticipation={drawParticipation}
                        handleCloseResults={handleCloseResults}
                        handleCloseTickets={handleCloseTickets}
                        prizeDraws={prizeDraws}
                        setAnchorElResults={setAnchorElResults}
                        setAnchorElTickets={setAnchorElTickets}
                        setPrizeDrawsResult={(value) => {
                            navigate(pathname + search + `?client-prize=${value.prizeDrawId}`)
                        }}
                        setShowPrize={setShowPrize}
                        anchorElRegulation={anchorElRegulation}
                        handleCloseRegulation={handleCloseRegulation}
                        setAnchorElRegulation={setAnchorElRegulation}
                        handleOpenModalInstallApp={() => setOpenModalInstallApp(true)}
                        key={'menus-nav'}
                    />
                </Grid>

                <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"} mt={1} mb={1}>
                    <Grid container width={"95%"} display={"flex"} flexDirection={"row"} alignItems={"center"} flexWrap={"nowrap"} justifyContent={!tokenClient ? "center" : "space-between"} gap={1} sx={{
                        ... (tokenClient && {
                            backgroundColor: '#efedf9',
                        }),
                        borderRadius: '5px',
                        padding: '7px',
                        ... ((!tokenClient && !pathname.includes("concurso/order")) && {
                            minHeight: '70px',
                        })
                    }}>
                        {(!tokenClient && !pathname.includes("concurso/order")) &&
                            <Button
                                startIcon={<GrLogin />}
                                variant="contained"
                                color="secondary"
                                sx={{
                                    animation: 'pulseWithoutBoxShadow 1s infinite',
                                    fontWeight: 'bold'
                                }}
                                onClick={() => navigate(RoutesPath.Clients.login.path)}
                            >Entrar</Button>
                        }
                        {tokenClient && <Grid display={"flex"} flexDirection={"column"}>
                            <Grid display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} gap={1}>
                                <Typography fontSize={13}>Cliente: {NameMask(user?.name)?.firstName ?? ""}</Typography>
                                <Button
                                    startIcon={<ImExit size={13} style={{ marginRight: -8 }} />}
                                    variant="text"
                                    sx={{ p: 0, fontSize: 13, color: 'gray' }}
                                    onClick={AuthService.logout}
                                >Sair</Button>
                            </Grid>

                            <Typography fontSize={13} fontWeight={"bold"}>Saldo Total para Apostar: {formatCurrency(userCredits?.totalCredits ?? 0)}</Typography>
                            <Typography fontSize={13} >
                                <IconButton style={{ padding: 0 }} onClick={() => getAllMovements(true)}>
                                    <GoEye fontSize={14} />
                                </IconButton> Créditos para Apostar: {formatCurrency(userCredits?.betsCredits ?? 0)}</Typography>
                            <Typography fontSize={13} sx={{
                                ... (userCredits?.credits < (constants.minPixTransfer) && {
                                    color: 'gray'
                                })
                            }}>
                                <IconButton data-tooltip-id="popup-withdraw" style={{ padding: 0 }} onClick={() => setOpenDetails(true)}>
                                    <GoEye fontSize={14} />
                                </IconButton> Valor para
                                <Link
                                    onClick={() => {
                                        setToolTipWithdrawIsVisible(true)
                                    }}
                                    data-tooltip-id="popup-withdraw"
                                    to={userCredits?.credits < (constants.minPixTransfer) ? "#" : RoutesPath.Clients.saque.path}
                                    style={{
                                        textDecoration: 'none',
                                        color: 'inherit'
                                    }}>
                                    <BiMoneyWithdraw size={14} color={(userCredits?.credits < constants.minPixTransfer) ? '#808080' : palette.primary.main} style={{ marginBottom: -3 }} />
                                    Saque: {formatCurrency(userCredits?.credits ?? 0)}
                                </Link>
                            </Typography>
                            <ToolTipClick
                                id="popup-withdraw"
                                label={`Saque disponível apenas para valores acima de ${formatCurrency(constants.minPixTransfer)}. (Conforme Regulamento)`}
                                open={tooltipWithsrawIsVisible}
                                disabled={!(userCredits?.credits < (constants.minPixTransfer))}
                            />
                        </Grid>}

                        {tokenClient && <Button sx={{
                            maxWidth: '30%',
                            padding: "4px",
                            fontSize: 10,
                            display: "flex",
                            flexDirection: "column",
                            fontWeight: 'bold',
                            boxShadow: '0px 0px 24px -2px rgba(0,0,0,0.75)',
                            animation: 'pulseWithoutBoxShadow 1s infinite'
                        }}
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate(RoutesPath.Clients.deposito.path)}
                        >
                            <RiMoneyDollarCircleLine size={24} />
                            COMPRAR CRÉDITOS PARA APOSTAR
                        </Button>}
                    </Grid>
                </Grid>

                {showBanner && <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <img src={CardImg} style={{
                        width: '95%'
                    }} />
                </Grid>}

                {isGame ?
                    <>
                        <Tabs
                            variant="fullWidth"
                            value={gameTab}
                            onChange={(e, newValue) => setGameTab(newValue)}
                            textColor="primary"
                            indicatorColor="primary"
                            aria-label="Modo de jogo"
                            centered
                            sx={{
                                mb: 2,
                                padding: '0px 10px',
                                height: '80px',
                                display: 'flex',
                                alignItems: 'flex-end'
                            }}
                            TabIndicatorProps={{
                                sx: {
                                    height: '0px'
                                }
                            }}
                        >
                            {[
                                {
                                    label: "Express", value: 2, image: {
                                        selected: ImgExpressSelected,
                                        notSelected: ImgExpressNotSelected
                                    }
                                },
                                {
                                    label: "Tradicional", value: 1, image: {
                                        selected: ImgTraditionalSelected,
                                        notSelected: ImgTraditionalNotSelected
                                    }
                                },
                            ].map((tab) => (
                                <Tab
                                    key={tab.value}
                                    value={tab.value}
                                    style={{
                                        // maxWidth: '50%'
                                    }}
                                    label={
                                        <Grid sx={{
                                            position: 'relative',
                                            height: "70px",
                                            width: "102%",
                                            ... (gameTab !== tab.value && {
                                                transform: 'scale(.9)'
                                            })
                                        }}>
                                            {(gameTab === tab.value && tab.value === 2) && <p
                                                style={{
                                                    position: 'absolute',
                                                    textDecoration: 'underline',
                                                    textTransform: 'none',
                                                    fontSize: '10px',
                                                    top: '0%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'left'
                                                }}>Você está aqui<FaHandPointDown size={10} style={{
                                                    marginBottom: -2,
                                                    marginLeft: 2
                                                }} /></p>}

                                            {(gameTab !== tab.value && tab.value === 2) && <p
                                                style={{
                                                    position: 'absolute',
                                                    textTransform: 'none',
                                                    fontSize: '10px',
                                                    top: '-2%',
                                                    left: '55%',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'left'
                                                }}>Sorteio<br /> na hora<FaHandPointDown size={10} style={{
                                                    marginBottom: -2,
                                                    marginLeft: 2
                                                }} /></p>}


                                            {(gameTab === tab.value && tab.value === 1) && <p
                                                style={{
                                                    position: 'absolute',
                                                    textDecoration: 'underline',
                                                    textTransform: 'none',
                                                    fontSize: '10px',
                                                    top: '0%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'left'
                                                }}>Você está aqui<FaHandPointDown size={10} style={{
                                                    marginBottom: -2,
                                                    marginLeft: 2
                                                }} /></p>}

                                            {(gameTab !== tab.value && tab.value === 1) && <p
                                                style={{
                                                    position: 'absolute',
                                                    textTransform: 'none',
                                                    fontSize: '10px',
                                                    top: '-2%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    textAlign: 'left'
                                                }}>Sorteio<br /> ao vivo<FaHandPointDown size={10} style={{
                                                    marginBottom: -2,
                                                    marginLeft: 2
                                                }} /></p>}


                                            <img
                                                src={gameTab === tab.value ? tab.image.selected : tab.image.notSelected}
                                                style={{
                                                    width: '100%',
                                                    height: '70%',
                                                    zIndex: 500,
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    ... (tab.value === 2 && {
                                                        borderTopRightRadius: '12px',
                                                        borderBottomRightRadius: '12px',
                                                    }),
                                                    ... (tab.value === 1 && {
                                                        borderTopLeftRadius: '12px',
                                                        borderBottomLeftRadius: '12px',
                                                    }),
                                                }}
                                            />
                                        </Grid>
                                    }
                                    sx={{
                                        padding: 0,
                                        mr: tab.value === 2 ? .5 : 0,
                                        ml: tab.value === 1 ? .5 : 0
                                    }}
                                />
                            ))}
                        </Tabs>
                        <AnimatePresence mode="wait">
                            {(gameTab === 1 && animationComplete) && memoizedGameTraditional}
                            {(gameTab === 1 && !animationComplete) && <SlideIn exit="" key={"traditional"} direction="bottom" onFinish={() => {
                                setTimeout(() => {
                                    setAnimationComplete(true)
                                }, 100)
                            }}>{memoizedGameTraditional}</SlideIn>}
                            {gameTab === 2 && <SlideIn key={"express"} direction="bottom"  >{memoizedGameExpress}</SlideIn>}
                        </AnimatePresence>
                    </>
                    :
                    <Outlet />
                }
            </Grid>
            <ClientMenu
                open={menu}
                purchase="client"
                handleClose={() => { setMenu(false) }}
            />

            <TableResults
                open={!!prizeDrawsResult}
                handleClose={() => setPrizeDrawsResult(null)}
                prizeDraw={prizeDrawsResult}
            />

            <TablePrizes
                open={showPrizes}
                handleClose={() => setShowPrize(false)}
                prizes={prizes?.sort((a, b) => b.prizeDrawId - a.prizeDrawId) as PrizesProps[]}
            />

            <TableDetailsWithdrawView
                open={openDetails}
                handleClose={() => {
                    setOpenDetails(false)
                    getAllMovements()
                    queryParams.delete("movements")
                    navigate(`${pathname}?${queryParams.toString()}`);
                }}
                historic={historic}
            />

            <RequestSeller
                open={openModalSeller}
                handleClose={(success) => {
                    setOpenModalSeller(false)
                    if (success) {
                    }
                }}
            />

            <InstallModal
                open={openModalInstallApp}
                handleClose={() => setOpenModalInstallApp(false)}
                key={"install-modal"}
            />

            <ReportUniqueClient
                key={"report-unique-client"}
            />

            <ModalWinnerClient key='prizes-client' />
        </Container >
    )
}

export default BetLayout;

interface chipItemProps {
    label: string;
    onClick: () => void;
    icon?: JSX.Element,
    pulse?: boolean
}

export const ChipItem = ({ label, onClick, icon, pulse }: chipItemProps) => (
    <Chip
        clickable
        label={(
            <section>
                <div style={{
                    whiteSpace: 'normal',
                }}>{label}</div>
            </section>
        )}
        icon={icon}
        color="default"
        sx={{
            bgcolor: '#316FE81A', color: '#234077', fontWeight: 'bold', ':focus': {
                bgcolor: '#316FE81A', color: '#234077',
            },
            height: 'auto',
            padding: '5px 3px',
        }}
        className={`${pulse ? 'pulse' : ''}`}
        onClick={onClick}
    />
)