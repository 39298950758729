import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material"
import RotateComponente from "components/rotate"
import TableView from "components/table"
import useAuth from "context/auth"
import { getQueryParam } from "hooks/useQuery"
import moment, { Moment } from "moment"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { PrizeDrawsService } from "service/prizes"
import { maskCPF, formatCurrency, NameMask, phoneMask } from "utils/mask"

export const ReportUniqueSeller = () => {
    const { palette } = useTheme()
    const { user } = useAuth()
    const { search, pathname } = useLocation()
    const navigate = useNavigate();

    const [result, setResult] = useState<string>()
    const [prizes, setPrizes] = useState<{
        quantity: number,
        legibleName: string,
        value: number
        name: string,
        document: string
    }[]>([])

    const seller = {
        name: user?.name ?? "",
        cellphone: user?.personalData?.phone ?? user?.phone ?? ""
    }
    const totalInPrizes = prizes.reduce((acc, prize) => acc + prize.value, 0)

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const [prizeDrawAt, setPrizeDrawAt] = useState<Moment>(moment())

    useEffect(() => {
        const prizeDraw = getQueryParam("seller-prize", search)
        if (prizeDraw) {
            setOpen(true)
            describe(prizeDraw)
        } else {
            setOpen(false)
        }

    }, [search])

    const renderAsterisk = (value: string, count: number) => {
        value = value?.slice(count)
        for (let i = 0; i < count; i++) {
            value = "*" + value
        }
        return value
    }

    const renderNeighbor = (value: string, floor: "top" | "bottom"): string => {
        let fullNumber = parseInt(value);

        fullNumber = floor === "top" ? fullNumber + 1 : fullNumber - 1;

        return (fullNumber)?.toString()?.padStart(value?.length, '0');
    };

    const renderColorful = (value: string): JSX.Element => {
        let split = value?.split("")
        const arrayNumbers: JSX.Element[] = []

        split?.forEach((item, index) => {
            arrayNumbers.push(<p className={`color-number-${index}`}>{item}</p>)
        })

        return <div style={{
            padding: 0,
            margin: 0,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "nowrap"
        }}>{arrayNumbers}</div>;
    }

    const describe = (prizeDraw: string) => {
        PrizeDrawsService.getReportUniqueSeller(prizeDraw)
            .then(({ data }) => {
                setPrizeDrawAt(moment(data?.prizeDrawAt))
                setPrizes(data?.prizes)
                setResult(data?.result)
                setLoading(false)
            })
            .catch((err) => {
                toast(err?.response?.data?.message ?? "Falha ao buscar resultado", { type: "error" })
                handleClose()
            })
    }

    const handleClose = () => {
        const updatedSearch = search.replace(/([&?])seller-prize=[^&]*/, '').replace(/^&/, '?');
        const cleanSearch = updatedSearch === '?' ? '' : updatedSearch;
        navigate(`${pathname}${cleanSearch}`);
        setLoading(true)

    };

    const orderBy = (prizes: {
        quantity: number;
        legibleName: string;
        value: number;
        name: string;
        document: string;
    }[]) => {
        const groupedByCPF = prizes.reduce((acc, winner) => {
            if (!acc[winner.document]) {
                acc[winner.document] = [];
            }
            acc[winner.document].push(winner);
            return acc;
        }, {} as Record<string, typeof prizes>);

        const groupedAndSummed = Object.values(groupedByCPF).map(group => ({
            document: group[0].document,
            name: group[0].name,
            totalSum: group.reduce((sum, winner) => sum + winner.value, 0),
            prizes: group.sort((a, b) => b.value - a.value)
        }));

        const sortedByTotalSum = groupedAndSummed.sort((a, b) => b.totalSum - a.totalSum);

        const finalSortedList = sortedByTotalSum.flatMap(group => group.prizes);
        return finalSortedList
    }

    const defaultPrizes: {
        label: string,
        result: string | JSX.Element,
        value: number
    }[] = [
            {
                label: "QUADRA",
                result: renderColorful(result),
                value: 50000
            },
            {
                label: "TERNO",
                result: renderColorful(renderAsterisk(result, 1)),
                value: 100
            },
            {
                label: "DUQUE",
                result: renderColorful(renderAsterisk(result, 2)),
                value: 10
            },
            {
                label: "UNIDADE",
                result: renderColorful(renderAsterisk(result, 3)),
                value: 1
            },
            {
                label: "NÚMERO FAVORITO",
                result: <p className="color-number-3">{result?.slice(3)}</p>,
                value: 0.25
            },
            {
                label: "VIZINHO DE CIMA",
                result: renderNeighbor(result, "top"),
                value: 50
            },
            {
                label: "VIZINHO DE BAIXO",
                result: renderNeighbor(result, "bottom"),
                value: 50
            },
            {
                label: "RESULTADO INVERTIDO",
                result: result?.split("")?.reverse()?.join(""),
                value: 50
            },
        ]

    return (
        <Dialog
            open={open}
            fullScreen
            sx={{ zIndex: 2000 }}
        >
            <DialogContent
                sx={{
                    p: 0,
                    maxHeight: '100%',
                    height: '100vh',
                    width: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '100%',
                    margin: 0,
                    overflow: 'hidden'
                }}
            >
                {loading ?
                    <CircularProgress />
                    :
                    <RotateComponente>
                        <Grid display={"flex"} flexDirection={"column"} gap={3} mt={4}>
                            <Grid display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} gap={1}>
                                <Typography variant="h3" textAlign={"center"}>SORTEIO: HORÁRIO {prizeDrawAt?.get("hours")}h, {prizeDrawAt?.format('dddd').toUpperCase()} {prizeDrawAt.format('DD/MM/YYYY')}</Typography>
                                <Typography variant="h1">RESULTADO: {renderColorful(result)}</Typography>
                            </Grid>

                            <Grid>
                                <TableView
                                    align='center'
                                    header={['PARÂMETROS PARA PREMIAÇÃO']}
                                    bgColor={palette.primary.main}
                                >
                                    <></>
                                </TableView>

                                <TableView
                                    align='center'
                                    header={['DESCRIÇÃO PARA ACERTO', 'RESULTADO', 'VALOR DE CADA ACERTO']}
                                    bgColor={palette.primary.main}
                                >
                                    <TableBody>
                                        {defaultPrizes?.map(({
                                            label,
                                            result,
                                            value
                                        }) => {
                                            return (
                                                <TableRow>
                                                    <TableCell align="center">
                                                        {label}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {result}
                                                    </TableCell>

                                                    <TableCell align="center" style={{ minWidth: 150 }}>
                                                        {formatCurrency(value)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}


                                    </TableBody>
                                </TableView>
                            </Grid>

                            <Typography variant="caption" textAlign={"center"}>Legenda: "*" Significa qualquer número natural (0,1,2,3,4,5,6,7,8,9)</Typography>

                            {totalInPrizes > 0 ?
                                <>
                                    <Grid>
                                        <TableView
                                            align='center'
                                            header={[<>LISTA DE GANHADORES<br /> CLIENTES DO REVENDEDOR: {NameMask(seller.name)?.firstName ?? ""} {NameMask(seller.name)?.lastName ?? ""} {phoneMask(seller?.cellphone)}</>]}
                                        >
                                            <></>
                                        </TableView>

                                        <TableView
                                            align='center'
                                            header={['NOME SOBRENOME', 'DOC', 'QUANTIDADE DE ACERTOS', 'ACERTO', 'VALOR DE CADA ACERTO']}
                                            cellWidth={300}
                                        >
                                            <TableBody>
                                                {orderBy(prizes)?.map(({ legibleName, quantity, value, document, name }) => {

                                                    const { firstName, lastName } = NameMask(name)
                                                    return (
                                                        <TableRow>
                                                            <TableCell align="center">{firstName ?? ""} {lastName ?? ""}</TableCell>
                                                            <TableCell align="center">{maskCPF(document)}</TableCell>
                                                            <TableCell align="center">{quantity}</TableCell>
                                                            <TableCell align="center">{legibleName}</TableCell>
                                                            <TableCell align="center">{formatCurrency(value)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </TableView>
                                    </Grid>
                                </>
                                :
                                <TableView
                                    align='center'
                                    header={['NÃO HÁ GANHADOR .']}
                                >
                                    <></>
                                </TableView>
                            }

                            <Typography variant="caption" fontStyle={"italic"} textAlign={"center"}>Em caso de duvida, acesse o regulamento ou clique em Atendimento.</Typography>

                            {/* <Typography variant="caption" textAlign={"center"}>Autenticação: iy7yfwat7awybtuywqbityiawyetbqweotyvbqwioqte</Typography> */}
                        </Grid>
                    </RotateComponente>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose}
                >
                    Fechar
                </Button>
            </DialogActions>
        </Dialog >
    )
}