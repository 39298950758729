import { Button, CircularProgress, Divider, Grid, IconButton, Switch, Typography, useTheme } from "@mui/material"

import BannerCarrousel from "../../components/carrousel";
import { QRCodeComponent } from "../../components/qrCode";
import useAuth from "../../context/auth";
import useBet from "../../context/bet";
import { useEffect, useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { FiCopy } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PrizeDrawsService } from "../../service/prizes";
import StorageService from "../../service/storage";
import { constants, Roles, sendToShow } from "../../utils";
import RoutesPath from "../../utils/routesBases";
import { BsQrCode } from "react-icons/bs";
import { QrCode } from "./qrcode";
import useQuery from "../../hooks/useQuery";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { MdClose, MdFullscreen } from "react-icons/md";
import { BetService } from "../../service/bets";
import TableDetailsWithdrawView from "../client/creditsDetails";
import CardImg from "../../assets/imgs/express/694c40bc-fe94-4f9d-b46c-c4e83dd89010.png"
import { BiMoneyWithdraw } from "react-icons/bi";
import TableDetailsWithdraw from "./credits/details";
import { ToolTipClick } from "layout/menus";
import { formatCurrency } from "utils/mask";
import { GoEye } from "react-icons/go";
import moneySVG from "../../assets/imgs/money.svg"
import { ModalWinnerSeller } from "./modalWinner";
import { ReportUniqueSeller } from "./reportInique";

const App = () => {

    const { prizesDraws, setPrizesDraws, setShowComissionSellerPurchase, showComissionSellerPurchase, ResetBet } = useBet();

    const { palette } = useTheme()

    const navigate = useNavigate();

    const { user } = useAuth();

    const theme = useTheme();

    const [openQrCode, setOpenQrCode] = useState(false)

    const indicationLink = `https://numerofavorito.com/convitedasorte?s=${user?.uuid}`

    const [loadingGuessCredit, setLoadingGuessCredit] = useState(false)

    const [showDetails, setShowDetails] = useState(false);

    const location = useLocation();


    const queryParams = new URLSearchParams(location.search);

    const querySimulator = queryParams.get('movements');

    const [openTooltipCredits, setOpenTolltipCredits] = useState(false)
    const disabledButtonWithDraw = user?.credits ? user?.credits < constants.minPixTransfer : true
    const [openDetails, setOpenDetails] = useState(false);
    const [historic, setHistoric] = useState<{
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA,
        betUUID: string | null,
        clientCpf: string | null,
        clientName: string | null,
        eventNumber: number
    }[] | null
    >(null)
    const query = useQuery()

    const [code, setCode] = useState<string>()

    const copyLink = () => {
        navigator.clipboard.writeText(indicationLink);
        toast('Link copiado!', {
            type: 'success'
        })
    }

    useEffect(() => {
        if (querySimulator) {
            setShowDetails(true)
        }
    }, [querySimulator])

    useEffect(() => {
        PrizeDrawsService.get()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizesDraws(data.prizeDraws);
                }
            })

        const _code = query.get("cartela")

        if (_code) {
            navigate("/cliente")
            // setCode(_code)
            // setOpenQrCode(true)
        }

        setTimeout(() => {
            sendToShow("button-sale-locale", "center")
        }, 700);

        BetService.creditsHistoric()
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
            })
    }, [])

    useEffect(() => {
        if (openTooltipCredits) {
            setTimeout(() => {
                setOpenTolltipCredits(false)
            }, 3000);
        }
    }, [openTooltipCredits])


    const onChangeShowComission = () => {
        StorageService.ViewComissionSeller(showComissionSellerPurchase ? 'false' : 'true')
        setShowComissionSellerPurchase(!showComissionSellerPurchase)
    }

    const verifyModal = () => {
        ResetBet()
        navigate(RoutesPath.Revendedor.bet.home2.path)
    }

    const getAllMovements = (guess?: boolean) => {
        if (guess) {
            setLoadingGuessCredit(true)
        }
        BetService.creditsHistoric(guess)
            .then(({ data }) => {
                if (data) {
                    setHistoric(data)
                }
                if (guess) {
                    setLoadingGuessCredit(false)
                    setOpenDetails(true)
                }
            })
    }

    return (
        <Grid container>
            <Grid container alignItems="center" gap={1} flexDirection={'column'} p={2}>
                {
                    user?.role === Roles.general &&
                    <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <img src={CardImg} style={{
                            width: '95%'
                        }} />
                    </Grid>
                }
                <Typography variant="h2" textAlign={"center"} fontWeight={"400"}>
                    <strong>{user?.role === Roles.general ? "Adm geral" : "Revendedor"}</strong>: <i>{user?.role === Roles.general ? user?.name : user?.name?.split(" ")[0]}</i>
                </Typography>
                {user?.role === Roles.seller && <Typography variant="h3" textAlign={"center"} fontWeight={"400"} mb={2}>
                    Código do Revendedor: <strong>{user?.alternativeId}</strong>
                </Typography>}
                <Typography variant="h4" fontWeight={"400"}>
                    Saldo: <strong style={{ color: (user?.credits ?? 0) < 0 ? 'red' : theme.palette.secondary.main }}>
                        {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </strong>
                </Typography>
                <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems="center"
                    onClick={() => {
                        if (disabledButtonWithDraw) {
                            setOpenTolltipCredits(true)
                        }
                    }}
                    data-tooltip-id={`popup-seller-credits`}
                >
                    <Button
                        variant="contained"
                        size="large"
                        disabled={disabledButtonWithDraw}
                        fullWidth
                        onClick={() => navigate(RoutesPath.Revendedor.saque.path)}
                        startIcon={<BiMoneyWithdraw />}
                    >
                        SOLICITAR SAQUE
                    </Button>
                </Grid>
                <ToolTipClick
                    id={`popup-seller-credits`}
                    label={`Saque disponível apenas para valores acima de ${formatCurrency(constants.minPixTransfer)}. (Conforme Regulamento)`}
                    open={openTooltipCredits}
                    disabled={!disabledButtonWithDraw}
                />

                <Button fullWidth variant="text" sx={{ p: 1, textTransform: 'none', color: palette.grey[500] }} onClick={() => setShowDetails(true)}>
                    <GoEye color={palette.grey[500]} size={25} />
                    <Typography fontSize={16} fontWeight="500" mt={.4} ml={1}>Detalhes do meu saldo</Typography>
                </Button>
                {/* <Button
                    onClick={() => navigate('meusaldo')}
                    color="inherit"
                    sx={{ textDecoration: 'underline', fontSize: 10 }}>(Detalhes do saldo)</Button> */}
            </Grid>
            {/* {
                user?.role === Roles.seller &&
                <Grid container alignItems="center" justifyContent={"center"} gap={1} p={2} xs={12} >
                    <Divider style={{ borderColor: 'GrayText', borderBottomWidth: 'thin' }} />
                    <div>
                        <Typography variant="h5" fontWeight={"400"} textAlign={"center"} color={palette.secondary.main} textTransform={"uppercase"}>
                            CRÉDITO BONIFICADO PARA PAGAMENTO DE APOSTAS:
                        </Typography>
                        <Typography fontWeight="normal" color={palette.secondary.main} variant="h3" textAlign={"center"}>
                            {(user?.guessCredits ?? 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                        </Typography>
                        <Typography color="GrayText" textAlign="center" fontSize={'.8rem'}>(Utilize esse crédito bonificado para pagamento de apostas)</Typography>
                    </div>

                    {user?.role === Roles.seller &&
                        <Grid container justifyContent={'space-between'} alignItems="center">
                            <Button
                                color="secondary"
                                variant="contained"
                                size="large"
                                fullWidth
                                sx={{
                                    animation: 'pulseWithoutBoxShadow 2s infinite'
                                }}
                                onClick={(e) => {
                                    navigate(RoutesPath.Revendedor.deposito.path)
                                }}
                                startIcon={<div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'
                                }}>
                                    <Typography fontSize={20}>+</Typography>
                                    <RiMoneyDollarCircleLine style={{
                                        marginLeft: 2
                                    }} />
                                </div>}
                            >
                                ADICIONAR SALDO PARA APOSTAS
                            </Button>
                        </Grid>
                    }

                    <Button
                        onClick={() => getAllMovements(true)}
                        color="inherit"
                        sx={{ textDecoration: 'underline', fontSize: 10 }}
                        startIcon={loadingGuessCredit && <CircularProgress size={24} />}
                    >(Detalhes do crédito)</Button>
                </Grid>
            } */}
            {
                user?.role === Roles.seller &&
                <>
                    <Grid container alignItems="center" gap={1} p={2} xs={12} onClick={onChangeShowComission}>
                        <Grid xs={2}>
                            <Switch
                                checked={showComissionSellerPurchase}
                                onChange={onChangeShowComission}
                            />
                        </Grid>
                        <Grid xs={9}>
                            <Typography color={theme.palette.grey[700]}>
                                Quero visualizar as comissões no momento da venda
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" gap={1} p={2}>
                        <Grid
                            id={"button-sale-locale"}
                            component={Button}
                            onClick={() => {
                                verifyModal()
                            }}
                            p={2}
                            fullWidth
                            variant="contained"
                            flexDirection={'column'}
                            gap={2}
                            borderRadius={3}
                            height={160}
                            sx={{
                                animation: 'pulseWithoutBoxShadow 2s infinite'
                            }}>
                            <img
                                src={moneySVG}
                                style={{ maxWidth: 50 }}
                                width="100%" />
                            <Typography variant="h3" color="white" textTransform={"uppercase"}>
                                Vender apostas <br /> presencialmente
                            </Typography>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2,
                            mb: 2
                        }} />

                        <Grid
                            component={Button}
                            onClick={() => {
                                setOpenQrCode(true)
                            }}
                            p={2}
                            fullWidth
                            variant="contained"
                            flexDirection={'column'}
                            gap={2}
                            borderRadius={3}
                            sx={{
                                height: '160px',
                                backgroundColor: palette.secondary.main,
                                borderColor: palette.secondary.main,
                                '&:hover': {
                                    borderColor: palette.secondary.main,
                                    backgroundColor: palette.secondary.dark
                                }
                            }}>
                            <BsQrCode size={50} color={"white"} />
                            <Typography variant="h3" color={"white"} textTransform={"uppercase"}>
                                Vender apostas <br /> com cartela
                            </Typography>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2
                        }} />
                        <Button
                            fullWidth
                            onClick={() => {
                                window.open(`https://wa.me/?text=NÚMERO%20FAVORITO%0A%0AGANHE%20ATÉ%20R%24%2050.000%2C00%20no%20PIX%20na%20hora!%0A%0AClique%20aqui%20para%20apostar%20agora%0A${indicationLink}`, '_blank')
                            }}
                            sx={{ p: 2, display: 'flex', gap: 1, mt: 2, borderRadius: 3, animation: 'pulseWithoutBoxShadow 2s infinite' }}
                            color={"success"}
                            variant="contained">
                            <FaWhatsapp size={45} />
                            <Typography variant="h4" color={theme.palette.common.white} textAlign={"start"} textTransform={'uppercase'}>
                                Vender apostas via link
                            </Typography>
                        </Button>
                        <Typography width={"100%"} textAlign={"center"} color={"GrayText"}>Se preferir, copie o link de vendas abaixo</Typography>
                        <Grid container p={2} bgcolor="#0000000A" alignItems="center" justifyContent="space-between">
                            <Typography width="calc(100% - 45px)" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                {indicationLink}
                            </Typography>
                            <IconButton onClick={copyLink}>
                                <FiCopy />
                            </IconButton>
                        </Grid>
                        <Divider sx={{
                            borderWidth: 1,
                            width: '100%',
                            mt: 2
                        }} />
                        <Typography width={"100%"} textAlign={"center"} color={"GrayText"}>OU</Typography>
                        <QRCodeComponent
                            key={indicationLink}
                            link={indicationLink}
                            title="Baixar QRCODE de vendas"
                            midComponent={
                                <Typography width={"100%"} textAlign={"center"} color={"GrayText"} mt={-1}>Ou baixe o QRCODE de vendas</Typography>
                            } />
                    </Grid>
                </>
            }
            <QrCode
                open={openQrCode}
                handleClose={() => setOpenQrCode(false)}
                code={code}
            />

            <TableDetailsWithdrawView
                open={openDetails}
                handleClose={() => {
                    setOpenDetails(false)
                }}
                historic={historic}
            />

            <TableDetailsWithdraw
                open={showDetails}
                handleClose={() => {
                    setShowDetails(false)
                    queryParams.delete("movements")
                    navigate(`${location.pathname}?${queryParams.toString()}`);
                }}
                historic={historic}
            />

            <ModalWinnerSeller key='prizes-seller' />
            <ReportUniqueSeller key='report-unique-seller'/>
        </Grid>
    )
}

export default App;