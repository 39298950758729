import { Sounds } from "../audios/intex";

export class Effects {
    static congratulations() {
        Sounds.playApplause()
        var colors = ['#e0c250', '#66666'];

        function randomInRange(min: number, max: number) {
            return Math.random() * (max - min) + min;
        }

        //@ts-ignore
        confetti({
            particleCount: 20,
            angle: 60,
            spread: 52,
            origin: { x: 0 },
            colors: colors
        });

        // Ajuste o z-index do canvas
        const canvas = document.querySelector('canvas');
        if (canvas) {
            canvas.style.position = 'fixed';
            canvas.style.top = '0';
            canvas.style.left = '0';
            canvas.style.zIndex = '9999'; // Defina um z-index maior que o modal
            canvas.style.pointerEvents = 'none'; // Para que o canvas não bloqueie interações
        }

        //@ts-ignore
        confetti({
            particleCount: 20,
            angle: 120,
            spread: 55,
            origin: { x: 1 },
            colors: colors,
        });

        //@ts-ignore
        confetti({
            particleCount: 20,
            angle: 60,
            spread: 52,
            origin: { x: 0, y: 1 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            particleCount: 20,
            angle: 120,
            spread: 55,
            origin: { x: 1, y: 1 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 0.6 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 1 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 0.3, },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 1, x: 0.3 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 0.5, x: 0.3 },
            colors: colors
        });

        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 1, x: 0.7 },
            colors: colors
        });
        //@ts-ignore
        confetti({
            angle: randomInRange(55, 125),
            spread: randomInRange(50, 70),
            particleCount: 20,
            origin: { y: 0.5, x: 0.7 },
            colors: colors
        });
    }
}
