export const moneyMask = (value: string) => {
    if (!value) {
        return 'R$ 0,00'
    }
    value = value.replace('.', '').replace(',', '').replace(/\D/g, '')

    const options = { minimumFractionDigits: 2 }
    const result = new Intl.NumberFormat('pt-BR', options).format(
        parseFloat(value) / 100
    )

    return 'R$ ' + result
}

export function split(str: string, index: number) {
    const result = [str.slice(0, index), str.slice(index)];

    return result;
}

export const phoneMask = (value: string) => {
    const phone = value?.replace(/\D/g, '');
    const result = phone?.replace(/^(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');

    return result;
}

export const maskCPF = (cpf: string) => {
    return cpf?.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.xxx.xxx-$4");
};

export function formatCPF(cpf: string) {
    if (cpf) {
        cpf = cpf?.replace(/\D/g, '');

        if (cpf.length === 11) {
            cpf = cpf?.replace(/(\d{3})(\d)/, '$1.$2');
            cpf = cpf?.replace(/(\d{3})(\d)/, '$1.$2');
            cpf = cpf?.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        } else {
            cpf = cpf?.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
        }
    }

    return cpf;
}

export const NameMask = (value: string, concat: boolean = false): {
    firstName: string,
    lastName: string
} => {
    var nameSplit = value?.split(" ") ?? [];
    var firstName = nameSplit[0];
    var lastName = nameSplit.length > 1 ? nameSplit[nameSplit.length - 1] : "";
    if (concat) {
        //@ts-ignore
        return `${firstName} ${lastName}`
    }
    return {
        firstName,
        lastName
    };
}

export const currencyToNumber = (value: string) => {
    value = value.replace(/\D/g, "")
    const power = 10 ** 2;
    return (Math.round(parseFloat((Number(value) * power).toPrecision(15))) / power) / 100;
}

export const round = (value: number, precision = 2) => {
    const power = 10 ** precision;
    return Math.round(parseFloat((value * power).toPrecision(15))) / power;
}

export const formatCurrency = (value: number) => {
    return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
}