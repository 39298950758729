import api from "../api"

export class CommissionService {
    static getCommissionPolicies() {
        return api.get('/credits/commissions-policies')
    }

    static createDeposit(value: number) {
        return api.post('/credits/deposit', {
            value
        })
    }

    static getDeposit(id: number) {
        return api.get('/credits/deposit/' + id)
    }
}