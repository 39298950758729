import { Card, Divider, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import useBet from "context/bet";
import { useEffect, useState } from "react";
import moment from "moment";
import { getColorHour } from "pages/bet";


interface CardDrawProps {
    day: string;
    color: {
        color: string,
        img: string
    };
    selected: number | null;
    onChange: (id: number | null) => void;
    id: number | null;
    date: string;
    hors: string;
    prizeDrawFrom?: 0 | 1;
    prizes: {
        id: number,
        prizeDrawId: number,
        value: number,
        order: number,
        legibleName: string
    }[],
    numberPrice: number,
    index: number,
    dayNumber: number,
    prizeDrawAt: string,
    showCloseIn: boolean,
    onReloadPrizeDraws: () => void,
    currentServerTime: string,
    closeIn: string
}

const CardDraw = (props: CardDrawProps) => {

    const { day, color, onChange, selected, id, date, hors: hours, prizeDrawFrom, prizes, numberPrice, dayNumber, prizeDrawAt, showCloseIn, onReloadPrizeDraws, currentServerTime, closeIn } = props;

    const { hoursToShowStamp, purchaseFlow } = useBet()

    const isSelected = selected === id

    const matchMobile = useMediaQuery('(min-width:448px)')
    const overrideStamp = useMediaQuery('(min-width:398px)')
    const breakWord = useMediaQuery('(min-width:350px)')

    return (
        <Grid container position={"relative"} sx={{
            ... (!selected && {
                animation: `pulseWithoutBoxShadow 2s infinite`
            })
        }}>
            <Card
                onClick={() => onChange(id ?? null)}
                variant="outlined"
                sx={{
                    width: '100%',
                    borderRadius: 3,
                    borderColor: '#0B4CEA',
                    bgcolor: 'transparent',
                    cursor: 'pointer',
                    position: 'relative',
                    borderWidth: 2,
                    ...(isSelected && {
                        boxShadow: '5px 5px #021878'
                    })

                }}>
                <Grid sx={{ p: 1 }}>
                    <Typography variant="h5" textAlign='center' fontWeight="400" color={'#021878'}>
                        {day}{!breakWord && <br />} {date}
                    </Typography>
                    <Typography variant="h4" textAlign='center' fontWeight="500" color={'#021878'} >
                        <strong>{hours} horas</strong>
                    </Typography>
                    {showCloseIn &&
                        <Typography variant="h5" textAlign='center' fontWeight="500" color={'GrayText'} sx={{
                            fontStyle: 'italic'
                        }}>
                            Encerra em: <strong>
                                <Timer
                                    key={'time-' + hours}
                                    hours={moment(closeIn).diff(moment(currentServerTime), 'seconds')}
                                    onTimerZero={onReloadPrizeDraws}
                                />
                            </strong>
                        </Typography>
                    }
                    {
                        isSelected &&
                        <Grid>
                            <Divider style={{ borderWidth: 'thin', marginTop: 10, marginBottom: 10, borderColor: 'GrayText' }} />
                            <Typography variant="h5" textAlign='center' fontWeight="400" color={"#021878"} pr={hours === hoursToShowStamp ? 5 : 0}>
                                Veja acima os <strong>prêmios individuais</strong>
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Card>
            <img
                src={getColorHour(prizeDrawAt).img}
                style={{
                    position: 'absolute',
                    left: purchaseFlow === "client" ? matchMobile ? -10 : -25 : -40,
                    top: purchaseFlow === "client" ? matchMobile ? -10 : -25 : -20,
                    cursor: "pointer",
                    width: "80px",
                    transform: 'rotate(-15deg)',
                }}
            />
        </Grid>
    )
}

export default CardDraw;

const Timer = ({
    hours,
    onTimerZero
}: {
    hours: number,
    onTimerZero: () => void
}) => {

    const [time, setTime] = useState(0);

    useEffect(() => {

        const timer = setInterval(() => {
            setTime((prevTime) => prevTime > 0 ? prevTime - 1 : 0);
        }, 1000);

        return () => clearInterval(timer);

    }, []);

    useEffect(() => {
        if (hours) setTime(hours)
    }, [hours])

    useEffect(() => {
        if (time === 0) {
            setTimeout(() => {
                onTimerZero()
            }, 15000);
        }
    }, [time])



    return <>{formatTime(time)}</>

};

const formatTime = (time: number) => {
    const duration = moment.duration(time, 'seconds');

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const pad = (num: number) => String(num).padStart(2, '0');

    // Se houver dias, exiba-os, caso contrário, só exibe horas, minutos e segundos
    return days > 0
        ? `${days}d ${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`
        : `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
};