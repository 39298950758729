import ConfirmAnimation from "assets/animations/Confirm.json"
import CancelAnimation from "assets/animations/Cancel.json"
import { WinnersProps } from "types"

export class Utils {
    static URLSBases = {
        apisNFV: "https://api.numerofavorito.com",
        ws: "ws://api.numerofavorito.com"
    }
    static storageLocales = {
        NFVtoken: '@NFV-tokken',
        NFVtokenClient: '@NFV-client-tokken',
        NFVSellerComissionShow: '@NFV-seller_comission_show',
        NFVKeyPix: '@NFV-key-pix',
        NFVCountClickModalFirstPurchase: '@NFV-count-click-modal-first-purchase',
        NFVCountClickModal30: '@NFV-count-click-modal-30',
        NFVCountClickFraud: '@NFV-count-click-modal-fraud'

    }

    static hexToRgb(color: string) {
        let c: any;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
            c = color.substring(1).split('');
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = '0x' + c.join('');
            return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
        }
        throw new Error('Bad Hex');
    }
}

export class ScrollManager {
    static scrollBlockCount = 0; // Contador de bloqueios

    static blockScroll() {
        if (this.scrollBlockCount === 0) {
            document.body.classList.add('no-scroll');
        }
        this.scrollBlockCount += 1;
    }

    static unBlockScroll() {
        if (this.scrollBlockCount > 0) {
            this.scrollBlockCount -= 1;
        }
        if (this.scrollBlockCount === 0) {
            document.body.classList.remove('no-scroll');
        }
    }
}

export const sendToShow = (id: string, placement: ScrollLogicalPosition = 'start') => {
    const element = document.getElementById(id)
    if (element) {
        element.scrollIntoView({
            block: placement ?? 'center',
            behavior: "smooth"
        });
    }
}

export function isMobile(): boolean {
    if (navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        || navigator.userAgent.match(/Tablet/i)
        || navigator.userAgent.match(/Mobile/i)
    ) {
        return true
    }
    else {
        return false
    }
}

export const constants = {
    minPixTransfer: 7.5,
    pixTransferTax: 2.5,
    homeUser: "DYNqidJ0DbXR"
}

export const Roles = {
    general: 20,
    seller: 10,
    client: 1,
}

export const suportNumber = "55999042356"

export class Assets {
    static Animations = {
        confirm: ConfirmAnimation,
        cancel: CancelAnimation
    }
}


export async function awaitTime(time: number) {
    return await new Promise((resolve) => {
        setTimeout(() => {
            resolve(true)
        }, time * 1000)
    })
}

export const BRAZIL_STATES = [
    'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS',
    'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC',
    'SP', 'SE', 'TO'
];

export const BET_STATUS: {
    [key: number]: string
} = {
    0: "CRIADA",
    1: "AGUARDANDO PAGAMENTO",
    2: "AGUARDANDO PAGAMENTO",
    3: "AGUARDANDO PAGAMENTO",
    4: "CONFIRMADA",
    5: "CANCELADA",
}

export const orderWinnerByGroup = (prizeDrawWinners: WinnersProps[]) => {
    if (!prizeDrawWinners) return prizeDrawWinners
    const arrayWinners: string[] = []
    const groupedByCPF = prizeDrawWinners.reduce((acc, winner) => {
        if (!acc[winner.cpf]) {
            acc[winner.cpf] = [];
        }
        acc[winner.cpf].push(winner);
        return acc;
    }, {} as Record<string, typeof prizeDrawWinners>);

    const groupedAndSummed = Object.values(groupedByCPF).map(group => ({
        cpf: group[0].cpf,
        name: group[0].name,
        totalSum: group.reduce((sum, winner) => sum + winner.total, 0),
        prizes: group.sort((a, b) => b.total - a.total)
    }));

    const sortedByTotalSum = groupedAndSummed.sort((a, b) => b.totalSum - a.totalSum);

    return sortedByTotalSum.flatMap(group => group.prizes)
}