import { Dialog, Grid, Typography } from "@mui/material"
import Lottie from "lottie-react"
import { Assets } from "utils"

export default function PaymentMade({ show, text }: { show: boolean, text?: string }) {
    return (
        <Dialog open={show}
            PaperProps={{ sx: { bgcolor: 'transparent !important', boxShadow: 'none', p: 0, m: 0, maxHeight: '100vh' , maxWidth: '100vw'} }}
            componentsProps={{ backdrop: { sx: { bgcolor: 'rgba(0, 0, 0, 0.7)' } } }}>
            <Grid container className="paymentMadeClass">
                <Grid width="100vw" container alignItems={"center"} flexDirection={"column"}>
                    <Lottie animationData={Assets.Animations.confirm} loop={false} style={{ width: 100 }} />
                    <Typography color="white" variant="h2"  textAlign={"center"}>
                        {text ?? "O pagamento foi efetuado com sucesso!"}
                    </Typography>
                </Grid>
            </Grid>
        </Dialog>
    )
}