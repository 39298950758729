import { Box, Container, Grid } from "@mui/material"
import SVGLOGO from "assets/imgs/logo 01.png"
import { Outlet } from "react-router-dom"
import 'swiper/css';

const ClientAppLayout = () => {

    return (
        <Container maxWidth="xs" sx={{ p: 0 }}>
            <Grid justifyContent='center' alignItems='center' container alignContent='flex-start'>
                <Grid container justifyContent='center' alignContent='center' p={2}>
                    <div>
                        <Grid container flexDirection='column' alignItems={'center'}>
                            <img src={SVGLOGO} width={100} />
                        </Grid>
                    </div>
                </Grid>
                <Box width={'100%'}>
                    <Outlet />
                </Box>
            </Grid>
        </Container>
    )
}
export default ClientAppLayout;