import { Button, Grid } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";

const LearnBetPage = () => {

    const navigate = useNavigate();

    return (
        <Grid container alignItems="center" gap={5} flexDirection={'column'}>
            <Grid container maxWidth={400}>
                <Player
                    autoPlay
                    src='https://s3.amazonaws.com/static.numerofavorito.com/video+aprenda.mp4' />
            </Grid>
            <Button 
            fullWidth
            sx={{ maxWidth: 250, mt: 5 }}
            variant="contained"
            onClick={() => navigate(-1)}>
                Voltar
            </Button>
        </Grid>
    )
}

export default LearnBetPage;