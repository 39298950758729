import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useTheme } from "@mui/material"
import { useEffect, useRef, useState } from "react";
import { QrReader } from 'react-qr-reader';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BetService } from "../../../service/bets";
import RoutesPath from "../../../utils/routesBases";

export const QrCode = ({
    open,
    handleClose,
    code
}: {
    open: boolean,
    handleClose: () => void;
    code?: string

}) => {

    const { palette } = useTheme()

    const navigate = useNavigate()

    const [data, setData] = useState<{
        [key: number]: string | null
    }>({
        0: null,
        1: null,
        2: null,
        3: null,
        4: null,
        5: null,
        6: null,

    })

    const [disabled, setDisabled] = useState(false)

    const [activeInput, setActiveInput] = useState<null | number>(null)

    const [loading, setLoading] = useState(false);

    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);


    const validNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

    const insertFullValue = (value: string) => {
        if (value.length !== 7) {
            alert("Número da cartela inválido!")
            return
        }
        const split = value.split("")
        let newValue: {
            [key: number]: string
        } = {}
        let i = 0
        for (let v of split) {
            newValue = {
                ...newValue,
                [i]: v
            }
            i++
        }
        setData(newValue);
        setDisabled(true)
    }

    const getCard = async () => {
        setLoading(true)
        await BetService.getCard(Object.values(data).join(""))
            .then(({ data }) => {
                navigate(RoutesPath.Revendedor.bet.home2.path, {
                    state: data
                })
            }).catch((err) => {
                toast(err.response.data.message, {
                    type: "error"
                })
            }).finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (code) {
            insertFullValue(code)
        }
    }, [code])




    return (
        <Dialog
            fullScreen={window.screen.width < 700}
            open={open}
            maxWidth={"xs"}
        >
            <DialogTitle>
                <Typography textAlign={"center"} variant="h3">Aponte a câmera do seu celular para o QRCODE da cartela</Typography>
            </DialogTitle>
            <DialogContent sx={{
                mt: 2
            }}>
                <QrReader
                    onResult={(result, error) => {
                        if (!!result) {
                            const value = result.getText().split('?cartela=')[1]
                            insertFullValue(value)
                        }

                        if (!!error) {
                            console.info(error);
                        }
                    }}
                    constraints={{
                        facingMode: {
                            exact: "environment"
                        }
                    }}
                />
                <Typography textAlign={"center"} mt={2}>Ou se preferir<br />Digite abaixo o número da cartela</Typography>
                <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={.5} mt={2} alignItems="center" justifyContent={'center'}>
                    {
                        Object.keys(data).map((_, index) => (
                            <Grid
                                container
                                borderRadius={2}
                                border={`1px solid ${activeInput === index ? palette.primary.main : palette.grey[500]}`}
                                p={2}
                                alignItems="center"
                                justifyContent={'center'}
                                maxWidth={85}
                                width={'100%'}
                                bgcolor="white"
                                style={{
                                    transition: 'all .3s ease',
                                    ...(activeInput === index && {
                                        transform: 'scale(1.1)'
                                    }),
                                    ...(activeInput !== index && activeInput !== null && {
                                        transform: 'scale(.9)'
                                    })
                                }}
                            >
                                <input
                                    disabled={disabled || loading}
                                    style={{
                                        fontSize: '16px',
                                        color: disabled || loading ? 'GrayText' : palette.primary.main
                                    }}
                                    ref={(el) => inputsRef.current[index] = el}
                                    inputMode="numeric"
                                    value={data[index as 0 | 1 | 2] ?? ''}
                                    onChange={(ev) => {
                                        const value = ev.target.value.slice(-1)
                                        if (validNumbers.includes(value.toString())) {
                                            setData(prev => ({
                                                ...prev,
                                                [index]: value.toString()
                                            }))
                                            inputsRef.current[index + 1]?.focus();
                                        }
                                    }}
                                    onKeyDown={(ev) => {
                                        if (ev.keyCode === 8) {
                                            setData(prev => ({
                                                ...prev,
                                                [index]: null
                                            }))
                                            inputsRef.current[index - 1]?.focus();
                                        }
                                    }}
                                    onFocus={() => setActiveInput(index)}
                                    onBlur={() => setActiveInput(null)}
                                    className="input_number_fav"
                                />
                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container justifyContent={"center"} direction={"column"} gap={1}>
                    <LoadingButton variant="contained" sx={{
                        height: '50px',
                        mb: .5,
                        ... (!(loading || Object.values(data).includes(null)) && {
                            animation: 'pulseWithoutBoxShadow 1.5s infinite'
                        })
                    }} disabled={loading || Object.values(data).includes(null)} onClick={getCard} loading={loading}>Clique para continuar</LoadingButton>
                    <Button variant="outlined" sx={{
                        color: 'GrayText',
                        borderColor: 'GrayText'
                    }} onClick={() => {
                        handleClose()
                        setData({
                            0: null,
                            1: null,
                            2: null,
                            3: null,
                            4: null,
                            5: null,
                            6: null,
                        })
                    }} color="inherit">Fechar</Button>
                </Grid>
            </DialogActions>

        </Dialog>
    )
}