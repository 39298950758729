import api from "../api"

export class ExpressService {
    static create(data: {
        clientId: number,
        sellerId: string,
        numbers: string[],
    }) {
        return api.post(`inst/bets`, data)
    }

    static makeDrawn(id: number) {
        return api.patch(`inst/bets/drawn/${id}`)
    }

    static playCoin(id: number) {
        return api.patch(`inst/bets/play-coin/${id}`)
    }

    static async getCredits(): Promise<{
        "credits": 13.5,
        "guessCredits": 0,
        "coinCredit": 838.85
    }> {
        const { data } = await api.get(`inst/bets/client-credit`)
        return data
    }
}