import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

interface RotateProps {
    children: React.ReactNode
}

export default function RotateComponente({ children }: RotateProps) {

    const isMobile = useMediaQuery("(max-width:600px)")

    return (
        <Grid sx={{
            transform: isMobile ? 'rotate(-90deg)' : '',
            color: 'white',
            height: 'calc(100vw)',
            width: 'calc(100vh - 6.281rem)',
            overflow: 'auto'
        }}>
            {children}
        </Grid>
    );
}