import React from "react";

interface NumberInputProps extends  React.InputHTMLAttributes<HTMLInputElement> {
  onChangeNumber: (value: number) => void;
  inputRef?:(el: any) => void;
}

export const NumberInput: React.FC<NumberInputProps> = ({ value, onChangeNumber, inputRef, ...rest }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(event.target.value.replace(/\D/g, ''));
      onChangeNumber(newValue);
      rest.onChange && rest.onChange(event)
    };
  
    return (
      <input
        {...rest}
        ref={inputRef}
        maxLength={1}
        inputMode="numeric"
        value={value ?? ''}
        onChange={handleChange}
        className="input_number_fav"
      />
    );
  };