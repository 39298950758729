import { Dialog, DialogContent, Grid, Typography } from "@mui/material"
import { SlideIn } from "../../../components/slide"
import { useEffect, useState } from "react"
import { AnimatePresence } from "framer-motion"
import { formatCurrency } from "../../../utils/mask"
import { useScript } from "../../../hooks/useScript"
import { Effects } from "../effects"
import { CoinFlip } from "../../../components/coin"

interface Props {
    open: boolean,
    prizes: {
        betId: number
        fundId: number
        numberId: number
        value: number
        prize: string
        number: string
    }[]
    onHandleClose: () => void,
}

export const EarningModal = ({
    open,
    prizes,
    onHandleClose
}: Props) => {
    useScript("https://cdn.jsdelivr.net/npm/canvas-confetti@1.9.3/dist/confetti.browser.min.js")
    const [isVisible, setIsVisible] = useState(true)
    const [prizeTypes, setPrizeTypes] = useState<string[]>([])
    const interval = 1000

    useEffect(() => {
        if (prizes.length > 0) {
            const types: string[] = []
            for (let p of prizes) {
                if (!types.includes(p.prize)) types.push(p.prize)
            }
            setPrizeTypes(types)
        }
    }, [prizes])

    useEffect(() => {
        let timeouts: NodeJS.Timeout[] = [];

        if (open) {
            Effects.congratulations()
            const hideTimeout = setTimeout(() => {
                setIsVisible(false);
                setTimeout(() => {
                    setIsVisible(true)
                    setPrizeTypes([])
                    onHandleClose();
                }, 1000);
            }, 6000 + (prizeTypes.length + 1) * interval);

            timeouts.push(hideTimeout);
        }

        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
        }
    }, [open, prizeTypes, onHandleClose])

    const calcValues = (prizeName: string): {
        quantity: number,
        value: number
    } => {
        const filteredPrizes = prizes.filter(prize => prize.prize === prizeName);

        // Calcula a quantidade e o valor total
        const quantity = filteredPrizes.length;
        const value = filteredPrizes.reduce((acc, prize) => acc + prize.value, 0);

        return { quantity, value };
    }

    const calcTotalPrize = () => {
        return prizes.reduce((acc, prize) => acc + prize.value, 0);
    }

    const renderPlural = (text: string, quantity: number) => {
        return quantity > 1 ? text.split(" ").map((item) => item + "s").join(" ") : text
    }

    const prizeName = ["Quadra", "Terno", "Duque", "Unidade"]

    return (
        <Dialog
            fullScreen
            open={open}
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0,0,0, 0.5)',
                    boxShadow: 'none',
                },
            }}
            disableScrollLock
        >
            <DialogContent>
                <AnimatePresence mode="sync">
                    {isVisible && (
                        <SlideIn key={`congratulation-eargin`}
                            direction="bottom"
                            exit="top"
                            type="spring"
                            usingModal>
                            <Grid
                                key="prizes-grid"
                                container
                                height={"100%"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                gap={"20px"}
                                mt={10}
                            >
                                <Typography variant="h1" color={"white"} sx={{ color: '#00e114' }} textAlign={"center"}>
                                    PARABÉNS!
                                </Typography>

                                <Typography variant="h2" color={"white"} textAlign={"center"}>
                                    Você foi premiado em
                                </Typography>

                                {prizeTypes.sort((a, b) => prizeName.indexOf(a) - prizeName.indexOf(b)).map((prize, index) => {
                                    const { quantity, value } = calcValues(prize)
                                    return (
                                        <Typography variant="h2" color={"white"} textAlign={"center"}>
                                            {quantity} {renderPlural(prize, quantity)}
                                        </Typography>
                                    )
                                })}

                                <Typography variant="h2" color={"white"} sx={{ color: '#00e114' }} textAlign={"center"}>
                                    JÁ GANHOU<br />{formatCurrency(calcTotalPrize())}
                                </Typography>

                                <Grid display={"flex"} gap={2} width={"100%"} mt={1} direction={"column"} justifyContent={"center"} alignItems={"center"}>
                                    <CoinFlip key={'sadsadds'} result={null} onFinish={() => { }} />
                                </Grid>

                                <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} mt={1}>
                                    {["Agora tente multiplicar seus ganhos com", "CARA OU COROA"].map((text) => <Typography variant="h3" textAlign={"center"} textTransform={"uppercase"} color={"white"} fontWeight={"bold"}>{text}</Typography>)}
                                </Grid>
                            </Grid>
                        </SlideIn>

                    )}
                </AnimatePresence>
            </DialogContent>
        </Dialog >
    )
}
