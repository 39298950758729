import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IndicationsProps from '../../../types/indications';
import { NameMask } from '../../../utils/mask';
import RotateComponente from '../../../components/rotate';
import useAuth from '../../../context/auth';
import { Roles } from '../../../utils';

interface TabeViewProps {
    open: boolean,
    handleClose: () => void;
    indicators: IndicationsProps[] | null
}

export default function TableView({ open, handleClose, indicators }: TabeViewProps) {

    const theme = useTheme();

    const { user } = useAuth()

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
                // overflow: 'hidden'
            }}>
                <RotateComponente>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead sx={{ bgcolor: "#316FE8" }}>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>Nome</TableCell>
                                    <TableCell sx={{ color: 'white' }}>WhatsApp</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Cidade</TableCell>
                                    <TableCell sx={{ color: 'white' }}>UF</TableCell>
                                    {user?.role === Roles.general && <TableCell sx={{ color: 'white' }}>Indicado por</TableCell>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {indicators?.map((row, index) => {
                                    const color = index % 2 === 0 ? '#316FE840' : '#316FE824'
                                    const NameSeller = NameMask(row?.name ?? "")
                                    return (
                                        <TableRow

                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {NameSeller.firstName} {NameSeller.lastName}
                                            </TableCell>
                                            <TableCell>{row?.personalData?.phone}</TableCell>
                                            <TableCell>{row?.personalData?.city}</TableCell>
                                            <TableCell>{row?.personalData?.uf}</TableCell>
                                            {user?.role === Roles.general && <TableCell>{row?.indicatedBy}</TableCell>}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}