import * as React from 'react';
import { Grid, useTheme } from '@mui/material';
import { NumberInput } from '../../components/input/numberInput';


interface PinNumbersProps {
    0: number | null,
    1: number | null,
    2: number | null,
    3?: number | null
}

interface PinProps {
    onCofirm?: (toke: string) => void;
    desible?: boolean;
    PinValues: PinNumbersProps;
    onChangePin: (PinNumbers: PinNumbersProps) => void;
    focus?: number
}

export default function Pin({ onCofirm, desible, PinValues, onChangePin, focus }: PinProps) {

    const { palette } = useTheme();

    const inputsRef = React.useRef<(HTMLInputElement | null)[]>([]);

    React.useEffect(() => {
        let token = String(PinValues[0]) + String(PinValues[1]) + String(PinValues[2])
        if (verifyNumber(PinValues[0]) && verifyNumber(PinValues[1]) && verifyNumber(PinValues[2])) {
            inputsRef.current[2]?.blur()
            onCofirm && onCofirm(token)
        }
    }, [PinValues])

    const verifyNumber = (x:number | null | undefined) => {
        if(x === null || x === undefined){
            return false 
        }
        return true
    }

    React.useEffect(() => {
        inputsRef.current[0]?.focus();
    },[])

    React.useEffect(() => {
        if(focus){
            inputsRef.current[focus]?.focus();
        }
    },[focus])

    return (
        <Grid container flexDirection={"row"} flexWrap={"nowrap"} gap={1} mt={2} alignItems="center" justifyContent={'center'}>
            {
                Array(3).fill(null).map((_, index) => (
                    <Grid 
                    container 
                    borderRadius={2} 
                    border={`1px solid ${palette.grey[500]}`} 
                    p={2} 
                    alignItems="center" 
                    justifyContent={'center'}
                    maxWidth={85} 
                    width={'100%'} 
                    bgcolor="white">
                        <NumberInput
                            disabled={desible}
                            onFocus={event => event.target.select()}
                            inputRef={(el) => inputsRef.current[index] = el}
                            value={PinValues[index as 0 | 1 | 2] ?? undefined}
                            onChange={(value) => {
                                if (!isNaN(Number(value.target.value)) && index < 2) {
                                    inputsRef.current[index + 1]?.focus();
                                }
                            }}
                            onChangeNumber={(value) => {
                                onChangePin({
                                    ...PinValues,
                                    [index]: value
                                })
                            }}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}