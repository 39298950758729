import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { To, useLocation, useNavigate } from "react-router-dom"
import moment from "moment";
import { PrizeDrawsService } from "../../service/prizes";
import ClientAppLayout from "../../layout/clients";
import useAuth from "../../context/auth";
import AppLayout from "../../layout/saller";
import RoutesPath from "../../utils/routesBases";


export function Videos() {
    const { state } = useLocation();
    const { tokenClient, token } = useAuth();

    const navigate = useNavigate();
    const [isClient, setIsClient] = useState(true);
    const [videos, setVideos] = useState<{
        id: number,
        prizeDrawAt: string,
        videoUrl: string,
        contentType: string
    }[]>([])

    useEffect(() => {
        if (!tokenClient && !token) {
            navigate(RoutesPath.Clients.login.path)
        }
        if (state?.prev_path) {
            if (state?.prev_path?.includes("correspondente") || state?.prev_path?.includes("venda")) {
                setIsClient(false)
            }
        } else {
            if (!tokenClient) {
                setIsClient(false)
            }
        }
        getVideos()
    }, [])

    const getVideos = async () => {
        let localVideos: {
            id: number,
            prizeDrawAt: string,
            videoUrl: string,
            contentType: string
        }[] = []
        if (token) {
            const tokenSeller = token.access
            await PrizeDrawsService.getVideos(tokenSeller)
                .then(({ data }) => {
                    if (data?.videos) {
                        localVideos = data?.videos
                    }
                })
        }
        if (tokenClient) {
            const _token = tokenClient.access;
            await PrizeDrawsService.getVideos(_token)
                .then(({ data }) => {
                    if (data?.videos) {
                        for (let _v of data?.videos) {
                            if (!localVideos.find((item) => item.id === _v.id)) {
                                localVideos.push(_v)
                            }
                        }
                    }
                })
        }

        setVideos(localVideos)
    }

    return (
        <>
            {isClient ? <ClientAppLayout /> : <AppLayout />}
            <Grid xs={12} display={"flex"} flexDirection={"column"}>
                <Typography variant="h3" textAlign={"center"} mt={1}>Transmissões dos Sorteios</Typography>
                <Typography mt={2} width={"90%"} alignSelf={"center"} textAlign={"center"}>Disponível até 24 horas após a transmissão do sorteio que você apostou.</Typography>
                <Grid container display={"flex"} flexDirection={"column"} gap={4} mt={4}>
                    {videos.map((video) => {
                        return (
                            <Card
                                key={video.id}
                                sx={{
                                    width: '90%',
                                    alignSelf: 'center'
                                }}>
                                <CardContent>
                                    <Typography textAlign={"center"} variant="h5" mt={2} mb={2}>{String(moment(video.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(video.prizeDrawAt).format("L")).toUpperCase()} às {String(moment(video.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas</Typography>
                                    <video controls controlsList="nodownload" preload="auto" playsInline style={{
                                        maxWidth: '100%'
                                    }}>
                                        <source src={video.videoUrl} type={"video/mp4"} />
                                    </video>
                                </CardContent>
                            </Card>
                        )
                    })}
                </Grid>
                <Grid xs={12} mt={4} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                    <Button variant="contained" color="primary" onClick={() => navigate((state?.prev_path ? -1 : '/') as To)}>Voltar</Button>
                </Grid>
            </Grid>
        </>
    )
};