import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import RotateComponente from '../../../components/rotate';
import TableView from '../../../components/table';
import moment from 'moment';
import { NameMask, maskCPF } from '../../../utils/mask';

interface TableCommissionProps {
    open: boolean,
    handleClose: () => void;
    historic: {
        balance: number, // SALDO
        creditValue: number, // VALOR
        event: number | string, // DESCRIÇÃO
        createdAt: Date | string, // DATA
        kind: string, // NATUREZA,
        clientCpf?: string,
        clientName?: string,
        prizeDraw?: {
            id: number,
            prizeDrawAt: string
        }

    }[] | null;
}

export default function TableDetailsWithdraw({ open, handleClose, historic }: TableCommissionProps) {

    const theme = useTheme();

    const prize = ['Quadra', 'Terno', 'Duque', 'Unidade']

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0,} }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['DATA', 'NATUREZA', 'DESCRIÇÃO', 'VALOR', 'SALDO']}
                    >
                        <TableBody>
                            {
                                historic?.map(({
                                    createdAt,
                                    event,
                                    balance,
                                    creditValue,
                                    kind,
                                    clientCpf,
                                    clientName,
                                    prizeDraw
                                }) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">
                                                {moment(createdAt).format("DD/MM/YYYY")}
                                            </TableCell>
                                            <TableCell align="center">
                                                {kind}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontWeight: 'bold' }}>
                                                {event}
                                                <br />
                                                {(clientName && clientCpf) && <Typography style={{
                                                    fontSize: '10px'
                                                }}>
                                                    <strong>CONCURSO</strong>: {prizeDraw?.id}, {String(moment(prizeDraw?.prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDraw?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas

                                                    <br/>
                                                    <strong>CLIENTE</strong>: {NameMask(clientName).firstName} {NameMask(clientName).lastName}, CPF: {maskCPF(clientCpf)}
                                                </Typography>
                                                }
                                            </TableCell>
                                            <TableCell align="center" style={{ minWidth: 150, fontWeight: 'bold' }}>
                                                {creditValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                            <TableCell align="center" style={{ minWidth: 150 }}>
                                                {balance?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }

                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                {/* <Button onClick={handleClose}>

                </Button> */}
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}