import { useEffect, useState } from "react";

export const useAudio = (url: string): [boolean, () => void, () => void] => {
    const [audio] = useState(new Audio(url));
    const [playing, setPlaying] = useState(false);
  
    const toggle = () => {
        setPlaying(!playing)
        if (!playing) reset();
    };

    const reset = () => audio.currentTime = 0;
  
    useEffect(() => {
        playing ? audio.play() : audio.pause();
      },
      [playing]
    );
  
    useEffect(() => {
      audio.addEventListener('ended', () => setPlaying(false));
      return () => {
        audio.removeEventListener('ended', () => setPlaying(false));
      };
    }, []);
  
    return [playing, toggle, reset];
  };