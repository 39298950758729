import { Grid, Typography, Card, CardContent, useTheme, Container } from "@mui/material"
import TopBar from "./topbar";
import tempBANNER from "assets/imgs/BANNER.png"
import Subtract from "./subtract";

const TicketPage = () => {

    const theme = useTheme();

    return (
        <Container maxWidth="sm">
            <Grid container gap={2}>
                <TopBar />
                <img src={tempBANNER} alt="Banner" width="100%" />
                <Typography textAlign="center">
                    O <strong> BÔNUS COMPRADOR TOP 1 </strong>do concurso NÚMERO FAVORITO de <strong>Quarta-feira, 19/03/2024</strong>,
                    já está  <strong>ACUMULADO em R$ 289,17</strong>
                </Typography>
                <Grid container p={2} gap={2}>
                    <CardItem />
                    <CardItem />
                    <CardItem />
                </Grid>
                <Subtract />
            </Grid>
        </Container>
    )
}

const CardItem = () => {
    const theme = useTheme();
    return (
        <Card variant="outlined" color="inherit" sx={{ width: '100%', borderColor: theme.palette.grey[900] }}>
            <CardContent>
                <Grid container justifyContent={'space-between'}>
                    <div>
                        <Grid container>
                            <Typography color={theme.palette.primary.main} fontWeight="bold" variant="h3">
                                1º Lugar:
                            </Typography>
                            <Typography sx={{ ml: 1 }} fontWeight="400" variant="h3">
                                José *
                            </Typography>
                        </Grid>
                    </div>
                    <Typography fontWeight="400" variant="h4">
                        Comprou
                    </Typography>
                </Grid>
                <Grid container justifyContent={'space-between'} mt={1}>
                    <div>
                        <Grid container>
                            <Typography color="GrayText" fontWeight="bold" variant="h5">
                                CPF:
                            </Typography>
                            <Typography sx={{ ml: 1 }} color="GrayText" fontWeight="400" variant="h5">
                                9xx.0xx.xx7-xx
                            </Typography>
                        </Grid>
                    </div>
                    <Typography color={theme.palette.primary.main} fontWeight="bold" variant="h4">
                        870 apostas
                    </Typography>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default TicketPage;