import { useForm } from "react-hook-form"
import { CircularProgress, FormControl, FormHelperText, Grid, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material"
import { useState } from "react";
import { Validators } from "../../../utils/validators";
import { CNPJMaskCustom, CPFMaskCustom } from "../../../components/mask";
import { AuthService } from "../../../service/auth";
import { LoadingButton } from "@mui/lab";
import useAuth from "../../../context/auth";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Roles } from "../../../utils";

const LoginPage = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues
    } = useForm<{ cpf: string, password: string }>()

    const { Authenticate } = useAuth();

    const [loading, setLoading] = useState(false);

    const [showPassword, setShowPassord] = useState(false);

    const [loadingCPF, setLoadingCPF] = useState(false)

    const [type, setType] = useState<"pf" | "pj">("pf")

    const navigate = useNavigate()

    const { search } = useLocation()

    const theme = useTheme();

    const handleSubmitLogin = ({ cpf, password }: { cpf: string, password: string }) => {
        setLoading(true)
        const role = window.location.pathname.includes('geral') ? Roles.general : Roles.seller
        AuthService.login(cpf.replace(/\D/g, ""), password, role)
            .then(({ data }) => {
                if (data?.id) {
                    Authenticate(data, data?.role === 20 ? 'general' : 'seller')
                }
            })
            .catch((err) => {
                toast(err?.response?.data?.message ?? "Error ao realizar login", { type: "error" })
            })
            .finally(() => setLoading(false))
    }

    return (
        <Grid mt={5}>
            <Typography
                fontWeight="bold"
                variant="h2"
                textAlign="center">

            </Typography>
            <Grid container mt={5} gap={2}>

                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Tipo de cadastro
                </Typography>
                <Grid container>
                    <Select fullWidth value={type} onChange={(e) => {
                        setType(e.target.value as any)
                        setValue("cpf", "")
                    }}>
                        <MenuItem value="pf">Pessoa Física</MenuItem>
                        <MenuItem value="pj">Pessoa Jurídica</MenuItem>
                    </Select>
                </Grid>

                <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe seu {type === "pf" ? "CPF" : "CNPJ"}
                </Typography>
                <Grid container>
                    <FormControl fullWidth>
                        <OutlinedInput
                            error={!!errors.cpf}
                            placeholder={type === "pf" ? "000.000.000-00" : "00.000.000/0000-00"}
                            inputComponent={type === "pf" ? CPFMaskCustom : CNPJMaskCustom}
                            disabled={loading}
                            fullWidth
                            endAdornment={loadingCPF && <CircularProgress size={24} />}
                            {...register("cpf", {
                                required: true,
                                validate: (value) => {
                                    if (!(type === "pf" ? Validators.validateCPF(value) : Validators.validateCNPJ(value))) {
                                        return `${type === "pf" ? "CPF" : "CNPJ"} inválido!`
                                    }
                                },
                            })}
                        />
                        <FormHelperText sx={{ color: 'red' }}>{errors.cpf?.message as string}</FormHelperText>
                    </FormControl>
                </Grid>
                {/* <Typography
                    fontWeight="bold"
                    variant="h5"
                >
                    Informe a SENHA (4 dígitos)
                </Typography>
                <Grid container>
                    <OutlinedInput
                        error={!!errors.password}
                        disabled={loading || loadingCPF}
                        type={showPassword ? "text" : 'password'}
                        placeholder="••••"
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end"><IconButton onClick={() => setShowPassord(!showPassword)}>
                                {showPassword ? <FiEyeOff /> : <FiEye />}</IconButton>
                            </InputAdornment>
                        }
                        {...register("password", {
                            required: true,
                            minLength: {
                                value: 4,
                                message: "Senha deve possuir 4 digitos"
                            },
                            maxLength: {
                                value: 4,
                                message: "Senha deve possuir 4 digitos"
                            }
                        })}
                        inputComponent={PasswordMobileMaskCustom}
                    />
                    <FormHelperText sx={{ color: 'red' }}>{errors.password?.message as string}</FormHelperText>
                </Grid> */}
                <LoadingButton
                    loading={loading}
                    disabled={loadingCPF}
                    onClick={handleSubmit(handleSubmitLogin)}
                    sx={{ mt: 2 }}
                    variant="contained"
                    fullWidth
                    size="large">
                    Entrar
                </LoadingButton>
            </Grid>
        </Grid>
    )
}

export default LoginPage;