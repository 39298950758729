import * as React from 'react';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface TabeViewProps {
    header?: string[] | JSX.Element[];
    align?: "center" | "justify" | "left" | "right" | "inherit"
    children: React.ReactNode;
    tableHeader?: React.ReactNode;
    bgColor?: string,
    cellWidth?: number
}

export default function TableView({ header, children, tableHeader, align, bgColor, cellWidth }: TabeViewProps) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
                {tableHeader ??
                    <TableHead sx={{ bgcolor: bgColor ?? "#316FE8" }}>
                        <TableRow>
                            {
                                header?.map((_head) => (
                                    <TableCell align={align ?? undefined} sx={{ color: 'white', ...(cellWidth && {width: cellWidth})}}>{_head}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                }
                {children}
            </Table>
        </TableContainer>
    );
}