import axios, { InternalAxiosRequestConfig } from "axios";
import { Utils } from "utils";
import StorageService from "./storage";

const api = axios.create({
    baseURL: Utils.URLSBases.apisNFV
});

api.interceptors.response.use(e => e, async reject => {
    return Promise.reject(reject);
})

async function intercepter(config: InternalAxiosRequestConfig<any>) {
    let token = null;
    if (window.location.href.includes('correspondente') || window.location.href.includes('venda') || window.location.href.includes('geral')) {
        token = await StorageService.getToken();
    } else {
        token = await StorageService.getTokeClient()?.access;
    }

    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}

api.interceptors.request.use(intercepter);
export default api;