import { Button, CircularProgress, Divider, FormControl, Grid, MenuItem, Select, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { MdFullscreen } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import TableCommission from "./table";
import { PrizeDrawsService } from "../../../service/prizes";
import { ComissionReportProps, prizeDrawsProps } from "../../../types";
import moment from "moment";
import { IGerencialReport } from "../../../types/indications";
import TableGerencialReport from "../indication/tableGerencialReport";
import useAuth from "../../../context/auth";
import RoutesPath from "../../../utils/routesBases";
import { Roles } from "../../../utils";
import { IPolicies } from "../comissionsTable";
import { CommissionService } from "../../../service/commission";

function removerRepetidos(array: { prizeDrawAt: string; prizeDrawId: number }[]) {
    return array.filter((item, index) => {
        return index === array.findIndex(obj =>
            obj.prizeDrawId === item.prizeDrawId
        );
    });
}

const MyCommissions = () => {

    const theme = useTheme();

    const navigate = useNavigate();

    const [filterPrize, setFilterPrize] = useState<number>(0);
    const [filterStatus, setFilterStatus] = useState('10');

    const [showTable, setShowTable] = useState(false);

    const [comission, setComission] = useState<ComissionReportProps[] | null>(null);

    const [comissionFiltered, setComissionFiltered] = useState<ComissionReportProps[] | null>(null);

    const [PrizeDraws, setPrizeDraws] = useState<{ prizeDrawAt: string; prizeDrawId: number }[] | null>(null);

    const [reportFilterPrizeDraw, setReportFilterPrizeDraw] = useState<number>(0);
    const [reportFilterIndicationType, setReportFilterIndicationType] = useState<string | undefined>('none');
    const [gerencialReport, setGerencialReport] = useState<IGerencialReport[]>([]);
    const [openTableGerencialReport, setOpenTableGerencialReport] = useState(false);
    const [prizeDrawOptions, setPrizeDrawOptions] = useState<prizeDrawsProps[]>([]);

    const [loadingGerencialReport, setLoadingGerencialReport] = useState(false);

    const { user } = useAuth();
    const [policies, setPolicies] = useState<IPolicies[]>([])

    useEffect(() => {
        CommissionService.getCommissionPolicies()
            .then(({ data }) => {
                setPolicies(data.policies)
            })
    }, [])

    const getRepotComission = () => {
        PrizeDrawsService.commissionReport()
            .then(({ data }) => {
                const result: ComissionReportProps[] = data?.result
                if (result) {
                    let _prizes = [];
                    for (let index = 0; index < result?.length; index++) {
                        _prizes.push({
                            prizeDrawAt: result[index].prizeDrawAt,
                            prizeDrawId: result[index].prizeDrawId
                        })

                    }
                    setPrizeDraws(removerRepetidos(_prizes ?? []))
                    setComissionFiltered(result)
                    setComission(result)
                }
            })
    }

    const getGerencialReport = () => {
        setLoadingGerencialReport(true)
        PrizeDrawsService.gerencialReport(reportFilterPrizeDraw).then(({ data }) => {
            setGerencialReport(data.result)
        }).finally(() => setLoadingGerencialReport(false))
    }

    const getPrizeDraws = async () => {
        let prizeDraws: prizeDrawsProps[] = []
        await PrizeDrawsService.results().then(({ data }) => {
            prizeDraws = prizeDraws.concat(data.prizeDraws)
        })
        await PrizeDrawsService.get().then(({ data }) => {
            prizeDraws = prizeDraws.concat(data.prizeDraws)
        })
        //@ts-ignore
        setPrizeDrawOptions(prizeDraws.sort((a, b) => new Date(b.prizeDrawAt).getTime() - new Date(a.prizeDrawAt).getTime()))
    }

    useEffect(() => {
        getGerencialReport()
    }, [reportFilterPrizeDraw])

    useEffect(() => {
        getPrizeDraws()
        getRepotComission()
    }, [])

    useEffect(() => {
        if (filterPrize !== 0 && comission) {
            let list_filtered = []
            for (let index = 0; index < comission?.length; index++) {
                if (comission[index].prizeDrawId === filterPrize) {
                    list_filtered.push(comission[index])
                }
            }
            setComissionFiltered(list_filtered)
        }
        else if (comission) {
            setComissionFiltered(comission)
        }
    }, [filterPrize])

    const renderPoliciesOptions = (policy: IPolicies): {
        value: string,
        label: string
    } => {
        if (policy.level === 0) {
            return {
                label: policy.description ?? "Minhas vendas",
                value: policy.level.toString()
            }
        } else if (policy.level === 1) {
            return {
                label: policy.description ?? "Por indicações  Diretas",
                value: policy.level.toString()
            }
        } else if (policy.level === 2) {
            return {
                label: policy.description ?? "Por indicações Indiretas 1º Grau",
                value: policy.level.toString()
            }
        } else if (policy.level === 3) {
            return {
                label: policy.description ?? "Por indicações Indiretas 2º Grau",
                value: policy.level.toString()
            }
        } else if (policy.level === 4) {
            return {
                label: policy.description ?? "Por indicações Indiretas 3º Grau",
                value: policy.level.toString()
            }
        } else if (policy.level === 5) {
            return {
                label: policy.description ?? "Por indicações Indiretas 4º Grau",
                value: policy.level.toString()
            }
        } else {
            return {
                label: "Opção desconhecida..",
                value: ""
            }
        }
    }

    return (
        <Grid container p={2} gap={3} justifyContent={'center'} flexDirection={'column'}>
            <Grid container display={"flex"} flexDirection={"column"} alignItems={"center"} gap={2} onClick={() => navigate(RoutesPath.Revendedor.saldo)}>
                <Typography variant="h4" fontWeight={"400"}>
                    Saldo Disponível: <strong style={{ color: (user?.credits ?? 0) < 0 ? 'red' : theme.palette.secondary.main }}>
                        {user?.credits?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                    </strong>
                </Typography>
                <Button
                    color="inherit"
                    sx={{ textDecoration: 'underline', fontSize: 10 }}>(Detalhes do saldo)</Button>
            </Grid>
            <Typography variant="h4" textAlign={"center"} fontWeight={"bold"} mt={2}>
                RELATÓRIO DE COMISSÕES
            </Typography>
            <Grid container justifyContent={"space-between"} alignItems="center">
                <Grid xs={12}>
                    <Typography
                        variant="h6"
                        textAlign={"start"}
                        fontWeight={"bold"}
                        color={theme.palette.grey[700]}>
                        Concuros
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            value={filterPrize}
                            onChange={(event) => setFilterPrize(event.target.value as number)}
                        >
                            <MenuItem value={0}>Todos</MenuItem>
                            <Divider orientation="horizontal" />
                            {
                                PrizeDraws?.sort((a, b) => b.prizeDrawId - a.prizeDrawId).map(({ prizeDrawAt, prizeDrawId }, _index) => (
                                    <MenuItem
                                        onClick={() => setFilterPrize(prizeDrawId)}
                                        value={prizeDrawId}>
                                        {String(moment(prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(prizeDrawAt).format("L")).toUpperCase()} às {String(moment(prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas
                                    </MenuItem>

                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {/* <Grid xs={5.9}>
                    <Typography
                        variant="h6"
                        textAlign={"start"}
                        fontWeight={"bold"}
                        color={theme.palette.grey[700]}>
                        Status do concurso
                    </Typography>
                    <FormControl fullWidth sx={{ maxWidth: 200 }}>
                        <Select
                            value={filterStatus}
                            // variant="filled"
                            onChange={(event) => setFilterStatus(event.target.value as string)}
                        >
                            <MenuItem value={'10'}>Todos</MenuItem>
                            <Divider orientation="horizontal" />
                            <MenuItem value={'20'}>Finalizados</MenuItem>
                            <Divider orientation="horizontal" />
                            <MenuItem value={'30'}>Em andamento</MenuItem>
                        </Select>
                    </FormControl>
                </Grid> */}
            </Grid>
            <Button color="inherit" variant="outlined" sx={{ p: 1 }} onClick={() => setShowTable(true)}>
                <MdFullscreen color="black" size={25} />
                <Typography fontSize={16} fontWeight="500" mt={.4}>Abrir relatório</Typography>
            </Button>

            <Divider orientation="horizontal" />

            <Typography
                mt={2}
                variant="h4"
                textAlign={"center"}
                fontWeight={"bold"}
                textTransform={'uppercase'}>
                RELATÓRIO DE VENDAS
            </Typography>
            <Typography
                variant="h6"
                textAlign={"center"}
                fontWeight={"bold"}
                textTransform={'uppercase'}>
                Filtrar por:
            </Typography>
            <Grid container flexDirection='column'>
                <Typography
                    variant="h6"
                    textAlign={"start"}
                    fontWeight={"bold"}
                    color={theme.palette.grey[700]}>
                    Concurso
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={reportFilterPrizeDraw as unknown as string}
                        onChange={(e) => {
                            setReportFilterPrizeDraw(Number(e.target.value))
                        }}
                    >
                        <MenuItem value={0}>Todos</MenuItem>
                        <Divider orientation="horizontal" />
                        {prizeDrawOptions.map((item) => {
                            return (
                                <MenuItem value={item.id}>{String(moment(item?.prizeDrawAt).format("dddd")).toUpperCase()} {String(moment(item?.prizeDrawAt).format("L")).toUpperCase()} às {String(moment(item?.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()} horas</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>
            {user?.role === Roles.seller && <Grid container flexDirection='column'>
                <Typography
                    variant="h6"
                    textAlign={"start"}
                    fontWeight={"bold"}
                    color={theme.palette.grey[700]}>
                    Tipos de vendas
                </Typography>
                <FormControl fullWidth>
                    <Select
                        value={reportFilterIndicationType}
                        // variant="filled"
                        onChange={(e) => {
                            setReportFilterIndicationType(e.target.value)
                        }}
                    >
                        <MenuItem value={'none'}>Todas</MenuItem>
                        <Divider orientation="horizontal" />
                        {policies.filter((item) => item.active && item.role === 10).map((item) => {
                            const { label, value } = renderPoliciesOptions(item)
                            return (
                                <MenuItem value={value}>{label}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </Grid>}
            <Button
                color="inherit"
                variant="outlined"
                sx={{ p: 1 }}
                onClick={() => setOpenTableGerencialReport(true)}
                endIcon={loadingGerencialReport && <CircularProgress size={24} />}
                disabled={loadingGerencialReport}
            >
                <MdFullscreen color="black" size={25} />
                <Typography fontSize={16} fontWeight="500" mt={.4}>Abrir relatório</Typography>
            </Button>

            <TableGerencialReport
                open={openTableGerencialReport}
                handleClose={() => {
                    setOpenTableGerencialReport(false);
                }}
                gerencialReport={reportFilterIndicationType !== "none" ? gerencialReport.filter((item) => item.level === Number(reportFilterIndicationType)) : gerencialReport}
            />
            <TableCommission
                open={showTable}
                comission={comissionFiltered?.sort((a, b) => b.prizeDrawId - a.prizeDrawId) as any}
                handleClose={() => { setShowTable(false) }}
            />
        </Grid>
    )
}

export default MyCommissions;