import { useTheme } from "@mui/material";

export const PredictionInput: React.FC<{
    disable?: boolean;
    value: number | undefined;
    onChange: (value: number | null) => void;
    inputRef?: (el: any) => void;
    onFocusIn?: () => void;
    onFocusOut?: () => void;
    onDelete: () => void;
    onKeyDown: (value: any) => void;
    isFavorite?: boolean;
    isFocus?: boolean;
    isWinner?: boolean;
    index: number;
    small?: boolean
}> = ({
    value,
    onChange,
    inputRef,
    disable,
    onFocusIn,
    onFocusOut,
    isFavorite,
    onDelete,
    onKeyDown,
    isFocus,
    index,
    isWinner,
    small,
    ...rest
}) => {
        const { palette } = useTheme();

        // Validação do valor na mudança
        const handleInput = (event: React.FormEvent<HTMLInputElement>) => {
            const input = event.target as HTMLInputElement;
            const newValue = Number(input.value.replace(/\D/g, '').charAt(0));
            onChange(input.value === "" ? null : newValue);
        };

        // Função para evitar entrada de caracteres inválidos
        const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            const allowedKeys = ['Delete'];
            const isNumberKey = e.key >= '0' && e.key <= '9';
            // Permite apenas teclas de número ou teclas funcionais (Backspace, Delete, etc.)
            if ((!isNumberKey && !allowedKeys.includes(e.key)) || e.key === " ") {
                e.preventDefault();
            } else {
                // Chama o onKeyDown original para permitir que o componente reaja a outros eventos
                onKeyDown(e.key);
            }

            // Tratamento do Backspace
            if (e.key === "Backspace" || e.keyCode === 8) {
                onDelete();
            }
        };

        return (
            <div style={{ position: 'relative', overflow: 'hidden' }}>
                {disable && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 10,
                        }}
                    />
                )}

                <input
                    {...rest}
                    name="input-only-numbers"
                    ref={inputRef}
                    typeof="number"
                    inputMode="numeric"
                    // maxLength={1} // Limita para 1 caractere numérico
                    id="input_number_fav"
                    value={value ?? ''} // Exibe o valor ou uma string vazia
                    type="tel" // Mantém tipo como texto para controlar o comportamento do teclado
                    // onChange={handleChange}
                    className="input_number_fav"
                    onFocus={onFocusIn}
                    onBlur={onFocusOut}
                    onKeyDown={handleKeyDown}
                    onInput={handleInput}
                    autoComplete="nope"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    aria-autocomplete="none"
                    form="off"
                    pattern="\d*"
                    style={{
                        ...(index === 0 && { color: 'rgba(0, 82, 0, .9)' }),
                        ...(index === 1 && { color: 'rgba(0, 82, 0, .8)' }),
                        ...(index === 2 && { color: 'rgba(0, 82, 0, .7)' }),
                        ...(isFavorite && {
                            color: palette.primary.main,
                            textDecoration: 'underline',
                            textDecorationColor: '#bab7b6',
                        }),
                        ...(isFocus && {
                            transform: 'scale(1.1)',
                            transition: 'transform .3s ease',
                        }),
                        ...(isWinner && {
                            transition: 'background-color 2s ease',
                            width: '45px',
                            height: '45px',
                            backgroundColor: 'rgba(28,255,164,.5)',
                            borderRadius: '50%',
                        }),
                        ... (small && {
                            fontSize: '20px'
                        })
                    }}
                />
            </div>
        );
    };
