import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, useHelper, useTexture } from '@react-three/drei';
import { useSpring, animated, easings } from '@react-spring/three';
import * as THREE from 'three';
import { CameraHelper } from 'three';

interface Props {
    result: null | number,
    onFinish: () => void
}

const CameraWithHelper = () => {
    const cameraRef = useRef();

    // Usamos o useHelper dentro do contexto do Canvas
    useHelper(cameraRef, CameraHelper);

    return (
        <perspectiveCamera ref={cameraRef} position={[0, 3, 5]} fov={75} near={0.1} far={1000} />
    );
};


const Coin = ({
    result,
    onFinish
}: Props) => {
    const coinRef = useRef();
    const [flipping, setFlipping] = useState(false);
    const [autoRotate, setAutoRotate] = useState(true);
    const duration = 1200;

    // Carrega as texturas para "heads" e "tails"
    const [headsTexture, tailsTexture] = useTexture([
        '/MOEDA CARA.png',  // Imagem de "heads"
        '/MOEDA COROA.png',  // Imagem de "tails"
    ]);

    // Cria a geometria da moeda com UV ajustado
    const geometry = useMemo(() => {
        const geo = new THREE.CylinderGeometry(2, 2, 0.1, 64);

        // Ajusta as UVs para garantir que as imagens ocupem a face inteira da moeda
        const uvAttribute = geo.attributes.uv;

        // Ajustar o mapeamento UV da face superior (Topo)
        for (let i = 0; i < 32; i++) {
            const angle = (i / 32) * Math.PI * 2;
            const x = (Math.cos(angle) + 1) / 2;  // Calcula a coordenada X
            const y = (Math.sin(angle) + 1) / 2;  // Calcula a coordenada Y
            uvAttribute.setXY(i, x, y);  // Define o UV corretamente para a face superior
        }

        // Ajustar o mapeamento UV da face inferior (Base)
        for (let i = 32; i < 64; i++) {
            const angle = ((i - 32) / 32) * Math.PI * 2;
            const x = (Math.cos(angle) + 1) / 2;  // Calcula a coordenada X
            const y = (Math.sin(angle) + 1) / 2;  // Calcula a coordenada Y
            uvAttribute.setXY(i, x, y);  // Define o UV corretamente para a face inferior
        }

        uvAttribute.needsUpdate = true;
        return geo;
    }, []);

    // Animação da moeda
    const { position, rotation } = useSpring({
        position: flipping ? [0, 1.5, 0] : [0, -1.3, 0],
        rotation: flipping
            ? [Math.PI * 10, Math.PI * 20, Math.random() > 0.5 ? Math.PI : 0]
            : [Math.PI / 2, 0, result === 1 ? 0 : Math.PI],
        config: { tension: 100, friction: 20, mass: 1, duration: duration, easing: easings.easeOutCubic},
        onRest: () => {
            setFlipping(false);
            setAutoRotate(false);
            setTimeout(() => {
                onFinish()
            }, duration);
        },
    });

    const flipCoin = () => {
        if (!flipping) {
            setFlipping(true);
        }
    };

    useFrame(() => {
        if (!flipping && coinRef.current && autoRotate) {
            //@ts-ignore
            coinRef.current.rotation.x = Math.PI / 2.2
            //@ts-ignore
            coinRef.current.rotation.z += 0.05;  // Rotação contínua em torno do eixo Z (horizontalmente)
        }
    });

    useEffect(() => {
        if (typeof result === "number") {
            flipCoin();
        }
    }, [result]);

    return (
        <>
            <animated.mesh
                ref={coinRef as any}
                geometry={geometry}  // Geometria com UV ajustado
                position={position as any}
                rotation={rotation as any}
            >
                <meshStandardMaterial attach="material-0" color="gold" side={THREE.DoubleSide} /> {/* Borda da moeda */}
                <meshStandardMaterial attach="material-1" map={tailsTexture} side={THREE.DoubleSide} /> {/* Frente ("heads") */}
                <meshStandardMaterial attach="material-2" map={headsTexture} side={THREE.DoubleSide} /> {/* Verso ("tails") */}
            </animated.mesh>
        </>
    );
};

export const CoinFlip = ({
    result,
    onFinish
}: Props) => {
    return (
        <Canvas
        >
            {/* <CameraWithHelper /> */}
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            <Coin key={'coin-3d'} result={result} onFinish={onFinish}/>
            <OrbitControls enabled={false} />
        </Canvas>
    );
};