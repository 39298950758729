import { ThemeProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { useEffect, useLayoutEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import Routers from './routes';
import theme from './theme';
import { ptBR } from '@mui/x-date-pickers/locales';
import 'moment/dist/locale/pt-br'
import 'moment/locale/pt-br'
import moment from 'moment';
import { AuthProvider } from './context/auth';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BetProvider } from './context/bet';
import { useAudio } from './hooks/useAudio';
import { lpMenus } from './layout/lp';
import useExpress, { ExpressProvider } from './context/express';

const Wrapper = ({ children }: {
    children: any
}) => {
    const location = useLocation();
    const { gameTab } = useExpress()
    useLayoutEffect(() => {
        if (gameTab !== 2 && location.pathname !== "/cliente/concurso" && location.pathname !== "/cliente" && location.pathname !== "/cliente/concurso/" && location.pathname !== "/cliente/") {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: "smooth" })
            }, 500);
        }
    }, [location.pathname]);
    return children
}

const SoundControl = ({ children }: {
    children: any
}) => {
    const { gameTab } = useExpress()
    const location = useLocation();
    const [playing, toggle, reset] = useAudio('https://s3.amazonaws.com/assets.numerofavorito.com/sounds/JINGLE+SITE.mp3');
    const [playingShort, toggleShort, resetShort] = useAudio('https://s3.amazonaws.com/assets.numerofavorito.com/sounds/JINGLE+SISTEMA.mp3');

    useEffect(() => {
        if (gameTab !== 2) {
            if (location.pathname.includes("aprenda")) {
                if (playing) {
                    toggle()
                }
                if (playingShort) {
                    toggleShort()
                }
                return
            }
            const condition = lpMenus.filter((item) => item.type === "route").find((item) => item.path.includes(location.pathname))
            if (condition) {
                if (playingShort) toggleShort()
                if (!playing) {
                    toggle()
                } else {
                    reset()
                }
            } else {
                if (playing) toggle()

                if (!playingShort) {
                    toggleShort()
                } else {
                    resetShort()
                }
            }
        }
    }, [location.pathname])

    return children
}

function App() {
    moment.locale('pt-br')
    return (
        <BrowserRouter>
            <ExpressProvider>
                {/* <SoundControl> */}
                    <Wrapper>
                        <AuthProvider>
                            <ThemeProvider theme={theme()}>
                                <BetProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterMoment}
                                        adapterLocale='pt-br'
                                        localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                        <Routers />
                                    </LocalizationProvider>
                                </BetProvider>
                            </ThemeProvider>
                        </AuthProvider>
                        <ToastContainer />
                    </Wrapper>
                {/* </SoundControl> */}
            </ExpressProvider>
        </BrowserRouter>
    );
}

export default App;
