import { Navigate, RouteObject, createBrowserRouter, useRoutes } from "react-router-dom"
import BetLayout from "layout/bet";
import useAuth from "context/auth";
import SellerRouters from "./seller";
import ClientRouters from "./client";
import RoutesPath from "utils/routesBases";
import useQuery from "hooks/useQuery";
import LearnBetPage from "pages/bet/learn";
import { lpMenus } from "layout/lp";
import { useEffect, useState } from "react";
import path from "path";
import GeralRouters from "./geral";
import RedirectComponent from "./redirect";
import BetPage from "pages/bet";

const Routers = () => {
    const [lpRoutes, setLpRoutes] = useState<RouteObject[]>([])

    useEffect(() => {
        let childrens: RouteObject[] = []
        lpMenus.map((lp) => {
            let paths: RouteObject[] = []
            lp.path.forEach((path) => {
                if (path === '/cliente') {
                    return
                }
                paths.push({
                    element: lp.element,
                    path: path
                } as RouteObject)
            })
            for (let child of paths) {
                childrens.push(child)
            }
        })
        setLpRoutes(childrens)
    }, [])

    return useRoutes([
        {
            path: '/',
            children: lpRoutes
        },
        // {
        //     path: '/convitedasorte',
        //     element: <RedirectComponent />
        // },
        {
            path: '/meuacesso',
            element: <Navigate to="/cliente" />
        },
        {
            path: '/app/meuacesso',
            element: <Navigate to="/cliente" />
        },
        {
            path: '/app/meusaldo',
            element: <Navigate to="/cliente" />
        },
        {
            element: RoutesPath.sorteio.element,
            path: RoutesPath.sorteio.path
        },
        {
            path:'/apostar',
            element: <BetLayout />,
            // children: [
            //     {
            //         element: <BetLayout/>,
            //         path: "/apostar"
            //     }
            // ]
        },
        ...ClientRouters(),
        ...SellerRouters("correspondente", "Correspondente"),
        ...SellerRouters("venda", "Revendedor"),
        ...GeralRouters(),
        {
            element: <LearnBetPage />,
            path: RoutesPath.aprenda
        },
        {
            element: <BetLayout />,
            path: RoutesPath.Clients.bet.home2.path,
            children: [
                {
                    element: RoutesPath.Clients.bet.home.element,
                    path: RoutesPath.Clients.bet.home.path
                },
                {
                    element: RoutesPath.Clients.bet.order.element,
                    path: RoutesPath.Clients.bet.order.path,
                },
                {
                    element: RoutesPath.Clients.bet.payment.element,
                    path: RoutesPath.Clients.bet.payment.path + '/:id',
                },
                {
                    element: RoutesPath.Clients.bet.recibo.element,
                    path: RoutesPath.Clients.bet.recibo.path + '/:id'
                },
                {
                    element: RoutesPath.Clients.bet.home2.element,
                    path: RoutesPath.Clients.bet.home2.path
                },
                {
                    element: RoutesPath.Clients.bet.order2.element,
                    path: RoutesPath.Clients.bet.order2.path,
                },
                {
                    element: RoutesPath.Clients.bet.payment2.element,
                    path: RoutesPath.Clients.bet.payment2.path + '/:id',
                },
                {
                    element: RoutesPath.Clients.bet.recibo2.element,
                    path: RoutesPath.Clients.bet.recibo2.path + '/:id'
                }
            ]
        }
    ])
}

export default Routers;