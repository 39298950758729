import { LPNav } from '../../../layout/lp';

import style from './styles.module.css'
import { RodapeLP } from 'layout/lp/rodape';
import ComissionIMG from 'assets/imgs/lp/correspondent/Comission.svg'
import ComissionMobileIMG from 'assets/imgs/lp/correspondent/Commission mobile.svg'

import ButtonIMG from 'assets/imgs/lp/correspondent/BOTÃO CADASTRO VENDEDOR.png';
import Card30IMG from 'assets/imgs/CARD 30.png';
import { useMediaQuery, useTheme } from '@mui/material';
import useQuery from 'hooks/useQuery';
import { Link, useNavigate } from 'react-router-dom';
import Arrow from 'assets/imgs/lp/contest/arrow.png'
import NewFavicon from 'assets/new favicon.png'
import Actor from 'assets/imgs/lp/correspondent/CARD CADASTRO.png'
import Commission from 'assets/imgs/lp/correspondent/35.png'
import { FaPlay } from 'react-icons/fa6';
import ThumbDesktop from 'assets/imgs/lp/whoWeAre/16.9.png'
import ThumbMobile from 'assets/imgs/lp/whoWeAre/background 3x4.png'
import { isMobile } from 'utils';


export function LPCorrespondent() {
    const media = useMediaQuery('(min-width:1744px)')
    const theme = useTheme()
    const query = useQuery()
    const navigate = useNavigate()

    const onClick = (): string => {
        let _query = ""
        let correspondent = query.get('s')
        let general = query.get('g')

        if (correspondent) {
            _query += `?s=${correspondent}`

        }
        if (general) {
            _query += `?g=${general}`

        }
        let url = ("/venda/cadastro" + _query)
        return url
    }

    function getHighlightedText(text: string, highlight: string) {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <h2 style={{
            color: "#666666",
            fontWeight: 400,
            width: '90%',
            textAlign: 'center',
            marginTop: 40
        }}> {parts.map((part, i) =>
            <span className={style.underline} key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { textDecoration: 'underline', textDecorationColor: theme.palette.primary.main, color: theme.palette.primary.main } : {}}>
                {part}
            </span>)
            } </h2>;
    }

    return (
        <div className="containerLP">
            <LPNav />
            <div className={style.container}>
                <div>
                    <div onClick={() => navigate(onClick())} style={{
                        cursor: 'pointer'
                    }}>
                        <img src={Actor} alt="Ator" />
                    </div>
                    <div className={style.containerUl}>
                        <ul>
                            <li>Sua <strong>independência financeira</strong> na palma da mão</li>
                            <li>⁠Seja nosso revendedor em sua cidade</li>
                            <li>Venda <strong>apenas 20 bilhetes</strong> de apostas e <strong>receba R$ 50 todo dia</strong></li>
                            <li>Saque sua <strong>comissão na hora</strong></li>
                            <li>Sistema <strong>fácil, rápido e seguro</strong></li>
                            <li>⁠Ganhe também indicando novos revendedores</li>
                        </ul>
                    </div>
                </div>
                <div className={style.contentRight}>
                    <div>
                        <Link to={onClick()}>
                            <img src={ButtonIMG} alt="botão venha ser revendedor" />
                        </Link>
                    </div>
                </div>

                <div className={style.containerVideoHowTo}>
                    <p>
                        <FaPlay size={30} />
                        Aprenda a vender apostas<br/> presencialmente</p>
                    <video controls controlsList="nodownload" preload="auto" playsInline poster={isMobile() ? ThumbMobile : ThumbDesktop}>
                        <source src={"https://s3.amazonaws.com/assets.numerofavorito.com/videos+lp/COMO+VENDER+PRESENCIAL.mp4"} type="video/mp4" />
                    </video>
                </div>

                {/* <div className={style.card30}>
                    <Link to={onClick()}>
                        <img src={Card30IMG} alt="Card promoção 30%" />
                    </Link>
                </div> */}
            </div>
            <RodapeLP />
        </div>
    )
}