import CorrespondentApp from "pages/correspondent";
import AuthLayout from "../layout/auth";
import AppLayout from "../layout/saller";
import { Navigate, RouteObject, useLocation } from "react-router-dom";
import CorrespondentIndication from "../pages/correspondent/indication";
import TicketPage from "../pages/correspondent/ticket";
import MyCommissions from "../pages/correspondent/myCommissions";
import CommissionsTable from "../pages/correspondent/comissionsTable";
import ResultPage from "../pages/correspondent/results";
import CreditsSellerPage from "../pages/correspondent/credits";
import WithdrawPage from "../components/withdraw";
import RoutesPath from "../utils/routesBases";
import useAuth from "../context/auth";
import LoginPage from "../pages/auth/correspondent/login";
import TutorialPage from "../pages/correspondent/tutorial";
import PubliPage from "../pages/correspondent/publi";

const GeralRouters = (): RouteObject[] => {
    const { token } = useAuth();
    const { search } = useLocation()

    if (!token) {
        return [
            {
                element: <AuthLayout />,
                path: "geral",
                children: [
                    {
                        element: <Navigate to={"/geral/login" + search} />,
                        path: "/geral",
                    },
                    {
                        element: <LoginPage />,
                        path: "/geral/login",
                    },
                ]
            },
        ]
    }

    return [
        {
            element: <AppLayout />,
            path: "geral",
            children: [
                {
                    element: <Navigate to={"/geral"} />,
                    path: "/geral/login",
                },
                {
                    element: <Navigate to={"/geral"} />,
                    path: "/geral/*",
                },
                {
                    element: <CorrespondentApp />,
                    path: "/geral",
                },
                {
                    element: <CorrespondentIndication />,
                    path: "/geral/convite-correspondente",
                },
                {
                    element: <CorrespondentIndication />,
                    path: "/geral/convite-revendedor",
                },
                {
                    element: <MyCommissions />,
                    path: "/geral/minhas-comissoes",
                },
                {
                    element: <CommissionsTable />,
                    path: "/geral/tabela-comissoes",
                },
                {
                    element: <CreditsSellerPage />,
                    path: "/geral/meusaldo",
                },
                {
                    element: <WithdrawPage />,
                    path: "/geral/saque",
                },
                {
                    element: <ResultPage />,
                    path: "/geral/resultados",
                },
                {
                    element: <TutorialPage />,
                    path: "/geral/aprenda",
                },
                {
                    element: <PubliPage />,
                    path: "/geral/publicidade",
                },
                {
                    element: RoutesPath.Geral.meusClientes.element,
                    path: RoutesPath.Geral.meusClientes.path,
                },
                {
                    element: RoutesPath.Geral.statistic.element,
                    path: RoutesPath.Geral.statistic.path,

                }
            ]
        },
        {
            element: <TicketPage />,
            path: "/bilhete/:id",
        }
    ]
}

export default GeralRouters;