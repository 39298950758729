import { CardContent, Grid, Typography, useTheme } from "@mui/material"
import BuyPredictionCard from "../../components/cards/buyprediction";
import useBet, { NumbersGroups } from "../../context/bet";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import RoutesPath from "../../utils/routesBases";

import Stamp15Blue from '../../assets/imgs/stamps/15 AZUL.png';
import Stamp15Orange from '../../assets/imgs/stamps/15 LARANJA.png';
import Stamp15Green from '../../assets/imgs/stamps/15 VERDE.png';
import Stamp5Blue from '../../assets/imgs/stamps/5 AZUL.png';
import Stamp5Orange from '../../assets/imgs/stamps/5 LARANJA.png';
import Stamp5Green from '../../assets/imgs/stamps/5 VERDE.png';
import StampMostPurchased from '../../assets/imgs/stamps/SELO COMPRA.png';
import { useEffect } from "react";
import { sendToShow } from "../../utils";

export const NumbersOptions = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const {
        setOrder,
        selectDraw,
        purchaseFlow,
        calcDiscount
    } = useBet();

    const CardsPredicationsBuy = [5, 10, 20]
    const RouterPath = purchaseFlow === 'client' ? RoutesPath.Clients : RoutesPath.Revendedor

    useEffect(() => {
        sendToShow("buy_prediction", 'start')
    }, [selectDraw])

    const getStamp = (value: number, day: string): string => {
        const diaDaSemana = moment(day).day();
        if (value === 10) {
            switch (diaDaSemana) {
                case 0: // Domingo
                case 2: // Terça
                case 4: // Quinta
                    return Stamp5Green
                case 1: // Segunda
                case 3: // Quarta
                case 5: // Sexta
                    return Stamp5Blue
                case 6: // Sábado
                    return Stamp5Orange
                default:
                    return Stamp5Green
            }
        } else {
            switch (diaDaSemana) {
                case 0: // Domingo
                case 2: // Terça
                case 4: // Quinta
                    return Stamp15Green
                case 1: // Segunda
                case 3: // Quarta
                case 5: // Sexta
                    return Stamp15Blue
                case 6: // Sábado
                    return Stamp15Orange
                default:
                    return Stamp15Green
            }
        }
    }


    return (
        <>
            <CardContent sx={{ bgcolor: '#00000010', mt: 2 }} id="buy_prediction">
                <Typography textAlign="center" variant="h3" fontWeight="400" color={palette.grey[900]}>
                    Quanto <strong style={{ color: palette.primary.main }}> mais apostas </strong>
                    você comprar, <strong>maiores são suas chances</strong> de ganhar!
                </Typography>
            </CardContent>
            <Grid width="100%">
                <Typography variant="h4" color="GrayText" fontWeight="500" textAlign="center">
                    Aqui você <strong> GANHA</strong>
                </Typography>
                <Grid>
                    <Grid>
                        <Typography textAlign="center" variant="h5" fontWeight="400" color="GrayText" width="100%" >
                            Com <strong>QUADRA</strong> acertando os 4 números do sorteio.
                        </Typography>

                        <Typography sx={{ mt: 1 }} variant="h4" color="GrayText" fontWeight="500" textAlign="center">
                            Prêmios de cortesia por aproximação
                        </Typography>
                        <Typography textAlign="center" variant="h5" fontWeight="400" color="GrayText" width="100%" >
                            <strong>TERNO</strong> acertando os 3 últimos números do sorteio.


                        </Typography>
                        <Typography textAlign="center" variant="h5" fontWeight="400" color="GrayText" width="100%" >
                            <strong>DUQUE</strong> acertando os 2 últimos números do sorteio.
                        </Typography>
                        <Typography textAlign="center" variant="h5" fontWeight="400" color="GrayText" width="100%" >
                            <strong>UNIDADE</strong> acertando o último número do sorteio.
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>
            <Grid container gap={5}>
                {
                    CardsPredicationsBuy.reverse().map((item, index) => {

                        // const showStamp = item === 10 || item === 20
                        const showStamp = false

                        const showMostPurchased = item === 20
                        return (
                            <Grid container position={"relative"}>
                                <BuyPredictionCard
                                    onBuy={(amount, value) => {
                                        setOrder({ group: amount, value })
                                        // navigate(RouterPath.bet.order2.path)
                                    }}
                                    fakeValue={(selectDraw?.numberPrice ?? 0) * item}
                                    amount={item as NumbersGroups}
                                    value={calcDiscount(item, (selectDraw?.numberPrice ?? 0) * item)}
                                    color={index % 2 === 0 ? "success" : "secondary"} />
                                {showStamp &&
                                    <img
                                        onClick={() => {
                                            setOrder({ group: item as any, value: calcDiscount(item, (selectDraw?.numberPrice ?? 0) * item).value })
                                            navigate(RouterPath.bet.order2.path)
                                        }}
                                        className="stampImg pulse"
                                        src={getStamp(item, selectDraw?.prizeDrawAt as string)}
                                        style={{
                                            position: 'absolute',
                                            right: -20,
                                            top: 110,
                                            width: purchaseFlow === "saller" ? "80px" : "115px",
                                            cursor: 'pointer',
                                        }}
                                    />
                                }
                                {showMostPurchased &&
                                    <img
                                        onClick={() => {
                                            setOrder({ group: item, value: calcDiscount(item, (selectDraw?.numberPrice ?? 0) * item).value })
                                            navigate(RouterPath.bet.order2.path)
                                        }}
                                        className="stampImg pulse"
                                        src={StampMostPurchased}
                                        style={{
                                            position: 'absolute',
                                            left: -30,
                                            bottom: 20,
                                            width: purchaseFlow === "saller" ? "80px" : "115px",
                                            cursor: 'pointer',
                                        }}
                                    />
                                }
                            </Grid>
                        )
                    })
                }
            </Grid>
        </>
    )
}