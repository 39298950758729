import { useLocation } from "react-router-dom";
import { Sounds } from "../components/games/audios/intex";
import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";

export interface GuessesPros {
    id: number;
    number: number | null;
    itemDefault?: boolean
}


export interface GuessesProps {
    [key: string]: GuessesPros[] | undefined;
}

export type NumbersGroups = 5 | 10 | 20

interface ContextProps {
    guesses: GuessesProps | null;
    guessPrice: number;
    gameTab: number;
    setGuesses(a: GuessesProps | null): void;
    getGuesses: (a?: GuessesProps) => string[];
    setGameTab: (value: number) => void;
}

export const ExpressManager = createContext({} as ContextProps);

const useExpress = () => useContext(ExpressManager);

export const ExpressProvider = ({ children }: { children: React.ReactNode }) => {

    const [guesses, setGuesses] = useState<GuessesProps | null>(null);
    const [gameTab, setGameTab] = useState(2)

    const { pathname } = useLocation()

    const guessPrice = 1

    const getGuesses = (guess?: GuessesProps): string[] => {
        let _predictions = guess ?? guesses ?? {}
        const response = Object.keys(_predictions as GuessesProps).map((item, index) => {
            if (_predictions) {
                let localPredict = ""
                _predictions[`guess${index + 1}`]?.map((_a) => {
                    localPredict += String(_a.number)
                })

                return localPredict
            }
        })
        return response as string[]
    }

    useEffect(() => {
        if (gameTab === 2 && (pathname === "/cliente/concurso" || pathname === "/cliente" || pathname === "/cliente/concurso/" || pathname === "/cliente/")) {
            Sounds.playBackgroundSound()
        } else {
            Sounds.stopBackgroundSound()
        }
    }, [gameTab, pathname])


    return (
        <ExpressManager.Provider value={{
            guesses,
            guessPrice,
            setGuesses,
            getGuesses,
            gameTab,
            setGameTab
        }}>
            {children}
        </ExpressManager.Provider>
    )
}

export default useExpress;