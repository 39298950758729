import { Button, Container, Grid, Typography } from "@mui/material"

import CardDraw from "../../components/cards/draw";
import { useEffect, useState } from "react";
import FavoriteNumber from "./favoriteNumber";
import { sendToShow } from "../../utils";
import { PrizeDrawsService } from "../../service/prizes";
import moment from "moment";
import useBet, { PredictionPros, PredictionsProps } from "../../context/bet";
import { IQRCODECards } from "../../types/bet";
import { useLocation, useNavigate } from "react-router-dom";
import RoutesPath from "../../utils/routesBases";
import { NumbersOptions } from "./numbersOptions";
import { RequestSeller } from "./order/requestSeller";
import useExpress from "context/express";

import Stamp10 from "../../assets/imgs/stamps/hours/10H.png"
import Stamp12 from "../../assets/imgs/stamps/hours/12H.png"
import Stamp14 from "../../assets/imgs/stamps/hours/14H.png"
import Stamp16 from "../../assets/imgs/stamps/hours/16H.png"
import Stamp18 from "../../assets/imgs/stamps/hours/18H.png"
import Stamp20 from "../../assets/imgs/stamps/hours/20H.png"
import { prizeDrawsProps } from "types";

export const getColorHour = (hour: string): {
    color: string,
    img: string
} => {
    const hourOfday = moment(hour).hour();
    switch (hourOfday) {
        case 10:
            return {
                color: '#00CCFF',
                img: Stamp10
            };
        case 12:
            return {
                color: '#316FE8',
                img: Stamp12
            };
        case 14:
            return {
                color: '#00FF00',
                img: Stamp14
            };
        case 16:
            return {
                color: '#00A410',
                img: Stamp16
            };
        case 18:
            return {
                color: '#FFB586',
                img: Stamp18
            };
        default:
            return {
                color: '#FF6600',
                img: Stamp20
            };
    }
}

const PrizeDrawPage = () => {
    const location = useLocation();

    const navigate = useNavigate();
    const { guessPrice } = useExpress()
    const { selectDraw, setSelectedDraw, setPrizesDraws, prizesDraws, purchaseFlow, hoursToShowStamp, setOrder, order, setPrediction, calcDiscount } = useBet();
 
    const [card, setCard] = useState<IQRCODECards | null>(null)

    const [openModalSeller, setOpenModalSeller] = useState(false)
    const [currentServerTime, setCurrentServerTime] = useState(new Date().toISOString())

    useEffect(() => {
        sendToShow("favorite_number")
    }, [selectDraw])

    useEffect(() => {
        getPrizeDraws()

        if (location.state?.card) {
            setCard(location.state?.card)
        }
        if (location.pathname.includes("apostar")) {
            setOpenModalSeller(true)
        }
    }, [])

    const handleCardNumbers = (numbers: string[]) => {
        let _predict: PredictionsProps = {}
        for (let i = 0; i < numbers.length; i++) {
            let key = `predication${i + 1}`
            let split = numbers[i].split("")
            let newPredict: PredictionPros[] = []
            for (let x = 0; x < split.length; x++) {
                newPredict.push({
                    id: x,
                    number: Number(split[x])
                })
            }
            _predict = {
                ..._predict,
                [key]: newPredict
            }
        }
        setPrediction(_predict);
    }

    const getPrizeDraws = () => {
        PrizeDrawsService.get()
            .then(({ data }) => {
                if (data?.prizeDraws) {
                    setPrizesDraws(data.prizeDraws);
                    setCurrentServerTime(data.currentServerTime)
                }
            })
    }

    return (
        <Container>
            <Grid container py={2} gap={2}>
                <Typography variant="h3" color={"#000081"} textAlign='center' fontWeight="400" width={"100%"} id={"prize-draws-select"}>
                    <strong>Selecione abaixo</strong> o horário do sorteio que deseja participar
                </Typography>
                <Grid p={2} container gap={4}>
                    {
                        prizesDraws.filter((item, index) => {
                            if (selectDraw) {
                                if (selectDraw?.id === item.id) {
                                    return item
                                }
                            } else {
                                return item
                            }
                        })?.map((data, index) => (
                            <Grid container position={"relative"}>
                                <CardDraw
                                    index={index + 1}
                                    key={data?.id}
                                    id={data?.id ?? null}
                                    selected={selectDraw?.id ?? null}
                                    onChange={(id) => {
                                        setSelectedDraw(data)
                                        if (card) {
                                            handleCardNumbers(card.numbers)
                                            setOrder({
                                                group: 10,
                                                value: calcDiscount(10, data.numberPrice * 10).value
                                            })
                                            navigate(RoutesPath.Revendedor.bet.order2.path, {
                                                state: {
                                                    card
                                                }
                                            })
                                        }
                                    }}
                                    color={getColorHour(data.prizeDrawAt)}
                                    day={String(moment(data.prizeDrawAt).format("dddd")).toUpperCase()}
                                    date={String(moment(data.prizeDrawAt).format("L")).toUpperCase()}
                                    hors={String(moment(data.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase()}
                                    prizeDrawFrom={data.prizeDrawFrom}
                                    prizes={data.prizes}
                                    numberPrice={data.numberPrice}
                                    dayNumber={moment(data.prizeDrawAt).day()}
                                    prizeDrawAt={data.prizeDrawAt}
                                    closeIn={data.closeIn}
                                    showCloseIn={index === 0}
                                    onReloadPrizeDraws={getPrizeDraws}
                                    currentServerTime={currentServerTime}
                                />
                                {String(moment(data.prizeDrawAt).locale('pt-br').format("HH:mm")).toUpperCase() === hoursToShowStamp &&
                                    <img
                                        onClick={() => setSelectedDraw(data)}
                                        className="stampImg"
                                        src={getColorHour(data.prizeDrawAt).img}
                                        style={{
                                            position: 'absolute',
                                            right: -30,
                                            bottom: -10,
                                            cursor: "pointer",
                                            width: purchaseFlow === "saller" ? "80px" : "115px"
                                        }}
                                    />}
                            </Grid>

                        ))
                    }
                </Grid>
                {selectDraw &&
                    <Grid width={"100%"} mt={-1} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                        <Button
                            onClick={() => setSelectedDraw(null)}
                            variant="outlined"
                            sx={{
                                padding: '3px 10px',
                                fontSize: 8,
                                color: "GrayText",
                                borderColor: "GrayText",
                                textTransform: "none"
                            }}
                        >
                            Escolher outro horário
                        </Button>
                    </Grid>
                }
                {/* {
                    selectDraw && !order &&
                    <NumbersOptions />
                } */}
                {
                    selectDraw &&
                    <FavoriteNumber />
                }
                <RequestSeller
                    open={openModalSeller}
                    handleClose={(success) => {
                        setOpenModalSeller(false)
                        if (success) {
                            sendToShow("prize-draws-select", "start")
                        } else {
                            navigate("/cliente/concurso")
                        }
                    }}
                />
            </Grid>
        </Container>
    )
}

export default PrizeDrawPage;