import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material"
import { PredictionInput } from "../../components/prediction/input"
import { GuessesPros } from "../../context/express"
import useExpress from "context/express"
import { useEffect, useRef, useState } from "react"
import { FaCheck } from "react-icons/fa6"
import { toast } from "react-toastify"
import { ScrollManager, sendToShow } from "../../utils"
import { formatCurrency } from "../../utils/mask"
import { SlideIn } from "../../components/slide"
import MoneyBagImg from "../../assets/imgs/express/SACO 02.png"
import ButtonPlayImg from "../../assets/imgs/express/SORTEAR.png"
import { CoinModal } from "./modal/coin"
import { AnimatePresence } from "framer-motion"
import { IDrawResult, PrizeDrawModal } from "./modal/prizeDraw"
import { Sounds } from "./audios/intex"
import { EarningModal } from "./modal/earnings"
import { NoGainModal } from "./modal/noGain"
import { NoBalanceModal } from "./modal/noBalance"
import { LoadingButton } from "@mui/lab"
import { LuCheckCircle } from "react-icons/lu"
import useAuth from "../../context/auth"
import RoutesPath from "../../utils/routesBases"
import { useNavigate } from "react-router-dom"
import { GrLogin } from "react-icons/gr"
import { RiMoneyDollarCircleLine } from "react-icons/ri"
import { BsArrowRepeat } from "react-icons/bs"

export const GameExpress = () => {
    const { palette } = useTheme()
    const { userCredits, tokenClient } = useAuth()
    const { guesses, setGuesses, guessPrice, getGuesses } = useExpress()
    const navigate = useNavigate()
    const [quantity, setQuantity] = useState(1)
    const [activeInput, setActiveInput] = useState<null | number>(0)
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const [openModalCoin, setOpenModalCoin] = useState(false)
    const [openModalPrizeDraw, setOpenModalPrizeDraw] = useState(false)
    const [openModalEarning, setOpenModalEarning] = useState(false)
    const [openModalNoGain, setOpenModalNoGain] = useState(false)
    const [openModalNoBalance, setOpenModalNoBalance] = useState(false)
    const [isDrawing, setIsDrawing] = useState(false);
    const [result, setResult] = useState<string>(null)
    const [drawResult, setDrawResult] = useState<IDrawResult>()
    const [loadingNumbers, setLoadingNumbers] = useState<number[]>([])
    const [showButtonGenerateNumbers, setShowButtonGenerateNumbers] = useState<number>(null)
    const [hasCompleted, setHasCompleted] = useState(false)
    const earnings = {
        1: 51051,
        2: 52102,
        3: 53153,
        4: 54204,
        5: 55255,
        6: 56306,
        7: 57357,
        8: 58408,
        9: 59459,
        10: 60510
    }

    const handleGuessesChange = (key: string, guess: GuessesPros[]) => {
        setGuesses({ ...guesses, [key]: guess });
    };

    useEffect(() => {
        setTimeout(() => {
            if (!inputsRef?.current[0]?.value) {
                sendToShow('input-0', 'start')
                setTimeout(() => {
                    inputsRef?.current[0]?.focus()
                }, 300);
            }
        }, 300)
    }, [inputsRef])

    useEffect(() => {
        if (guessPrice * quantity === getGuessTotalValue() && quantity === 10) {
            setTimeout(() => {

                sendToShow(`total-value`, "start")
            }, 500);
            setHasCompleted(true)
        } else {
            setHasCompleted(false)
        }
    }, [guesses])

    const getGuessTotalValue = (qtd: boolean = false): number => {
        let value = 0
        if (guesses) {
            const keys = Object.keys(guesses)
            for (let key of keys) {
                if (guesses[key]?.length === 4) {
                    value += 1
                }

            }
        }
        return qtd ? value : value * guessPrice
    }

    useEffect(() => {
        if (guesses) {
            const keys = Object.keys(guesses);
            const completedGuesses = keys.filter(key => guesses[key].length === 4);
            if (completedGuesses.length >= quantity && quantity < 10) {
                setQuantity(prevQuantity => prevQuantity + 1);
                setTimeout(() => {
                    inputsRef?.current[completedGuesses.length * 4]?.focus();
                }, 100);
            }
        }
        if (quantity > 10) {
            setQuantity(10)
            let _guess = guesses
            const numbersAsArray = Object.keys(_guess);

            for (let number of numbersAsArray) {
                const asNumber = Number(number.replace(/\D/g, ''));
                if (asNumber > 10) {
                    delete _guess[number];
                }
            }
            setGuesses(_guess)
        }
    }, [guesses, quantity]);

    const showButton = (): boolean => {
        let value = true
        if (guesses) {
            const keys = Object.keys(guesses)
            for (let key of keys) {
                if (guesses[key]?.length === 4) {
                    value = false
                }

            }
        }
        return !value
    }

    const renderNamePrize = (name: string, color: string, isFavorite: boolean = false) => {
        if (isFavorite) {
            return (
                <p style={{
                    display: 'inline',
                    textDecoration: 'underline',
                    textDecorationColor: '#bab7b6',
                    margin: 0,
                    fontSize: 10
                }}>
                    <span style={{
                        fontWeight: '700',
                        color: color,
                    }}>
                        {name}
                    </span>
                </p>
            )
        }
        return (
            <p key={`prize-name-${name}`} style={{
                display: 'inline',
                fontWeight: '700',
                color: color,
                margin: 0,
                fontSize: 10
            }}>{name}</p>
        )
    }

    const renderGuessValue = (): string => {
        let qtd = getGuessTotalValue(true)
        if (qtd === 0) {
            return ""
        } else if (qtd === 1) {
            return `1 Aposta: ${formatCurrency(getGuessTotalValue())}`
        } else {
            return `${getGuessTotalValue(true)} Apostas: ${formatCurrency(getGuessTotalValue())}`
        }

    }

    const checkPrize = (numberIndex: number, guessIndex: number) => {
        let value = true
        if (result) {
            let resultSplited = result.split("").map(Number)
            const guess = guesses[`guess${guessIndex + 1}`]
            if (guess) {
                if (numberIndex === 3) {
                    if (guess[numberIndex]?.number !== resultSplited[numberIndex]) {
                        value = false
                    }
                } else if (numberIndex === 0) {
                    if (guess[numberIndex]?.number !== resultSplited[numberIndex] || guess[numberIndex + 1]?.number !== resultSplited[numberIndex + 1] || guess[numberIndex + 2]?.number !== resultSplited[numberIndex + 2] || guess[numberIndex + 3]?.number !== resultSplited[numberIndex + 3]) {
                        value = false
                    }
                } else if (numberIndex === 1) {
                    if (guess[numberIndex]?.number !== resultSplited[numberIndex] || guess[numberIndex + 1]?.number !== resultSplited[numberIndex + 1] || guess[numberIndex + 2]?.number !== resultSplited[numberIndex + 2]) {
                        value = false
                    }
                } else {
                    if (guess[numberIndex]?.number !== resultSplited[numberIndex] || guess[numberIndex + 1]?.number !== resultSplited[numberIndex + 1]) {
                        value = false
                    }
                }
            } else {
                value = false
            }
        } else {
            value = false
        }
        return value
    };

    const renderNeighbor = (value: string, floor: "top" | "bottom"): string => {
        let fullNumber = parseInt(value);

        fullNumber = floor === "top" ? fullNumber + 1 : fullNumber - 1;

        return (fullNumber)?.toString()?.padStart(value?.length, '0');
    };

    const checkFavoriteNumber = (numberIndex: number, guessIndex: number) => {
        let value = true
        if (result && numberIndex !== 3) {
            let resultSplited = result.split("").map(Number)
            const guess = guesses[`guess${guessIndex + 1}`]
            if (guess && guess?.length === 4 && guess[numberIndex]?.number === resultSplited[3]) {
                return true
            } else {
                value = false
            }
        } else {
            value = false
        }
        return value
    };

    const checkOtherPrizes = (guessIndex: number) => {
        let value = false
        if (result) {
            const inverter = result.split("").reverse().join("")
            const guess = guesses ? guesses[`guess${guessIndex + 1}`] : []
            if (guess) {
                const guessString = guess.map((item) => item.number).join("")
                //verificação do número invertido
                if (guessString === inverter) {
                    value = true
                }// vizinho de cima 
                else if (guessString === (renderNeighbor(result, "top"))) {
                    value = true
                }//vizinho de baixo 
                else if (guessString === (renderNeighbor(result, "bottom"))) {
                    value = true
                }
            }
        }
        return value
    };


    const getRandomNumber = () => {
        const numbers: string[] = []

        for (let i = 0; i <= 3; i++) {
            let value = Math.floor(Math.random() * 10)
            numbers.push(value.toString())
        }

        if (getGuesses().includes(numbers.join(''))) {
            return getRandomNumber()
        }

        return numbers
    }

    const generateNumber = async (index: number): Promise<any> => {
        Sounds.playBackgroundSound()
        setLoadingNumbers(prev => [...prev, index])

        handleGuessesChange(`guess${index + 1}`, getRandomNumber().map((item, i) => ({
            id: i,
            number: Number(item)
        })))

        setLoadingNumbers(prev => prev.filter((item) => item !== index))
        if (index < 9) {
            setTimeout(() => {
                inputsRef?.current[(index + 1) * 4]?.focus();
            }, 100);
        }
    }

    const handlePrizeDraw = () => {
        if (!tokenClient) {
            navigate(RoutesPath.Clients.login.path)
            return
        }

        if (userCredits?.totalCredits < getGuessTotalValue()) {
            setOpenModalNoBalance(true)
        } else {
            setOpenModalPrizeDraw(true)
            Sounds.playClick()
            setIsDrawing(true)
            ScrollManager.blockScroll()
        }
    }

    return (
        <>
            <Grid container gap={2} minWidth={300} pb={4}>
                <Typography width="100%" variant="h4" textAlign='center' fontWeight="400" padding={"0px 10px"} mt={-1}>
                    <strong style={{ textTransform: 'uppercase' }}>Sorteio e premiação individual</strong>
                    <br />
                    <br />
                    Quanto mais você <strong>apostar</strong>, maiores são suas chances de <strong>ganhar</strong>!
                    <br />
                    <br />
                    Preencha de <strong>1</strong> a <strong>10</strong> apostas
                </Typography>

                <Typography mt={-2} width="100%" fontSize={"10px"} fontWeight="400" textAlign='center' padding={"0px 10px"}>
                    com os números de sua preferência
                    <br />
                    ou clique <LuCheckCircle /> para preencher automaticamente sua(s) aposta(s)
                </Typography>

                <Typography width="100%" variant="h4" textAlign='center' fontWeight="400" padding={"0px 10px"} mt={-2}>
                    {renderNamePrize("QUADRA", 'rgba(0, 82, 0, .9)')} | {renderNamePrize("TERNO", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("DUQUE", 'rgba(0, 82, 0, .8)')} | {renderNamePrize("UNIDADE", palette.primary.main, true)}
                </Typography>
                <Grid container>
                    {
                        Array(quantity).fill(null).map((_, guess_index) => {
                            const beforeKey = `guess${guess_index}`
                            let disabledNext = false
                            if (guess_index > 0) {
                                if (((guesses ?? {})[beforeKey] ?? [])?.length < 4) {
                                    disabledNext = true
                                }
                            }
                            if (isDrawing && guesses[`guess${guess_index + 1}`]?.length !== 4) {
                                return (
                                    <></>
                                )
                            }
                            return (
                                <Grid container display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={1}>
                                    <Grid id={`input-${guess_index}`} sx={{ scrollMarginTop: result ? 20 : 0 }} key={`guess-${guess_index}`} container justifyContent={"center"} gap={2} alignItems="center" direction={"row"}>
                                        <div>
                                            <Grid container overflow="hidden" p={.7}>
                                                {
                                                    Array(4).fill(null).map((_, index) => {
                                                        const key = `guess${guess_index + 1}`
                                                        const ITEM_ID = (index - guess_index) > 0 ? ((index)) : index
                                                        const guessItem = guesses ? guesses[key] : null
                                                        const valueItem = guessItem ? guessItem.find((_item) => _item.id === ITEM_ID) : null

                                                        const refPosition = ((index) + (guess_index) * 4)

                                                        if (activeInput === refPosition && index === 0) {
                                                            sendToShow(`input-${guess_index}`, 'start')
                                                        }
                                                        return (
                                                            <Grid
                                                                key={index}
                                                                width={30}
                                                                height={30}
                                                                container
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                sx={{
                                                                    scrollMarginTop: 150,
                                                                    // numero favorito
                                                                    ... ((checkFavoriteNumber(index, guess_index)) && {
                                                                        backgroundColor: palette.primary.light,
                                                                        animation: 'pulseWithoutBoxShadowMore 1s infinite'
                                                                    }),

                                                                    // demais prêmios
                                                                    ... (checkOtherPrizes(guess_index) && {
                                                                        backgroundColor: '#ffec01',
                                                                        animation: 'pulseWithoutBoxShadowMore 1s infinite'
                                                                    }),
                                                                    // premios normais
                                                                    ... ((checkPrize(index, guess_index)) && {
                                                                        backgroundColor: 'rgba(28,255,164,.5)',
                                                                        animation: 'pulseWithoutBoxShadowMore 1s infinite'
                                                                    }),

                                                                    borderRadius: 2,
                                                                    ...(disabledNext && !isDrawing && {
                                                                        backgroundColor: '#edebeb'
                                                                    }),

                                                                }}
                                                                border={`1px solid GrayText`}
                                                            >
                                                                <PredictionInput
                                                                    index={index}
                                                                    isFocus={activeInput === refPosition}
                                                                    isFavorite={index === 3}
                                                                    disable={disabledNext || isDrawing}
                                                                    inputRef={(el) => inputsRef.current[refPosition] = el}
                                                                    value={valueItem?.number ?? undefined}
                                                                    onDelete={() => {
                                                                        handleGuessesChange(key, (guessItem ?? []).filter((item) => item.id !== index))
                                                                        if (refPosition > 0) {
                                                                            inputsRef.current[refPosition - 1]?.focus()
                                                                        }
                                                                    }}
                                                                    onKeyDown={(keyCode) => {
                                                                        Sounds.playBackgroundSound()
                                                                        Sounds.playClick()
                                                                        let value = Number(keyCode)
                                                                        if (!isNaN(value)) {
                                                                            if (index === 3 && guesses) {
                                                                                let newGuesses: GuessesPros[] = [
                                                                                    ...guessItem ?? [],
                                                                                    {
                                                                                        id: index,
                                                                                        number: value
                                                                                    }
                                                                                ]
                                                                                let exist = false

                                                                                for (let _pred of Object.keys(guesses ?? {})) {
                                                                                    let pred = guesses[_pred]
                                                                                    let isEqual = 0
                                                                                    if (pred) {
                                                                                        for (let obj of pred) {
                                                                                            let find = newGuesses.find((item) => item.id === obj.id && item.number === obj.number)
                                                                                            if (find) isEqual += 1
                                                                                        }
                                                                                    }
                                                                                    if (isEqual === 4 && _pred !== key) {
                                                                                        exist = true
                                                                                    }
                                                                                }

                                                                                if (exist) {
                                                                                    toast("A aposta não pode se repetir", {
                                                                                        type: "warning"
                                                                                    })
                                                                                    return
                                                                                }
                                                                            }
                                                                            if (valueItem) {
                                                                                if (guessItem) {
                                                                                    let newGuesses: GuessesPros[] = [
                                                                                        ...guessItem.filter((item) => item.id !== index),
                                                                                        {
                                                                                            id: index,
                                                                                            number: value
                                                                                        }
                                                                                    ]
                                                                                    handleGuessesChange(key, newGuesses)
                                                                                }
                                                                            } else {
                                                                                if (guessItem) {
                                                                                    let newGuesses: GuessesPros[] = [
                                                                                        ...guessItem,
                                                                                        {
                                                                                            id: index,
                                                                                            number: value
                                                                                        }
                                                                                    ]
                                                                                    handleGuessesChange(key, newGuesses)
                                                                                } else {
                                                                                    let newGuesses: GuessesPros[] = [
                                                                                        {
                                                                                            id: index,
                                                                                            number: value
                                                                                        }
                                                                                    ]
                                                                                    handleGuessesChange(key, newGuesses)
                                                                                }
                                                                            }
                                                                            if (refPosition !== ((quantity * 4) - 1)) {
                                                                                inputsRef.current[refPosition + 1]?.focus()
                                                                            } else {
                                                                                if (Object.keys(guesses ?? {}).length === 10) {
                                                                                    inputsRef.current[refPosition]?.blur()
                                                                                    sendToShow('button-play', "start")
                                                                                }
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(value) => {
                                                                    }}
                                                                    onFocusIn={() => {
                                                                        setActiveInput(refPosition)
                                                                        setShowButtonGenerateNumbers(Math.trunc(refPosition / 4))
                                                                    }}
                                                                    onFocusOut={() => {
                                                                        setTimeout(() => {
                                                                            setActiveInput(null)
                                                                        }, 100);
                                                                    }}
                                                                    small
                                                                />
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                        <Grid display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                                            <Typography
                                                variant="h6"
                                            >
                                                Aposta {guess_index + 1}
                                            </Typography>
                                            <FaCheck color={palette.success.main} style={{
                                                opacity: (guesses && guesses[`guess${guess_index + 1}`]?.length === 4) ? 1 : 0
                                            }} />
                                        </Grid>
                                    </Grid>
                                    {(((showButtonGenerateNumbers === guess_index) || loadingNumbers.includes(guess_index)) && !hasCompleted && !isDrawing) && <LoadingButton
                                        onClick={() => generateNumber(guess_index)}
                                        loading={loadingNumbers.includes(guess_index)}
                                        disabled={loadingNumbers.includes(guess_index)}
                                        onMouseDown={(e) => e.preventDefault()}
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'none',
                                            backgroundColor: 'transparent',
                                            backdropFilter: 'blur(3px)',
                                            fontWeight: 'bold',
                                            width: '200px',
                                            color: "GrayText",
                                            borderColor: "GrayText",
                                        }}
                                        startIcon={<LuCheckCircle size={14} />}
                                    >
                                        <Typography fontSize={'8px'}>Se preferir, clique para preencher a aposta {guess_index + 1} automaticamente</Typography>
                                    </LoadingButton>}
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {!isDrawing &&
                    <Grid container mt={-1} display={"flex"} flexDirection={"row"} alignItems={"center"} justifyContent={"center"} gap={2}>
                        {(showButton() && !openModalPrizeDraw && !openModalNoBalance) && <Grid item display={"flex"} alignItems={"flex-start"} justifyContent={"flex-start"}>
                            <Button variant="outlined" onClick={() => {
                                setGuesses(null)
                                setQuantity(1)
                                sendToShow('input-0', 'start')
                                setTimeout(() => {
                                    inputsRef.current[0]?.focus()
                                }, 100);
                            }}
                                sx={{
                                    padding: '3px 10px',
                                    fontSize: 8,
                                    color: "GrayText",
                                    borderColor: "GrayText",
                                }}
                            >Apagar apostas</Button>
                        </Grid>
                        }


                        <Typography id={`total-value`} color={palette.grey[600]} variant="h6" textAlign='right' fontWeight="400" sx={{ scrollMarginTop: 150 }}>
                            {renderGuessValue()}
                            <br />
                            {tokenClient && getGuessTotalValue(true) > 0 &&
                                <>
                                    Saldo: <span style={{
                                        color: (userCredits?.totalCredits - getGuessTotalValue()) < 0 ? 'red' : 'inherit'
                                    }}>{formatCurrency(userCredits?.totalCredits - getGuessTotalValue())}</span>
                                </>
                            }
                            {!tokenClient &&
                                <Button
                                    startIcon={<GrLogin />}
                                    variant="contained"
                                    color="secondary"
                                    sx={{
                                        animation: 'pulseWithoutBoxShadow 1s infinite',
                                        fontWeight: 'bold'
                                    }}
                                    onClick={() => navigate(RoutesPath.Clients.login.path)}
                                >Entrar</Button>
                            }
                        </Typography>


                    </Grid>}

                {(getGuessTotalValue(true) > 0 && !isDrawing) && <Typography mt={-1} width={"100%"} textAlign={"center"} variant="h6" color={"blue"}>Ganhe até {formatCurrency(earnings[getGuessTotalValue(true)])}</Typography>}

                {((userCredits?.totalCredits - getGuessTotalValue()) < 0 && !isDrawing) &&
                    <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        <Button sx={{
                            maxWidth: '30%',
                            padding: "4px",
                            fontSize: 10,
                            display: "flex",
                            flexDirection: "column",
                            fontWeight: 'bold',
                            boxShadow: '0px 0px 24px -2px rgba(0,0,0,0.75)',
                            animation: 'pulseWithoutBoxShadow 1s infinite'
                        }}
                            variant="contained"
                            color="secondary"
                            onClick={() => navigate(RoutesPath.Clients.deposito.path)}
                        >
                            <RiMoneyDollarCircleLine size={24} />
                            COMPRAR CRÉDITOS PARA APOSTAR
                        </Button>
                    </Grid>
                }

                <AnimatePresence mode="wait">
                    {(showButton() && !openModalPrizeDraw) &&
                        <SlideIn key={"coin"} direction="bottom" duration={openModalPrizeDraw ? 0.1 : 0.3} type="spring">
                            <Grid
                                id={"button-play"}
                                container
                                sx={{
                                    width: '100%',
                                    height: '130px',
                                    position: 'relative',
                                    animation: 'pulseWithoutBoxShadow 1s infinite',
                                    overflow: 'hidden',
                                    mt: -2
                                }}
                                onClick={handlePrizeDraw}
                            >
                                <img src={MoneyBagImg} style={{
                                    width: '100px',
                                    position: 'absolute',
                                    top: 0,
                                    zIndex: 10,
                                    left: '50%',
                                    transform: 'translateX(-50%)'
                                }} />
                                <img src={ButtonPlayImg} style={{
                                    width: '170px',
                                    height: '50px',
                                    position: 'absolute',
                                    bottom: 0,
                                    zIndex: 9,
                                    right: '50%',
                                    transform: 'translateX(50%)'
                                }} />
                            </Grid>

                            <Typography
                                mt={2}
                                width={"100%"}
                                textAlign={"center"}
                                color={"gray"}
                                onClick={handlePrizeDraw}
                            >Clique acima para <strong>SORTEAR</strong></Typography>
                        </SlideIn>
                    }
                    {openModalPrizeDraw &&
                        <SlideIn direction="bottom" type="spring" duration={0.1}>
                            <Typography mb={2} width={"100%"} textAlign={"center"} variant="h2" color={"blue"}>{drawResult ? "RESULTADO" : "SORTEANDO"} {(isDrawing && !drawResult) && <BsArrowRepeat style={{
                                marginBottom: -3,
                                animation: 'rotate 2s linear infinite'
                            }} />}</Typography>
                            <PrizeDrawModal guesses={getGuesses().filter((guess) => guess.length === 4)} onFinish={(res) => {
                                setOpenModalPrizeDraw(false)
                                setIsDrawing(false)
                                if (res?.totalInPrizes > 0 && !res.isJoker) {
                                    setOpenModalEarning(true)
                                } else {
                                    setOpenModalNoGain(true)
                                }
                            }} onShowResult={(res) => {
                                setResult(res.drawnNumber)
                                setDrawResult(res)
                                setTimeout(() => {
                                    sendToShow('input-0', 'start')
                                }, 1000);
                            }}
                                onError={(isBalance) => {
                                    ScrollManager.unBlockScroll()
                                    if (isBalance) {
                                        setOpenModalNoBalance(true)
                                    }
                                    setOpenModalPrizeDraw(false)
                                    setIsDrawing(false)

                                }}
                            />
                        </SlideIn>
                    }
                </AnimatePresence>
            </Grid>

            <NoBalanceModal
                open={openModalNoBalance}
                onHandleClose={() => {
                    setOpenModalNoBalance(false)
                    window.scrollTo({ top: 0, behavior: "smooth" })
                }}
            />
            <EarningModal
                key={'earning-modal'}
                open={openModalEarning}
                prizes={drawResult?.prizes ?? []}
                onHandleClose={() => {
                    setOpenModalEarning(false)
                    setOpenModalCoin(true)
                }}
            />

            <NoGainModal
                key={'no-gain-modal'}
                value={drawResult?.totalInPrizes ?? 0}
                open={openModalNoGain}
                onHandleClose={() => {
                    setOpenModalNoGain(false)
                    ScrollManager.unBlockScroll()
                    setOpenModalCoin(true)
                }} />
            <CoinModal
                key={'coin-modal'}
                open={openModalCoin}
                onHandleClose={() => {
                    setOpenModalCoin(false)
                    sendToShow('total-value', 'start')
                    ScrollManager.unBlockScroll()
                    setResult(null)
                    setDrawResult(null)
                }}
                value={drawResult?.totalInPrizes ?? 0}
                betId={drawResult?.id}
            />
        </>
    )
}