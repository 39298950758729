import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { prizeDrawsProps } from "../types";
import { PaymentProps } from "../types/bet";
import UserProps from "../types/user";
import useAuth from "./auth";
import useQuery from "../hooks/useQuery";
import { BetService } from "../service/bets";

import { useNavigate, useLocation } from "react-router-dom";
import StorageService from "../service/storage";
import RoutesPath from "../utils/routesBases";
import useExpress from "./express";
import { constants } from "../utils";

const initialLuckyNumbers = {
    5: [],
    10: [],
    20: [],
    50: [],
    100: [],
    300: []
}


export interface PredictionPros {
    id: number;
    number: number | null;
    itemDefault?: boolean
}


export interface PredictionsProps {
    [key: string]: PredictionPros[] | undefined;
}

interface luckyNumbersGroupsProps {
    5: string[],
    10: string[],
    20: string[],
}

interface orderItem {
    group: number,
    value: number;
}


export type NumbersGroups = 5 | 10 | 20

interface ContextProps {
    favoriteNumber: number | null;
    setFavoriteNumber(a: number | null): void;
    predictions: PredictionsProps | null;
    setPrediction(a: PredictionsProps | null): void;

    luckyNumbers: number[] | null;
    setLuckNumbers(a: number[] | null): void;

    luckyNumbersGroups: luckyNumbersGroupsProps;
    order: orderItem | null;
    setOrder(order: orderItem): void;

    selectDraw: prizeDrawsProps | null;
    setSelectedDraw(a: prizeDrawsProps): void;

    prizesDraws: prizeDrawsProps[];
    setPrizesDraws(a: prizeDrawsProps[]): void;

    payment: PaymentProps | null;
    setPayment(a: PaymentProps): void;

    seller: UserProps | null;
    setSaller(a: UserProps): void;

    GetPredicationsNumber: (a?: PredictionsProps) => string[];

    purchaseFlow: "client" | "saller";

    ResetBet: () => void;

    setShowComissionSellerPurchase(a: boolean): void;
    showComissionSellerPurchase: boolean;

    sellerUuid: string;

    clientThatIndicate: any;

    hoursToShowStamp: string;

    calcDiscount: (numbers_count: number, value: number) => {
        value: number,
        percent: number
    }
}

export const BetManager = createContext({} as ContextProps);

const useBet = () => useContext(BetManager);

export const BetProvider = ({ children }: { children: React.ReactNode }) => {

    const navigate = useNavigate();

    const query = useQuery();

    const { pathname } = useLocation();

    const lastPath = pathname.split('/').filter(segment => segment !== '').pop();

    const [showComissionSellerPurchase, setShowComissionSellerPurchase] = useState<boolean>(() => {
        const show = StorageService.ViewComissionSeller()
        if (show === 'true') {
            return true
        }
        return false
    })

    const { user: userSaller } = useAuth();

    const { gameTab } = useExpress()

    const [loadingSaller, setLoadingSaller] = useState<boolean>(false)

    const [sellerUuid, setSellerUuid] = useState<string>("")

    const [seller, setSaller] = useState<UserProps | null>(null)
    const [clientThatIndicate, setClientThatIndicate] = useState<string | null>(null)

    const [errorSaller, setErrorSaller] = useState<string | null>(null)

    const [prizesDraws, setPrizesDraws] = useState<prizeDrawsProps[]>([])
    const [selectDraw, setSelectedDraw] = useState<prizeDrawsProps | null>(null);

    const [payment, setPayment] = useState<PaymentProps | null>(null);

    const [favoriteNumber, setFavoriteNumber] = useState<number | null>(null);

    const [luckyNumbersGroups, setLuckyNumbersGroups] = useState<luckyNumbersGroupsProps>(initialLuckyNumbers)

    const [luckyNumbers, setLuckNumbers] = useState<number[] | null>(null);
    const [predictions, setPrediction] = useState<PredictionsProps | null>(null);

    const [order, setOrder] = useState<orderItem | null>(null);

    const hoursToShowStamp = ""

    let purchaseFlow: "client" | "saller" = pathname.includes('cliente') || pathname.includes("apostar") ? "client" : "saller"

    function getRandomArray(amount: number): string[] {
        if (luckyNumbers) {
            luckyNumbers.sort(() => Math.random() - 0.5);
            const arr = luckyNumbers.slice(0, amount).sort((a, b) => a - b);
            return arr.map((_number) => `${_number}`)
        }
        return []
    }

    const calcDiscount = (numbers_count: number, value: number): {
        value: number,
        percent: number
    } => {
        switch (numbers_count) {
            // case (10):
            //     return {
            //         value: value * 0.95,
            //         percent: 0.05
            //     }
            // case (20):
            //     return {
            //         value: value * 0.85,
            //         percent: 0.15
            //     }
            // case (50):
            //     return {
            //         value: value * 0.95,
            //         percent: 0.05
            //     }
            // case (100):
            //     return {
            //         value: value * 0.90,
            //         percent: 0.1
            //     }
            // case (300):
            //     return {
            //         value: value * 0.85,
            //         percent: 0.15
            //     }
            default:
                return {
                    value,
                    percent: 0
                }
        }
    }

    useEffect(() => {
        if (luckyNumbers) {
            setLuckyNumbersGroups({
                "5": getRandomArray(4),
                "10": getRandomArray(9),
                "20": getRandomArray(19),
            })
        }
    }, [luckyNumbers])

    useEffect(() => {
        if (purchaseFlow === 'saller') {
            if (userSaller) {
                setSellerUuid(userSaller.uuid ?? "")
                setSaller({
                    ...(userSaller),
                    credits: (userSaller).credits + 50
                })
                setErrorSaller(null)
                return
            }
        }
        const _sallerquery = query.get('s')
        const clientIndicate = query.get('c')
        if (purchaseFlow === 'client' && /concurso/.test(pathname) && !/(order|pagamento|recibo)/.test(pathname)) {
            setClientThatIndicate(clientIndicate)

            localStorage.setItem('lastSellerCode', _sallerquery ?? constants.homeUser)
            localStorage.setItem('clientSellerCode', clientIndicate ?? "")

            setSellerUuid(_sallerquery ?? "")
            if (!seller || seller?.uuid !== _sallerquery && !loadingSaller) {
                setLoadingSaller(true)
                BetService.getSaller(_sallerquery ?? constants.homeUser)
                    .then(({ data }) => {
                        if (data) {
                            setSaller(data)
                            return
                        }
                        setErrorSaller("Revendedor não encontrado!")
                    })
                    .finally(() => setLoadingSaller(false))
                    .catch(() => setErrorSaller("Revendedor não encontrado!"))
            }
        }
    }, [query, pathname])

    const GetPredicationsNumber = (prediction?: PredictionsProps): string[] => {
        let _predictions = prediction ?? predictions
        const response = Object.keys(_predictions as PredictionsProps ?? {}).map((item, index) => {
            if (_predictions) {
                let localPredict = ""
                _predictions[`predication${index + 1}`]?.map((_a) => {
                    localPredict += String(_a.number)
                })

                return localPredict
            }
        })
        return response as string[]

        // let predication1 = ""
        // let predication2 = ""
        // let predication3 = ""
        // let predication4 = ""
        // if (_predictions) {
        //     _predictions['predication1']?.map((_a) => {
        //         predication1 += String(_a.number)
        //     })
        //     predication1 += String(favoriteNumber)
        //     _predictions['predication2']?.map((_a, index) => {
        //         if (index === 1) {
        //             predication2 += String(favoriteNumber);
        //             predication2 += String(_a.number);
        //             return
        //         }
        //         predication2 += String(_a.number)
        //     })
        //     _predictions['predication3']?.map((_a, index) => {
        //         if (index === 2) {
        //             predication3 += String(favoriteNumber);
        //             predication3 += String(_a.number);
        //             return
        //         }
        //         predication3 += String(_a.number)
        //     })
        //     _predictions['predication4']?.map((_a, index) => {
        //         predication4 += String(_a.number)
        //     })
        //     predication4 += String(favoriteNumber)
        // }

        // return [
        //     predication1,
        // ]
    }

    // useEffect(() => {
    //     if (errorSaller) {
    //         setTimeout(() => {
    //             setErrorSaller(null)
    //             navigate("/")
    //         }, 3000)
    //     }
    // }, [errorSaller])

    useEffect(() => {
        const segmentsToCheck = ['order', 'pagamento',];
        segmentsToCheck.map(segment => {
            if (purchaseFlow === 'client' && pathname.includes(segment) && !seller) {
                const lastSellerCode = localStorage.getItem('lastSellerCode')
                const clientIndicate = localStorage.getItem('clientSellerCode')
                navigate(RoutesPath.Clients.bet.home2.path + (lastSellerCode ? `?s=${lastSellerCode} ${clientIndicate ? `&c=${clientIndicate}` : ''}` : ''))
            }
        });
    }, [purchaseFlow])

    const ResetBet = () => {
        setSelectedDraw(null)
        setLuckyNumbersGroups(initialLuckyNumbers)
        setLuckNumbers(null)
        setPrediction(null)
        setPayment(null)
        setFavoriteNumber(null)
        setOrder(null)
    }


    useEffect(() => {
        const _sallerquery = query.get('s')
        if (userSaller && !seller && (!_sallerquery || _sallerquery === "") && userSaller?.lastSellerId) {
            BetService.getSaller(userSaller?.lastSellerId)
                .then(({ data }) => {
                    if (data) {
                        setSaller(data)
                        return
                    }
                })
        }
    }, [purchaseFlow, userSaller])
    
    return (
        <BetManager.Provider value={{
            favoriteNumber,
            setFavoriteNumber,
            predictions,
            setPrediction,
            luckyNumbers,
            setLuckNumbers,
            luckyNumbersGroups,
            order,
            setOrder,
            selectDraw,
            setSelectedDraw,
            prizesDraws,
            setPrizesDraws,
            payment,
            setPayment,
            seller,
            setSaller,
            GetPredicationsNumber,
            purchaseFlow,
            ResetBet,
            showComissionSellerPurchase,
            setShowComissionSellerPurchase,
            sellerUuid,
            clientThatIndicate,
            hoursToShowStamp,
            calcDiscount
        }}>
            {children}
            {/* <Dialog
                open={(loadingSaller || !!errorSaller) && gameTab !== 2}
                onClose={() => { }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    sx: {
                        p: 0,
                        height: '100vh',
                        width: '100vw',
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        m: 0
                    }
                }}
            >
                <Grid
                    container
                    height="100%"
                    width="100%"
                    alignItems="center"
                    flexDirection="column"
                    gap={2}
                    justifyContent="center">
                    {
                        errorSaller ?
                            <>
                                <FiAlertTriangle color="red" size={60} />
                                <Typography>{errorSaller}</Typography>
                            </> :
                            <CircularProgress color="primary" />
                    }
                </Grid>
            </Dialog> */}
        </BetManager.Provider>
    )
}

export default useBet;