import { Dialog, Grid, Typography } from "@mui/material"
import Lottie from "lottie-react"
import { Assets } from "utils"

export default function PaymentCancel({ show, text }: { show: boolean, text?: string }) {
    
    return (
        <Dialog open={show}
            PaperProps={{ sx: { bgcolor: 'transparent !important', boxShadow: 'none', p: 0, m: 0, maxHeight: '100vh', maxWidth: '100vw' } }}
            componentsProps={{ backdrop: { sx: { bgcolor: 'rgba(0, 0, 0, 0.7)' } } }}>
            <Grid container className="paymentCancelClass">
                <Grid width="100vw" container alignItems={"center"} flexDirection={"column"}>
                    <Grid width={100} height={100} sx={{
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingTop: 1.5,
                        borderRadius: '50%',
                        mb: 2
                    }}>
                        <Lottie animationData={Assets.Animations.cancel} loop={true} style={{
                            transform: 'scale(2)'
                         }}/>
                    </Grid>
                    <Typography color="white" variant="h2" textAlign={"center"}>
                        {text ?? "A aposta foi cancelada!"}
                    </Typography>
                </Grid>
            </Grid>
        </Dialog>
    )
}