import { Button, Grid, MenuItem, Select, Typography } from "@mui/material";
import { ArrowLeftIcon, ArrowRightIcon } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";

export function PaginationComponent({
    count,
    handleClick,
    limitOption
}: {
    count: number,
    handleClick: ({
        page,
        limit
    }: {
        page: number,
        limit: number
    }) => void,
    limitOption?: number[]
}) {

    const limitOptions: number[] = limitOption ?? [10, 20, 30, 50, 100]
    const [page, setPage] = useState<number>(1)
    const [limit, setLimit] = useState<number>(limitOptions[0])
    const [totalPages, setTotalPages] = useState<number>(Math.trunc(count / limit) + (count % limit === 0 ? 0 : 1))

    useEffect(() => {
        setTotalPages(Math.trunc(count / limit) + (count % limit === 0 ? 0 : 1))
        handleClick({
            limit: limit,
            page: 1
        })
        setPage(1)
    }, [limit])


    return (
        <Grid
            mt={2}
            container
            width={"100%"}
            height={50}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            gap={10}>
            <Grid
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={2}>
                <Typography color={"black"}>Resultados por página:</Typography>
                <Select
                    variant="standard"
                    value={limit}
                    onChange={(e) => {
                        setLimit(e.target.value as number)
                    }}
                >
                    {limitOptions.map((item) => {
                        return (
                            <MenuItem value={item} key={item}>{item}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            <Grid
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}>
                <Typography color={"black"}>Página {page} de {totalPages}</Typography>
                <Button
                    disabled={page <= 1}
                    onClick={() => {
                        handleClick({
                            limit: limit,
                            page: page - 1
                        })
                        setPage(page - 1)
                    }}>
                    <ArrowLeftIcon />
                </Button>
                <Button
                    disabled={page >= totalPages}
                    onClick={() => {
                        handleClick({
                            limit: limit,
                            page: page + 1
                        })
                        setPage(page + 1)
                    }}>
                    <ArrowRightIcon />
                </Button>
            </Grid>
        </Grid>
    )
}