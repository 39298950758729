import React from "react";
import { useLocation } from "react-router-dom";

export default function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const getQueryParam = (param: string, url: string) => {
  const queryString = url.split('?').pop();
  const params = new URLSearchParams(queryString);
  return params.get(param);
}
