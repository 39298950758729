import {  Grid, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import Ball from "../../../assets/imgs/express/BOLAS RESULTADO.png"
import { Sounds } from "../audios/intex";
import { ExpressService } from "../../../service/express";
import useAuth from "../../../context/auth";
import useBet from "../../../context/bet";
import { toast } from "react-toastify";
import { constants } from "../../../utils";
import useQuery from "hooks/useQuery";

interface Props {
    onFinish: (value: IDrawResult) => void,
    onShowResult: (value: IDrawResult) => void,
    guesses: string[],
    onError: (isBalance?: boolean) => void
}

export interface IDrawResult {
    id: number
    drawnNumber: string
    totalInPrizes: number
    isJoker: boolean
    client: {
        credits: number
        guessCredits: number
        coinCredit: number
    }
    prizes: {
        betId: number
        fundId: number
        numberId: number
        value: number
        prize: string
        number: string
    }[]
}

export const PrizeDrawModal = ({
    onFinish: onHandleClose,
    onShowResult,
    guesses,
    onError
}: Props) => {
    const query = useQuery();

    const { seller } = useBet()
    const { user, setUser } = useAuth()
    const [values, setValues] = useState<{ [key: number]: string }>({});
    const [finish, setFinish] = useState(false)
    const [focus, setFocus] = useState<number>(null)
    const [intervals, setIntervals] = useState([])
    const [finalResult, setFinalResult] = useState([])
    const [drawResult, setDrawResult] = useState<IDrawResult>()
    const duration = 2750
    const maxIterations = Math.round(duration / 200)

    const matchMobile = useMediaQuery('(min-width:622px)')


    // Números finais que devem ser mostrados

    // Definir cores para cada número
    const colorNumber = (index: number): string => {
        switch (index) {
            case 0: return 'rgba(0, 82, 0, .9)';
            case 1: return 'rgba(0, 82, 0, .8)';
            case 2: return 'rgba(0, 82, 0, .7)';
            default: return '#EC6615';
        }
    }

    const getRandomNumber = (exclude: number) => {
        let value = Math.floor(Math.random() * 10)

        if (value === exclude) {
            return getRandomNumber(exclude)
        } else {
            return value
        }
    }


    // Função para iniciar o sorteio
    const handleNumbers = () => {
        setValues({}); // Reseta os valores para vazios

        let stoppedBalls = 0;

        // Função para controlar a animação de cada número individualmente
        const startAnimation = (index: number, finalValue: number) => {
            setFocus(index)
            let iteration = 0;
            let lastNumber = 0
            const interval = setInterval(() => {
                // Sorteia um valor aleatório de 0 a 9
                const randomValue = getRandomNumber(lastNumber)
                lastNumber = randomValue
                setValues(prev => ({
                    ...prev,
                    [index]: randomValue.toString() // Define o valor aleatório temporário
                }));

                iteration += 1;

                // Verifica se atingiu o valor final correto
                if (iteration === maxIterations) {
                    clearInterval(interval); // Para o intervalo quando atingir o valor correto
                    setIntervals(prev => prev.filter((_interval) => _interval !== interval))
                    setValues(prev => ({
                        ...prev,
                        [index]: finalValue.toString() // Define o número final corretamente
                    }));

                    stoppedBalls += 1; // Atualiza quantas bolas já pararam

                    // Se todas as bolas já pararam, finaliza o sorteio
                    if (stoppedBalls === finalResult.length) {
                        setFocus(null)
                        setFinish(true)
                    }
                }
            }, duration / maxIterations); // Ajusta a velocidade de animação para caber em 3 segundos
            setIntervals(prev => [...prev, interval])
        };

        // Função para iniciar as animações em ordem da esquerda para a direita
        const startSequentialAnimations = () => {
            finalResult.forEach((value, i) => {
                setTimeout(() => {
                    startAnimation(i, value);
                    setTimeout(() => {
                        Sounds.playPrizeDraw()
                    }, 200);
                }, i * duration); // Inicia a animação de cada número com um atraso de 3 segundos
            });
        };

        startSequentialAnimations(); // Inicia as animações
    };

    // Animações de entrada e saída dos números
    const numberVariants = {
        hidden: { y: "-100%", transform: 'scale(0.8)' },  // Inicia o número no centro, mas invisível
        visible: { y: "0%", transform: 'scale(1)' }, // Número aparece no centro
        exit: { y: "100%", transform: 'scale(0.8)' },    // Número desaparece no centro
    };

    const createBet = () => {
        const _seller = query.get('s')

        ExpressService.create({
            clientId: user?.id as number,
            sellerId: _seller ?? seller?.uuid ?? user?.lastSellerId ?? constants.homeUser,
            numbers: guesses
        }).then(({ data }) => {
            makeDraw(data.bet.id)
            setUser({
                ...user,
                credits: data.client.credits,
                guessCredits: data.client.guessCredits,
                coinCredit: data.client.coinCredit
            })
        }).catch((err) => {
            if (err.response.status === 402) {
                onError(true)

            } else {
                toast(err?.response?.data?.message ?? "Falha ao gerar sorteio", {
                    type: "error"
                })
                onError()
            }
        })
    }

    const makeDraw = (id: number) => {
        ExpressService.makeDrawn(id)
            .then(({ data }) => {
                setFinalResult((data.drawnNumber as string).split("").map(Number))
                setDrawResult({
                    ...data,
                    id
                })

            }).catch((err) => {
                toast(err?.response?.data?.message ?? "Falha ao gerar sorteio", {
                    type: "error"
                })
                onError()
            })
    }

    const finishDrawn = () => {
        onShowResult(drawResult)
        setTimeout(() => {
            setTimeout(() => {
                onHandleClose(drawResult)
                setFocus(null)
                setFinish(false)
                setValues({})
                Sounds.stopPrizeDraw()
                intervals.forEach((_interval) => clearInterval(_interval))
            }, 500);
        }, 5000);
    }

    useEffect(() => {
        if (finalResult.length > 0) {
            handleNumbers()
        }
    }, [finalResult])

    useEffect(() => {
        createBet()
    }, [])



    useEffect(() => {
        if (finish) {
            finishDrawn()
        }
    }, [finish])



    return (
        <Grid
            container
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-around"
        >
            {Array(4).fill(null).map((_, index) => (
                <Grid key={`ball-${index}`} position="relative" width="21%" sx={{
                    transition: 'all ease .5s',
                    transform: focus === index ? 'scale(1.1)' : 'scale(1)'
                }}>
                    <img
                        src={Ball}
                        width="100%"
                        style={{ zIndex: 10 }}
                    />
                    <div style={{
                        position: 'absolute',
                        left: '50%',
                        top: '46%',
                        transform: 'translate(-50%, -50%)',
                        width: '13vw',
                        height: '18vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        zIndex: 1000,
                    }}>
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={values[index]} // Troca de chave para cada novo número
                                variants={numberVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit" // Evita sair fora do centro
                                transition={{
                                    duration: (((duration / maxIterations) / 1000) * (duration / 10000)), // Duração fixa da transição
                                    ease: "easeInOut" // Animação linear sem elástico
                                }}
                                style={{
                                    position: 'absolute',
                                    fontSize: !matchMobile ? '13vw' : '70px',
                                    color: colorNumber(index),
                                    fontWeight: 700,
                                    fontFamily: 'Poppins',
                                    textAlign: 'center'
                                }}
                            >
                                {values[index]}
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
};
