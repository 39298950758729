import { IListQuery } from "pages/correspondent/statistic/interface"
import api from "../api"

export class BetService {
    static generatorNumbers(number: number | string, prizeDraw: number) {
        return api.get(`bets/generate/${number}?prize-draw-id=${prizeDraw}`)
    }

    static confirmClient(data: any) {
        return api.patch(`bets/confirm-client`, data)
    }

    static confirmSeller(data: any){
        return api.patch(`bets/confirm-seller`, data)
    }

    static getSaller(id: string){
        return api.get(`/users/sellers/${id}`)
    }

    static findClient(cpf: string){
        return api.get(`/clients/${cpf}`)
    }

    static createClient(data:  {
        cpf: string,
        phone: string,
        birthday?: string
    }) {
        return api.post(`clients`, data)
    }

    static bet(data:  {
        sellerId: string,
        prizeDrawId: number,
        clientId: number,
        paymentInCash?: boolean,
        favoriteNumber: any,
        clientThatIndicate?: any;
        numbers: Array<{
            wasDigited: boolean,
            number: string,
        }>,
        cardId?: string
    }, seller?: boolean) {
        return api.post(`bets${seller ? '/seller' : ''}`, data)
    }

    static inspect(uuid: string) {
        return api.get(`bets/inspect/${uuid}`)
    }

    static getTicket(id: number) {
        return api.get(`bets/client/${id}`)
    }
    
    static getDrawsParticipationClient() {
        return api.get(`prize-draws/list-for-client-participation`)
    }

    static creditsHistoric(guess?: boolean) {
        return api.get(`/credits/all`, {
            params: {
                guess
            }
        })
    }

    static sendCopyAndPast(data: {
        send_to: string,
        bet_id: number
    }) {
        return api.post(`/bets/send-copy-past`, data)
    }

    static sendVoucher(bet_id: number) {
        return api.post(`/bets/send-voucher/${bet_id}`)
    }

    static getRandomNumber(prize_draw_id: number, favorite_number: number) {
        return api.get(`bets/random-numbers/${prize_draw_id}/${favorite_number}`)
    }

    static getReport(query?: IListQuery) {
        return api.get('/bets/report', {
            params: {
                ...query,
            }
        })
    }

    static getCard(id: string) {
        return api.get('/bets/card/' + id)
    }

    static cancel(id: number) {
        return api.delete('/bets/' + id)
    }
}