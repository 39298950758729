import api from "../api"

export class ClientService {
    static get() {
        return api.get('/credits/withdraw')
    }

    static getByCPF(cpf: string) {
        return api.get(`/clients/${cpf.replace(/\D/g, '')}`)
    }

    static getClientsBySeller() {
        return api.get(`/clients/seller`)
    }
    
}