import { Button, Dialog, DialogActions, DialogContent, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import RotateComponente from "../../../components/rotate";
import TableView from "../../../components/table";
import { IMyClients } from "../../../types/user";
import { NameMask } from "../../../utils/mask";

export default function TableMyClients({ open, handleClose, clients }: {
    open: boolean,
    handleClose: () => void,
    clients: IMyClients[]
}) {

    const theme = useTheme();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{ sx: { p: 0, maxHeight: '100%', width: '100vw', maxWidth: '100%', margin: 0 } }}
        >
            <DialogContent sx={{
                p: 0,
                maxHeight: '100%',
                height: 'calc(100vh - 32px)',
                width: '100vw',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '100%',
                margin: 0,
            }}>
                <RotateComponente>
                    <TableView
                        align='center'
                        header={['NOME', 'WHATSAPP', 'TOTAL DE COMPRAS']}
                    >
                        <TableBody>
                            {
                                clients?.map(({
                                    amount,
                                    id,
                                    name,
                                    phone
                                }, index) => {
                                    const color = index % 2 === 0 ? '#316FE840' : '#316FE824'
                                    const clientName = NameMask(name ?? "")
                                    return (
                                        <TableRow
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: color }}
                                        >
                                            <TableCell align="center">
                                                <Typography component={Button} color={"black"} onClick={() => {
                                                    window.open(`https://wa.me/+55${phone}`, "_blank")
                                                }}>
                                                    {clientName.firstName} {clientName.lastName}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography component={Button} color={"black"} onClick={() => {
                                                    window.open(`https://wa.me/+55${phone}`, "_blank")
                                                }}>
                                                    {phone?.replace(/^(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')}
                                                </Typography>

                                            </TableCell>
                                            <TableCell align="center">
                                                {(amount ?? 0)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </TableView>
                </RotateComponente>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    color="inherit"
                    sx={{ backgroundColor: '#0000001A' }}
                    onClick={handleClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}