import React from 'react';
import { Keyframes, motion } from 'framer-motion';

type SlideInProps = {
    direction: 'left' | 'right' | 'top' | 'bottom' | "";
    children: React.ReactNode;
    duration?: number
    type?: string,
    usingModal?: boolean,
    exit?: 'left' | 'right' | 'top' | 'bottom' | "",
    onFinish?: () => void
};

export const SlideIn: React.FC<SlideInProps> = ({ direction, children, duration, type, usingModal, exit, onFinish }) => {
    const getInitialPosition = () => {
        switch (direction) {
            case 'left':
                return { x: '-100vw' };
            case 'right':
                return { x: '100vw' };
            case 'top':
                return { y: '-100vh' };
            case 'bottom':
                return { y: '100vh' };
            default:
                return { x: 0, y: 0 };
        }
    };

    const getExitPosition = () => {
        switch (exit ?? direction) {
            case 'left':
                return { x: '-100vw' };
            case 'right':
                return { x: '100vw' };
            case 'top':
                return { y: '-100vh' };
            case 'bottom':
                return { y: '100vh' };
            default:
                return { x: 0, y: 0 };
        }
    };

    return (
        <motion.div
            initial={getInitialPosition()}
            animate={{ x: 0, y: 0 }}
            exit={getExitPosition()}
            transition={{
                type: type ?? "keyframes",
                stiffness: 50,
                duration: duration ?? 0.25,
                ease: 'linear'
            }}
            style={{
                position: 'relative', // manter position: relative para garantir que o conteúdo se mova
                ... (!usingModal && {
                    width: '100%', // Manter o mesmo tamanho do pai
                    height: '100%', // Manter o mesmo tamanho do pai
                })
            }}
            onAnimationComplete={onFinish}
        >
            {children}
        </motion.div>
    );
};
