import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { Utils } from 'utils';

// const socketio: Socket = io(Utils.URLSBases.apisNFV);

const useSocket = () => {
    const socketRef = useRef<Socket | null>(null);

    useEffect(() => {
        if (socketRef) {
            // if (!socketRef.current.connected) {
            //     // socketRef.current.connect();
            // }
            // return () => {
            //     if (socketRef.current.connected) {
            //         // socketRef.current.disconnect();
            //     }
            // };
        }
    }, []);

    return { socket: socketRef.current };
}

export default useSocket;
